import React, { useCallback, useMemo, useState } from "react";
import ReactSelect, { components as cs } from "react-select";
import CreatableSelect from "react-select/creatable";
import PropTypes from "prop-types";
import map from "lodash/map";

const NewSingleValue = () => null;

const NewInput = ({ value: inputValue, isHidden, ...props }) => {
  const {
    selectProps: { value, getOptionLabel },
  } = props;
  const label = useMemo(() => {
    if (!value) {
      return "";
    }
    return getOptionLabel(value);
  }, [getOptionLabel, value]);
  const v = useMemo(() => {
    if (!inputValue) {
      return label;
    }

    return inputValue;
  }, [inputValue, label]);
  const hidden = useMemo(() => {
    if (v) {
      return false;
    }
    return isHidden;
  }, [isHidden, v]);
  return <cs.Input isHidden={hidden} value={v} {...props} />;
};

const components = {
  ...cs,
  Input: NewInput,
  SingleValue: NewSingleValue,
};

const changeHandler = (obj, props, reqErrorMsg) => {
  // if (!props.validationFunc) return;

  const { isReq, valueKey, isMulti } = props;
  let value = null;
  if (isMulti) value = obj && obj.length > 0 ? map(obj, [valueKey].toString()).join(",") : null;
  else value = obj ? obj[valueKey] : null;
  let errorMsg = undefined;
  if (isReq !== null) {
    if (!value && isReq) errorMsg = reqErrorMsg;
    else errorMsg = null;
  }
  props.onChangeFunc(props.name, value, errorMsg, obj);
};

const validationHandler = (e, props, reqErrorMsg) => {
  const { title, isReq, reqType, minLength, value, name } = props;
  let errorMsg = isReq ? null : undefined;
  if (props.onBlurFunc) {
    props.onBlurFunc(name, value);
  }
  if (!props.validationFunc) return;

  if (!value && isReq) errorMsg = reqErrorMsg;
  else if (value && reqType && !getRegExp(reqType).test(value)) errorMsg = `Please enter valid ${title}`;
  else if (minLength && value.length < minLength) errorMsg = `${title} must be at least ${minLength} characters long`;
  props.validationFunc(name, errorMsg);
};

const getObjectByValue = (options, valueKey, labelKey, value, isHeading, isCreatableSelect) => {
  if (!options || (options && options.length == 0)) return null;

  let selectedObj = null;
  if (isHeading) {
    options.map(o => {
      if (!selectedObj) selectedObj = o.options.find(o => o[valueKey] == value);
    });
  } else selectedObj = options.find(o => o[valueKey] == value);

  if (!selectedObj && isCreatableSelect && value !== null) return { [valueKey]: value, [labelKey]: value };
  else if (!selectedObj) return null;

  return {
    [valueKey]: selectedObj[valueKey],
    [labelKey]: selectedObj ? selectedObj[labelKey] : "",
  };
};

const filterOption = ({ label, value }, string, options, valueKey) => {
  // default search
  if (label.includes(string) || value.includes(string)) return true;

  // check if a group as the filter string as label
  const groupOptions = options.filter(group => {
    if (!group.label) return false;
    return group.label.toLocaleLowerCase().includes(string);
  });

  if (groupOptions) {
    for (const groupOption of groupOptions) {
      // Check if current option is in group
      const option = groupOption.options.find(opt => opt[valueKey] === value);
      if (option) {
        return true;
      }
    }
  }
  return false;
};

const formatGroupLabel = data => (
  <div className="d-flex font-sm justify-content-between text-primary" style={{ textTransform: "none" }}>
    <span>{data.label}</span>
    <span className="align-items-center badge badge-pill badge-primary d-flex justify-content-center">
      {data.options.length}
    </span>
  </div>
);

const Select = props => {
  const reqErrorMsg = `Please select ${props.title}`;

  const {
    isReq,
    title = "",
    outerClassName,
    className,
    classNamePrefix,
    placeholder,
    isClearable,
    options,
    value,
    isLoading,
    name,
    valueKey,
    labelKey,
    error,
    isMulti,
    isHeading,
    menuPlacement,
    styles,
    smallSize,
    width,
    outerWidth,
    isDisabled,
    height,
    isCreatableSelect,
    refFunc,
    defaultMenuIsOpen,
    menuIsOpen,
    filterOptionFunc,
    autoFocus,
    isOptionDisabled,
  } = props;

  const styleObj = {
    maxHeight: 30,
    height: 30,
    minHeight: 0,
    flexWrap: "unset",
    fontSize: 13,
  };
  if (width) styleObj.width = width;
  if (height) {
    styleObj.maxHeight = height;
    styleObj.height = height;
  }
  const smallStyles = {
    control: styles => {
      return {
        ...styles,
        ...styleObj,
      };
    },
    indicatorSeparator: styles => {
      return {
        ...styles,
        marginTop: 4,
        marginBottom: 4,
      };
    },
  };

  const inputProps = {
    className,
    classNamePrefix,
    options,
    valueKey,
    labelKey,
    placeholder: isLoading ? "Loading" : placeholder === null ? `Select ${title}` : placeholder,
    isClearable,
    isMulti,
    menuPlacement,
    isDisabled,
    isLoading,
  };

  if (refFunc) inputProps.ref = refFunc;
  if (filterOptionFunc) inputProps.filterOption = filterOptionFunc;
  if (menuIsOpen) inputProps.menuIsOpen = menuIsOpen;
  if (defaultMenuIsOpen) inputProps.defaultMenuIsOpen = defaultMenuIsOpen;
  if (name) inputProps.name = name;
  if (isOptionDisabled) inputProps.isOptionDisabled = isOptionDisabled;
  if (value !== "" || value !== null || value !== undefined) {
    if (isMulti) {
      const valueArr = [];
      if (value) value.split(",").map(v => valueArr.push(getObjectByValue(options, valueKey, labelKey, v)));
      inputProps.value = valueArr;
    } else {
      inputProps.value = getObjectByValue(options, valueKey, labelKey, value, isHeading, isCreatableSelect);
    }
  } else if (value === "" || value == null || value == undefined) {
    inputProps.value = "";
  }
  if (smallSize) inputProps.styles = smallStyles;
  else if (styles) inputProps.styles = styles;

  let Select = ReactSelect;
  if (isCreatableSelect) {
    Select = CreatableSelect;
    inputProps.components = components;
  }

  if (autoFocus) inputProps.autoFocus = true;
  if (isHeading) {
    inputProps.formatGroupLabel = formatGroupLabel;
    inputProps.styles = {
      option: styles => {
        return {
          ...styles,
          paddingLeft: 20,
        };
      },
    };
  }

  return (
    <div
      className={`form-group${outerClassName ? ` ${outerClassName}` : ""}${smallSize ? "" : " s-ddl-style"}`}
      style={{ width: outerWidth ? outerWidth : undefined }}
    >
      {/* {title ? <label className="col-form-label">
        {title}
        {isReq && <span className="req-msg">*</span>}
      </label> : null} */}
      <Select
        {...inputProps}
        // filterOption={(obj, string) => filterOption(obj, string, options, valueKey)}
        getOptionLabel={option => option[labelKey]}
        getOptionValue={option => option[valueKey]}
        onChange={e => changeHandler(e, props, reqErrorMsg)}
        onBlur={e => validationHandler(e, props, reqErrorMsg)}
      />
      {error && <span className="req-msg">{error === true ? reqErrorMsg : error}</span>}
    </div>
  );
};

Select.defaultProps = {
  isReq: null,
  outerClassName: null,
  className: "form-control select-control b0 p0",
  classNamePrefix: "prefix-select",
  placeholder: null,
  isClearable: true,
  options: [],
  valueKey: "value",
  labelKey: "label",
  isMulti: false,
  isHeading: false,
  menuPlacement: "auto",
  styles: null,
  smallSize: false,
  width: null,
  outerWidth: null,
  isDisabled: false,
  isOptionDisabled: null,
  isLoading: false,
  onChangeFunc: () => {},
  onBlurFunc: () => {},
  isCreatableSelect: false,
  refFunc: null,
  defaultMenuIsOpen: false,
  menuIsOpen: false,
  filterOptionFunc: null,
  autoFocus: false,
};

Select.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  outerClassName: PropTypes.string,
  className: PropTypes.string,
  classNamePrefix: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  value: PropTypes.any,
  options: PropTypes.array,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  onChangeFunc: PropTypes.func,
  onBlurFunc: PropTypes.func,
  isReq: PropTypes.bool,
  error: PropTypes.any,
  isMulti: PropTypes.bool,
  isHeading: PropTypes.bool,
  menuPlacement: PropTypes.string,
  styles: PropTypes.object,
  smallSize: PropTypes.bool,
  width: PropTypes.any,
  outerWidth: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCreatableSelect: PropTypes.bool,
  refFunc: PropTypes.func,
  defaultMenuIsOpen: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  filterOptionFunc: PropTypes.func,
  autoFocus: PropTypes.bool,
  isOptionDisabled: PropTypes.func,
};

export default Select;
