import React, { Component } from "react";
import { Card, CardBody, InputGroup } from "reactstrap";
import { Button, Input } from "../../common/components";

// import { withRouter } from "../../withRouter";
import { connect } from "react-redux";
import { handlePopup } from "../../actions";
import CommonUtils from "../../utils/CommonUtils.js";
import { getFormDetails } from "../../common/components/helper";
import { getMerchantLogo, isArray, logoutApp } from "../../utils/GeneralUtils";
import FooterContent from "../../components/FooterContent";
import { sessionCLogin } from "../../utils/SessionUtils";
import { withRouter } from "../../withRouter.js";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      errors: {
        email: null,
      },
      apiSubmit: false,
      mId: null,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
  }

  componentDidMount() {
    this.setState({ mId: sessionCLogin() });
  }

  handleSubmit(e) {
    e.preventDefault();
    let { email, errors } = this.state;
    let obj = getFormDetails({ email, errors });
    if (!obj) return false;
    this.setState({ apiSubmit: true });
    const messages = [
      {
        error: "Invalid User",
        response:
          "Your account does not exist. Please contact <a href='mailto:support@purplegrids.com'/>support@purplegrids.com</a> with your email id to reset your password.",
      },
      { error: "success", response: "Password reset instructions has been sent to your email address." },
      {
        error: "Multiple registrations found",
        response:
          "You have multiple user accounts configured. Please contact <a href='mailto:support@purplegrids.com'/>support@purplegrids.com</a> with your email id to reset your password.",
      },
      { error: "External User cannot reset password", response: "Password Reset unavailable for External Account." },
    ];

    CommonUtils.forgotPasswordPost(email).then(response => {
      if (response.error) {
        this.setState({ apiSubmit: false }, () => {
          this.props.handlePopup({
            isError: true,
            message: "Entered email address is not registered.",
          });
        });
        return false;
      }
      this.setState({ apiSubmit: false }, () => {
        const popupObj = {
          message: response.errorDescription
            ? displayMessage(messages, response.errorDescription)
            : displayMessage(messages, "success"),
          onOkClick: () => {
            this.props.handlePopup();
            if (response.errorDescription === "success") {
              logoutApp(this.props.navigate);
            }
          },
        };
        if (response.errorDescription === "success") {
          popupObj.isSuccess = true;
        } else {
          popupObj.isError = true;
        }
        this.props.handlePopup(popupObj);
      });
    });
  }
  onInputChange(name, value) {
    this.setState({ [name]: value });
  }

  onInputValidate(name, error) {
    const { errors } = this.state;
    errors[name] = error;
    this.setState({ errors });
  }

  render() {
    let { email, apiSubmit, errors, mId } = this.state;

    return (
      <div className="login-v1">
        <div className="content m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={getMerchantLogo(mId)} height="100%" />
              </div>
              <CardBody className={`text-gray-700 ${!mId ? "pb-0" : ""}`}>
                <form onSubmit={this.handleSubmit.bind(this)}>
                  <div className="vc-fw-600 fs21 text-center text-muted">Forgot Password</div>
                  <p className="mb-4 text-center text-muted">
                    Enter your email below and
                    <br />
                    we'll send you password reset instructions.
                  </p>
                  <div className="mb-2">
                    <label className="vc-fw-600">Email</label>
                    <Input
                      outerClassName="mb0"
                      name="email"
                      placeholder="Enter Email"
                      value={email}
                      autoComplete="email"
                      title="Email"
                      isReq={true}
                      reqType="email"
                      error={errors.email}
                      onChangeFunc={this.onInputChange}
                      validationFunc={this.onInputValidate}
                    />
                  </div>
                  <Button
                    text="Submit"
                    submitBtn
                    type="submit"
                    className="px-4 w-100 mt-3"
                    style={{ height: 40 }}
                    size="normal"
                    displayIcon={false}
                    loading={apiSubmit}
                  />
                  <div className="link-box mt-3 text-center vc-fw-600 fs13">
                    Already have an account?{" "}
                    <a
                      href="javascript:;"
                      className="link"
                      onClick={() => {
                        logoutApp(this.props.navigate);
                      }}
                    >
                      Sign in
                    </a>
                  </div>
                  {!mId && (
                    <div className="footer text-muted mt-5">
                      <FooterContent />
                    </div>
                  )}
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}
const mapDispatchToProps = {
  handlePopup,
};

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));

function displayMessage(messages, responseErrorDescription) {
  const arrMsg = messages.filter(a => a.error.includes(responseErrorDescription)).map(a => a.response);
  return isArray(arrMsg) ? arrMsg[0] : "";
}
