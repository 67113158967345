import React from "react";
import { Button, Input, ScrollBox, TextArea, Select, CopyToClipboard, PasswordInput } from "../../../common/components";
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Badge, Label } from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import {
  ElementForm,
  SelectElement,
  SelectAttribute,
  SubmitElement,
  SelectIntent,
  ActionSetUserData,
  ActionDecision,
  ActionJavaScript,
} from "./components";
import {
  isJsonString,
  getAllQueryParams,
  isURL,
  isObjEmpty,
  anchorUrl,
  getRandomNumber,
  mapEnvironment,
  isArray,
  handleRequestAPI,
} from "../../../utils/GeneralUtils";
import { getAttrByPrevElement, getBotIntent, prepareStructure } from "../../../utils/BotUtils";
import { getFormDetails } from "../../../common/components/helper.js";
import CommonUtils from "../../../utils/CommonUtils";
import { BOT_ATTRIBUTES } from "../../../utils/ConstUtils";
import { cloneDeep, get } from "lodash";
import ReadMore from "../../../common/components/ReadMore";
import { sessionMerchantId } from "../../../utils/SessionUtils.js";

class IntegrationApi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apiVerified: false,
      type: "GET",
      url: "",
      urlTarget: 0,
      activeTab: "params",
      listKey: Date.now(),
      paramsList: [{ key: "", value: "" }],
      headersList: [{ key: "", value: "" }],
      bodyList: [{ key: "", value: "" }],
      bodyListEncoded: [{ key: "", value: "" }],
      environmentList: [{ key: "", value: "" }],
      apiStatusCode: "",
      response: null,
      objResponse: null,
      authType: "NO_AUTH",
      bearerToken: "",
      username: "",
      password: "",
      bodyData: "raw",
      contentType: "application/json",
      rawSelect: "JSON",
      rawData: "",
      rawDataTarget: 0,
      responsedisplay: "pretty",
      apiProcess: false,
      requestFailed: false,
      errorFlow: "stopFlow",
      defaultError: "",
      errorElementId: getRandomNumber(6),
      errorElement: "",
      timeout: null,
      intent: null,
      step: 1,
      nextIntent: "",
      jsonStructure: [],
      prevElementStructure: [],
      actionJS: null,
      actionAttr: null,
      actionDecision: null,

      integrationId: null,
      integrationList: [],
      integrationConfig: null,

      errors: {
        url: null,
        integrationId: null,
      },
    };

    this.verificationKey = null;
    this.rawSelectOpt = [
      { label: "Text", value: "Text" },
      { label: "Javascript", value: "Javascript" },
      { label: "JSON", value: "JSON" },
      { label: "HTML", value: "HTML" },
      { label: "XML", value: "XML" },
    ];
    this.authTypeOpt = [
      { label: "No Auth", value: "NO_AUTH" },
      { label: "Bearer Token", value: "BEARER_TOKEN" },
      { label: "Basic Auth", value: "BASIC_AUTH" },
    ];

    this.contentTypeOpt = [
      { label: "JSON", value: "application/json" },
      { label: "form-data", value: "application/form-data" },
      { label: "x-www-form-urlencoded", value: "application/x-www-form-urlencoded" },
    ];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getValidation = this.getValidation.bind(this);
    this.handleSendUrl = this.handleSendUrl.bind(this);
    this.verifyAPI = this.verifyAPI.bind(this);
    this.onChangePrevElement = this.onChangePrevElement.bind(this);
    this.onChangeErrorElement = this.onChangeErrorElement.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.onChangeService = this.onChangeService.bind(this);
  }

  componentDidMount() {
    this.loadIntegrations();

    const { boxes, botElementId } = this.props;
    const box = boxes.find(b => b.id === botElementId);
    let bodyList = [{ key: "", value: "" }];
    let bodyListEncoded = [{ key: "", value: "" }];
    if (box) {
      const {
        type,
        url,
        description,
        bearerToken,
        headersList,
        environmentList,
        paramsList,
        rawData,
        authType,
        bodyData,
        contentType,
        intent,
        nextIntent,
        jsonStructure,
        errorElementId,
        timeout,
        errorElement,
        listData,
        actionJS,
        actionAttr,
        actionDecision,
        integrationId,
        authorization,
      } = box.payload;
      const cType = contentType || (bodyData === "raw" ? "application/json" : bodyData) || "application/json";
      let cTypeStr = cType;
      if (cType === "x-www-form-urlencoded" || cType === "application/x-www-form-urlencoded") {
        cTypeStr = "application/x-www-form-urlencoded";
        bodyListEncoded = isArray(listData) ? listData : [{ key: "", value: "" }];
      }
      if (cType === "form-data" || cType === "application/form-data") {
        cTypeStr = "application/form-data";
        bodyList = isArray(listData) ? listData : [{ key: "", value: "" }];
      }

      const headers = [...headersList];
      const auth = { authType, bearerToken };

      for (let i = 0; i < headers.length; i++) {
        const header = headers[i];

        if (header.key.toLowerCase() === "authorization" && header.value.toLowerCase().startsWith("bearer")) {
          auth.authType = "bearer token";
          auth.bearerToken = header.value;
          headers.splice(i, 1);
          i--;
        }
      }

      this.setState(
        {
          type,
          url,
          bearerToken: (authorization && authorization.token) || auth.bearerToken || "",
          username: (authorization && authorization.username) || "",
          password: (authorization && authorization.password) || "",
          headersList: headers,
          actionJS,
          actionAttr,
          actionDecision,
          description,
          environmentList: isArray(environmentList) ? environmentList : [{ key: "", value: "" }],
          paramsList: isArray(paramsList) ? paramsList : [{ key: "", value: "" }],
          rawData,
          authType:
            (authorization && authorization.type) || (auth.authType === "bearer token" ? "BEARER_TOKEN" : "NO_AUTH"),
          jsonStructure,
          contentType: cTypeStr,
          bodyList,
          bodyListEncoded,
          nextIntent,
          errorElementId,
          timeout,
          errorElement,
          intent: intent || null,
          activeTab: type === "POST" ? "body" : "params",
          integrationId,
        },
        () => this.verifyAPI(true)
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.selectedElement.id !== nextProps.selectedElement.id) {
      this.loadIntegrations(nextProps.selectedElement.id);
    }
  }

  async loadIntegrations(integrationName = this.props.selectedElement.id) {
    const id = integrationName.replace("INT_API_", "").replace(/-/g, " ");
    let list = get(this.props.merchantAttr, "data.integrations", []).filter(
      x => x.service.toLowerCase() === id && x.status === "Active"
    );
    const configList = get(this.props.merchantAttr, "data.integrationConfig", {});
    const integrationConfig = (configList[Object.keys(configList).find(x => x.toLowerCase() === id)] || []).find(
      x => x.status === "Active"
    );

    list = list.filter(l => l.name);
    this.setState({ integrationList: list, integrationConfig });
    let mainMerchantList = [];

    if (sessionMerchantId() != 100) {
      const res = await CommonUtils.getMerchantDetails(100);
      if (!res.error) {
        mainMerchantList = get(res, "merchant.integrations", []).filter(
          x => x.service.toLowerCase() === id && x.status === "Active"
        );
      }
    }
    mainMerchantList = mainMerchantList.filter(m => m.name);
    this.setState({ integrationList: [...mainMerchantList, ...list] });
  }

  onChangeService(name, value) {
    const { integrationList, integrationConfig } = this.state;
    const stateObj = { [name]: value };
    let obj = integrationList.find(x => x.integrationId === value);
    if (obj) {
      //const mappingData = cloneDeep(obj);
      //const envData = obj.envVariables;
      //delete mappingData.envVariables;

      obj = mapEnvironment(
        obj.envVariables.filter(x => x.key && x.value),
        obj
      );

      // obj = {
      //   ...mappedData,
      //   envVariables: obj.envVariables,
      // };

      if (integrationConfig) {
        let objStr = JSON.stringify(obj);
        Object.keys(integrationConfig).map(k => {
          const myRegExp = new RegExp(`{{${k.toUpperCase()}}}`);
          objStr = objStr.replace(myRegExp, integrationConfig[k]);
        });
        obj = JSON.parse(objStr);
      }

      stateObj.type = obj.requestMethod;
      stateObj.url = obj.requestUrl;
      stateObj.description = obj.description;
      stateObj.contentType = obj.contentType;
      stateObj.authType = "NO_AUTH";
      stateObj.bearerToken = "";
      stateObj.username = "";
      stateObj.password = "";
      stateObj.rawData = "";
      stateObj.bodyList = [{ key: "", value: "" }];
      stateObj.bodyListEncoded = [{ key: "", value: "" }];

      if (obj.contentType === "application/json" || obj.contentType === "") {
        stateObj.rawData = obj.payload || "";
        stateObj.bodyList = [{ key: "", value: "" }];
      } else if (obj.contentType === "application/form-data") {
        stateObj.rawData = "";
        const bodyList = obj.payload;
        stateObj.bodyList = bodyList;
      } else if (obj.contentType === "application/x-www-form-urlencoded") {
        stateObj.rawData = "";
        const bodyList = obj.payload;
        stateObj.bodyListEncoded = bodyList;
      }

      let headers = [];
      let authHeader;

      for (const header of obj.requestHeaders) {
        const key = header.key.toLowerCase();
        const value = header.value.toLowerCase();
        if (key === "authorization" && value.startsWith("bearer")) {
          authHeader = header;
        } else {
          headers.push(header);
        }
      }
      if (authHeader) {
        stateObj.authType = "BEARER_TOKEN";
        stateObj.bearerToken = authHeader.value;
      }

      if (obj.requestAuth) {
        const { type, token, username, password } = obj.requestAuth;
        switch (type) {
          case "BEARER_TOKEN":
            stateObj.authType = type;
            stateObj.bearerToken = token;
            break;
          case "BASIC_AUTH":
            stateObj.authType = type;
            stateObj.username = username;
            stateObj.password = password;
            break;
        }
      }

      const headersList = [];
      if (obj.contentType === "application/json") {
        headersList.push({ key: "Content-Type", value: obj.contentType });
      }

      if (isArray(headers)) {
        headersList.push(...headers);
      }

      if (headersList.length) {
        stateObj.headersList = headersList;
      } else {
        stateObj.headersList = [{ key: "", value: "" }];
      }
      //stateObj.environmentList = envData && isArray(envData) ? envData : [{ key: "", value: "" }];
    }

    this.setState(stateObj, () => {
      this.setParams(this.state.url);
    });
  }

  onInputValidate(name, error) {
    let errors = this.state.errors;
    errors[name] = error;
    this.setState({ errors });
  }

  handleStep(step, isSubmit = false) {
    const stateObj = { step };
    if (step === 2) {
      const obj = getFormDetails(this.state, this.onInputValidate);
      if (!obj) return false;
    }
    if (step === 3 && this.actionJavaScriptRef) {
      const obj = this.actionJavaScriptRef.getData();
      if (!obj) return false;
      stateObj.actionJS = obj.skip ? null : obj.data;
      if (!obj.skip && obj.jsonStructure) {
        stateObj.jsonStructure = obj.jsonStructure;
        this.generateElementStructure(obj.jsonStructure);
      }
    }
    if (step === 4 && this.actionSetUserDataRef) {
      const obj = this.actionSetUserDataRef.getData();
      if (!obj) return false;
      stateObj.actionAttr = obj.skip ? null : obj.data;
      if (!obj.skip && obj.attrStructure) {
        stateObj.jsonStructure = [...this.state.jsonStructure, ...obj.attrStructure];
        this.generateElementStructure(stateObj.jsonStructure);
      }
    }
    if (step === 5 && this.actionDecisionRef) {
      const obj = this.actionDecisionRef.getData();
      if (!obj) return false;
      stateObj.actionDecision = obj.skip ? null : obj;
    }

    if (isSubmit) stateObj.step--;

    this.setState(stateObj, () => {
      if (isSubmit) {
        this.handleSubmit();
      }
    });
  }

  handleSubmit() {
    const {
      type,
      description,
      rawData,
      authType,
      headersList,
      environmentList,
      bearerToken,
      username,
      password,
      prevElement,
      errorElementId,
      timeout,
      errorElement,
      contentType,
      bodyList,
      bodyListEncoded,
      paramsList,
      intent,
      nextIntent,
      jsonStructure,
      actionJS,
      actionAttr,
      actionDecision,
      integrationId,
    } = this.state;
    let { url } = this.state;
    const { handleSubmitElement, selectedElement, boxes, botVersion } = this.props;
    let templatePayload = "";

    let listData = [];

    if (!url) return false;
    if (contentType === "application/x-www-form-urlencoded") {
      listData = bodyListEncoded.filter(x => x.key);
    }
    if (contentType === "application/form-data") {
      listData = bodyList.filter(x => x.key);
    }
    if (anchorUrl(url).search.length === 0) {
      if (paramsList.length > 0 && paramsList[0].key) {
        paramsList.map((p, i) => {
          url += `${i === 0 ? "?" : "&"}${p.key}=${p.value}`;
        });
      }
    }

    let auth = {};
    if (authType === "NO_AUTH") {
      auth = {};
    } else if (authType === "BEARER_TOKEN") {
      auth = {
        type: "BEARER_TOKEN",
        token: bearerToken,
      };
    } else if (authType === "BASIC_AUTH") {
      auth = {
        type: "BASIC_AUTH",
        username: username,
        password: password,
      };
    }
    if (type !== "GET") {
      if (contentType === "application/json") {
        templatePayload = rawData;
      } else {
        templatePayload = listData;
      }
    }

    const elError = boxes.find(x => x.id === errorElement);
    let data = {
      input_type: selectedElement.id,
      next_intent: nextIntent,
      request_params: [
        {
          url: url,
          description: description,
          needAuth: "no", // static
          apiName: selectedElement.label, // - name given by user for node
          appendApiName: "no", // static
          connectorType: "REST", // static
          responseType: "SYNC", // static
          errorIntent: getBotIntent(elError, botVersion),
          timeout,
          type: type,
          params: [], // static
          requestHeaders: headersList.filter(x => x.key && x.value),
          requestAuth: auth,
          merchantId: BOT_ATTRIBUTES.MERCHANT_ID,
          templatePayload,
          version: "1",
        },
      ],
    };
    if (type !== "GET") {
      data.request_params[0]["contentType"] = contentType;
    }

    if (actionJS || isArray(actionAttr) || actionDecision) {
      data.customAction = {};

      if (actionJS) {
        data.customAction.javascript = actionJS;
      }
      if (isArray(actionAttr)) {
        data.customAction.attributes = actionAttr;
      }
      if (actionDecision) {
        data.customAction.decision = actionDecision;
      }
    }

    const aqr = get(data, `customAction.decision.qr`, []);

    const payload = {
      type,
      url,
      description,
      paramsList,
      headersList,
      environmentList: environmentList.filter(x => x.key && x.value),
      rawData: type === "GET" ? "" : rawData,
      listData,
      errorElementId,
      errorElement,
      timeout,
      contentType: type === "GET" ? "" : contentType,
      intent,
      nextIntent,
      jsonStructure,
      qr: [...aqr],
      actionJS,
      actionAttr,
      actionDecision,
      integrationId,
      authorization: auth,
    };
    if (data.request_params[0].errorIntent) {
      payload.qr.unshift({
        id: errorElementId,
        text: "Error",
        connection: errorElement,
      });
    }
    handleSubmitElement(data, prevElement, payload);
  }

  setParams(url) {
    let query = anchorUrl(url).search;
    let { paramsList } = this.state;
    let list = [];
    let params;
    if (isURL(url)) {
      if (query.length > 0) {
        params = getAllQueryParams(url);
        if (!isObjEmpty(params)) {
          for (const [key, value] of Object.entries(params)) {
            let paramEntry = {
              key: key,
              value: value != "undefined" ? value : "",
            };
            list.push(paramEntry);
          }
        }
      } else {
        paramsList = [{ key: "", value: "" }];
      }
    }
    list.length > 0 ? (paramsList = list) : null;
    this.setState({ paramsList }, () => {
      this.verifyAPI();
    });
  }

  verifyAPI(isVerified = false) {
    const {
      url,
      type,
      bodyData,
      rawSelect,
      rawData,
      paramsList,
      headersList,
      bodyList,
      bodyListEncoded,
      authType,
      bearerToken,
    } = this.state;
    let apiVerified = isVerified;

    const verificationKey = JSON.stringify({
      url,
      type,
      bodyData,
      rawSelect,
      rawData,
      paramsList,
      headersList,
      bodyList,
      bodyListEncoded,
      authType,
      bearerToken,
    });

    if (url && this.verificationKey === verificationKey) {
      apiVerified = true;
    } else if (apiVerified) {
      this.verificationKey = verificationKey;
    }
    this.setState({ apiVerified });
  }

  getValidation(e) {
    const { name, value } = e.currentTarget;

    const stateObj = { [name]: value };
    if (name === "type") {
      stateObj.activeTab = "params";
      if (value === "POST") {
        stateObj.activeTab = "body";
      }
    }

    this.setState(stateObj, () => {
      if (name === "responsedisplay") {
        this.setResponse();
      }
      if (["type", "contentType"].includes(name)) {
        this.verifyAPI();
      }
    });
  }

  setResponse() {
    const { responsedisplay, objResponse } = this.state;
    let response = objResponse;
    try {
      switch (responsedisplay) {
        case "pretty":
          response = JSON.stringify(objResponse, null, 2);
          break;
        case "raw":
          response = JSON.stringify(objResponse);
          break;
        default:
          response = objResponse;
          break;
      }
    } catch (e) {}
    this.setState({ response });
  }

  async handleSendUrl() {
    const {
      type,
      headersList,
      bodyList,
      rawData,
      authType,
      bearerToken,
      username,
      password,
      environmentList,
      contentType,
    } = this.state;
    const customConfigData = this.props.merchantAttr.data.customConfig;
    let { url, bodyListEncoded } = this.state;
    if (!url) return;

    let listData = [];

    if (contentType === "application/json" && rawData && !isJsonString(rawData)) {
      this.setState({ apiStatusCode: 400, jsonStructure: [], response: "Invalid body", requestFailed: true }, () =>
        this.verifyAPI()
      );
      return;
    }
    if (contentType === "application/x-www-form-urlencoded") {
      listData = bodyListEncoded.filter(l => l.key && l.value);
    }
    if (contentType === "application/form-data") {
      listData = bodyList.filter(l => l.key && l.value);
    }
    const request = {
      url,
      type,
      headersList,
      authType,
      bearerToken,
      username,
      password,
      rawData,
      environmentList,
      contentType,
      bodyList: listData,
    };
    this.setState({ apiProcess: true });
    const res = await handleRequestAPI(request, [], customConfigData);
    const stateObj = { apiStatusCode: res.status + " " + res.statusText };
    if (!res.success) {
      this.setState(
        { ...stateObj, jsonStructure: [], objResponse: res.data || res.error, apiProcess: false, requestFailed: true },
        () => {
          this.verifyAPI();
          this.setResponse();
        }
      );
      return;
    }

    const jsonStructure = prepareStructure(res.data);
    this.setState(
      { ...stateObj, jsonStructure, objResponse: res.data, apiProcess: false, requestFailed: false },
      () => {
        this.generateElementStructure(jsonStructure);
        this.verifyAPI(true);
        this.setResponse();
      }
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  addFeature(listName) {
    let list = this.state[listName];
    list.push({
      key: "",
      value: "",
    });
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  removeFeature(listName, index) {
    let list = this.state[listName];
    list.splice(index, 1);
    if (listName == "paramsList") {
      this.updateUrl(list);
    }
    this.setState({ [listName]: list, listKey: Date.now() });
  }

  updateUrl(paramsList, listName) {
    let { url } = this.state;
    url = url.split("?")[0];
    if (paramsList.length > 0 && paramsList[0].key) {
      paramsList.map((p, i) => {
        url += `${i === 0 ? "?" : "&"}${p.key}=${p.value}`;
      });
    }
    this.setState({ url });
  }

  onChangeFeature(name, value, index, listName) {
    let list = this.state[listName];
    let isParams = listName == "paramsList";
    list[index][name] = value;
    this.setState({ [listName]: list }, () => {
      this.verifyAPI(), isParams ? this.updateUrl(list) : null;
    });
  }

  generateElementStructure(externalStructure = []) {
    const { boxes, elementType } = this.props;
    const structure = getAttrByPrevElement(this.state.prevElement, boxes, elementType);
    this.setState({ prevElementStructure: [...structure, ...externalStructure] });
  }

  onChangePrevElement(name, value) {
    this.setState({ [name]: value }, () => this.generateElementStructure());
  }
  onChangeErrorElement(name, value) {
    this.setState({ [name]: value });
  }
  renderTab() {
    const {
      type,
      activeTab,
      paramsList,
      bodyList,
      bodyListEncoded,
      listKey,
      authType,
      bearerToken,
      username,
      password,
      prevElementStructure,
      contentType,
      rawData,
      rawDataTarget,
      errorFlow,
      defaultError,
      headersList,
      environmentList,
      timeout,
      errorElement,
    } = this.state;
    // const { attributeList } = this.props;
    const { botElementId, boxes, connectors, attributeList } = this.props;

    return (
      <div className="postman-head">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "params" })}
              onClick={() => {
                this.toggle("params");
              }}
            >
              {" "}
              Params
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "authorization" })}
              onClick={() => {
                this.toggle("authorization");
              }}
            >
              {" "}
              Authorization
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "headers" })}
              onClick={() => {
                this.toggle("headers");
              }}
            >
              {" "}
              Headers
            </NavLink>
          </NavItem>
          {["POST", "PUT", "PATCH"].includes(type) && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "body" })}
                onClick={() => {
                  this.toggle("body");
                }}
              >
                {" "}
                Body
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "errors" })}
              onClick={() => {
                this.toggle("errors");
              }}
            >
              Errors
            </NavLink>
          </NavItem>
          {environmentList.length > 0 && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "environment" })}
                onClick={() => {
                  this.toggle("environment");
                }}
              >
                Environment{" "}
                <Badge pill color="orange">
                  {environmentList.filter(x => x.key && x.value).length}
                </Badge>
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="params">
            {activeTab === "params" &&
              paramsList.map((obj, index) => {
                return (
                  <div key={index + listKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "paramsList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            appendIcon={
                              <SelectAttribute
                                attributeList={attributeList}
                                externalAttributes={prevElementStructure}
                                onChangeFunc={value => this.onChangeFeature("value", value, index, "paramsList")}
                                setAttribute={[null, obj.value]}
                              />
                            }
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "paramsList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === paramsList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("paramsList")} />
                            ) : null}
                            {index !== 0 || paramsList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("paramsList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
          <TabPane tabId="authorization">
            {activeTab === "authorization" && (
              <div className="d-flex">
                <div className="pr-4 w-100" style={{ minWidth: 180, maxWidth: 250 }}>
                  <Label>Auth Type</Label>
                  <Select
                    name="authType"
                    value={authType}
                    options={this.authTypeOpt}
                    isClearable={false}
                    onChangeFunc={(name, value) => this.setState({ [name]: value }, () => this.verifyAPI())}
                  />
                </div>
                <div className="border-left border-gray-200 pl-4 w-100">
                  {authType === "NO_AUTH" && (
                    <Label className="mt5">This request does not use any authorization.</Label>
                  )}
                  {authType === "BEARER_TOKEN" && (
                    <React.Fragment>
                      <Label>Token</Label>
                      <Input
                        placeholder="Token"
                        name="bearerToken"
                        value={bearerToken}
                        onChangeFunc={(name, value) => this.setState({ [name]: value }, () => this.verifyAPI())}
                      />
                    </React.Fragment>
                  )}

                  {authType === "BASIC_AUTH" && (
                    <div style={{ maxWidth: 300 }}>
                      <Label>Username</Label>
                      <Input
                        placeholder="Username"
                        name="username"
                        value={username}
                        onChangeFunc={(name, value) => this.setState({ [name]: value })}
                      />
                      <Label>Password</Label>
                      <PasswordInput
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChangeFunc={(name, value) => this.setState({ [name]: value })}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tabId="headers">
            {activeTab === "headers" &&
              headersList.map((obj, index) => {
                return (
                  <div key={index + listKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "headersList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Value"
                            name="value"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "headersList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === headersList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("headersList")} />
                            ) : null}
                            {index !== 0 || headersList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("headersList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
          <TabPane tabId="body">
            {activeTab === "body" && (
              <div>
                <Row className="mr-2">
                  <Col>
                    <Label>Content Type</Label>
                    <Select
                      name="contentType"
                      value={contentType}
                      options={this.contentTypeOpt}
                      isClearable={false}
                      outerWidth={250}
                      onChangeFunc={(name, value) => this.setState({ [name]: value }, () => this.verifyAPI())}
                    />
                  </Col>
                </Row>
                {contentType === "application/json" && (
                  <Row className="mt5">
                    <Col>
                      <div className="position-relative">
                        <TextArea
                          name="rawData"
                          value={rawData}
                          appendIcon={
                            <SelectAttribute
                              attributeList={attributeList}
                              outerAttriClass="text-area-icon"
                              externalAttributes={prevElementStructure}
                              onChangeFunc={value => this.setState({ rawData: value }, () => this.verifyAPI())}
                              setAttribute={[rawDataTarget, rawData]}
                            />
                          }
                          onChangeFunc={(name, value, e) =>
                            this.setState({ [name]: value, rawDataTarget: e.target }, () => this.verifyAPI())
                          }
                        />
                        <CopyToClipboard clsName="float-right pr-0" value={rawData} />
                      </div>
                    </Col>
                  </Row>
                )}
                {contentType === "application/form-data" &&
                  bodyList.map((obj, index) => {
                    return (
                      <div key={index + listKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                        <div className="input-box">
                          <Row>
                            <Col md={5}>
                              <Input
                                type="text"
                                placeholder="Enter Key"
                                name="key"
                                value={obj.key}
                                onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "bodyList")}
                              />
                            </Col>
                            <Col md={5}>
                              <Input
                                type="text"
                                placeholder="Enter Value"
                                name="value"
                                value={obj.value}
                                onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "bodyList")}
                              />
                            </Col>
                            <Col md={2}>
                              <div className="btn-box mb5" style={{ width: "60px" }}>
                                {index === bodyList.length - 1 ? (
                                  <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("bodyList")} />
                                ) : null}
                                {index !== 0 || bodyList.length > 1 ? (
                                  <Button
                                    minusBtn
                                    className="d-inline"
                                    onClickFunc={() => this.removeFeature("bodyList", index)}
                                  />
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    );
                  })}
                {contentType === "application/x-www-form-urlencoded" &&
                  bodyListEncoded.map((obj, index) => {
                    return (
                      <div key={index + listKey} style={{ marginTop: index == 0 ? 5 : 10 }}>
                        <div className="input-box">
                          <Row>
                            <Col md={5}>
                              <Input
                                type="text"
                                placeholder="Enter Key"
                                name="key"
                                value={obj.key}
                                onChangeFunc={(name, value) =>
                                  this.onChangeFeature(name, value, index, "bodyListEncoded")
                                }
                              />
                            </Col>
                            <Col md={5}>
                              <Input
                                type="text"
                                placeholder="Enter Value"
                                name="value"
                                value={obj.value}
                                onChangeFunc={(name, value) =>
                                  this.onChangeFeature(name, value, index, "bodyListEncoded")
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <div className="btn-box mb5" style={{ width: "60px" }}>
                                {index === bodyListEncoded.length - 1 ? (
                                  <Button
                                    plusBtn
                                    className="mr10"
                                    onClickFunc={() => this.addFeature("bodyListEncoded")}
                                  />
                                ) : null}
                                {index !== 0 || bodyListEncoded.length > 1 ? (
                                  <Button
                                    minusBtn
                                    className="d-inline"
                                    onClickFunc={() => this.removeFeature("bodyList", index)}
                                  />
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    );
                  })}
              </div>
            )}
          </TabPane>
          <TabPane tabId="errors">
            {activeTab === "errors" && (
              <div>
                <Row>
                  <Col md={4}>
                    <label>Timeout</label>
                    <br />
                    <Input
                      type="text"
                      placeholder="Enter Timeout"
                      name="timeout"
                      value={timeout}
                      onChangeFunc={this.onChangeErrorElement}
                    />
                  </Col>
                  <Col md={4}>
                    <label>Error Element</label>
                    <br />
                    <SelectElement
                      value={errorElement}
                      boxes={boxes}
                      name="errorElement"
                      placeholder="Select Error Element"
                      // botElementId={botElementId}
                      connectors={connectors}
                      defaultSelected={false}
                      onChangeFunc={this.onChangeErrorElement}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </TabPane>
          <TabPane tabId="environment">
            {activeTab === "environment" &&
              environmentList.map((obj, index) => {
                return (
                  <div key={index} style={{ marginTop: index == 0 ? 5 : 10 }}>
                    <div className="input-box">
                      <Row>
                        <Col md={5}>
                          <Input
                            type="text"
                            placeholder="Enter Key"
                            name="key"
                            value={obj.key}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={5}>
                          <Input
                            type="text"
                            name="value"
                            placeholder="Enter Value"
                            className="form-control pr35"
                            value={obj.value}
                            onChangeFunc={(name, value) => this.onChangeFeature(name, value, index, "environmentList")}
                          />
                        </Col>
                        <Col md={2}>
                          <div className="btn-box mb5" style={{ width: "60px" }}>
                            {index === environmentList.length - 1 ? (
                              <Button plusBtn className="mr10" onClickFunc={() => this.addFeature("environmentList")} />
                            ) : null}
                            {index !== 0 || environmentList.length > 1 ? (
                              <Button
                                minusBtn
                                className="d-inline"
                                onClickFunc={() => this.removeFeature("environmentList", index)}
                              />
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                );
              })}
          </TabPane>
        </TabContent>
      </div>
    );
  }

  renderPlatform() {
    const {
      url,
      urlTarget,
      response,
      responsedisplay,
      apiProcess,
      requestFailed,
      apiStatusCode,
      prevElement,
      prevElementStructure,
      errors,
    } = this.state;
    const { attributeList } = this.props;
    return (
      <React.Fragment>
        <Row>
          <Col md="9" className="pr0">
            <Input
              name="url"
              title="URL"
              placeholder="Enter url"
              value={url}
              className="form-control pr35"
              outerClassName="mt10"
              isReq={true}
              error={errors.url}
              appendIcon={
                <SelectAttribute
                  attributeList={attributeList}
                  externalAttributes={prevElementStructure}
                  onChangeFunc={(value, inputName) =>
                    this.setState({ url: value }, () => {
                      this.verifyAPI(), this.onInputValidate(inputName, null);
                    })
                  }
                  setAttribute={[urlTarget, url]}
                />
              }
              onClickFunc={e => this.setState({ urlTarget: e.target })}
              validationFunc={this.onInputValidate}
              onChangeFunc={(name, value, err, obj, e) =>
                this.setState({ [name]: value, urlTarget: e.target }, () => {
                  this.verifyAPI(), this.setParams(value);
                })
              }
            />
          </Col>
          <Col md="3" className="mt10">
            <Button
              submitBtn
              displayIcon={false}
              style={{ height: "33px" }}
              text="Send"
              loading={apiProcess}
              onClickFunc={this.handleSendUrl}
            />
          </Col>
        </Row>
        <Row>
          <Col>{this.renderTab()}</Col>
        </Row>
        <Row>
          <Col md="12">
            <label className="mt20 float-left font-weight-bold">Response</label>
          </Col>
        </Row>
        {response !== null && (
          <Row>
            <Col md="12">
              <input
                type="radio"
                name="responsedisplay"
                checked={responsedisplay === "pretty"}
                value="pretty"
                className="mr5 cursor-pointer"
                id="pretty"
                onChange={this.getValidation}
              />
              <label className="cursor-pointer mr20" htmlFor="pretty">
                Pretty
              </label>

              <input
                type="radio"
                name="responsedisplay"
                checked={responsedisplay === "raw"}
                value="raw"
                className="mr5 cursor-pointer"
                id="raw"
                onChange={this.getValidation}
              />
              <label className="cursor-pointer mr20" htmlFor="raw">
                Raw
              </label>

              {apiStatusCode && (
                <div className="float-right">
                  Status:
                  <label className={`ml5 ${requestFailed ? "text-danger" : "text-success"}`}>{apiStatusCode}</label>
                </div>
              )}
            </Col>
          </Row>
        )}

        <TextArea value={response} rows={20} name="response" onChangeFunc={() => false} />
        <CopyToClipboard clsName="float-right pr-0" value={response} />
      </React.Fragment>
    );
  }

  renderStep1() {
    const { integrationId, integrationList, prevElement, errors, description } = this.state;
    const { botElementId, boxes, connectors, selectedElement } = this.props;

    if (integrationList.length === 0) {
      return (
        <div className="ele-content">
          <div className="align-items-center d-flex flex-column mt-5">
            <img src={selectedElement.image.src} className="mb-3" width={100} />
            <div className="fs17 text-center text-gray-500 w-50">
              It appears there are no integrations ready for display.
              <br />
              Please{" "}
              <NavLink
                className="d-inline nav-link p-0"
                to={{ pathname: "/settings", state: { tabKey: "integrations" } }}
              >
                click here
              </NavLink>{" "}
              to create a new integration.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="ele-content">
        <div className="d-flex">
          <div className="int-box">
            <img src={selectedElement.image.src} />
          </div>
          <div className="b-form">
            <ElementForm prevElement>
              <SelectElement
                value={prevElement}
                boxes={boxes}
                botElementId={botElementId}
                connectors={connectors}
                onChangeFunc={this.onChangePrevElement}
              />
            </ElementForm>

            <ElementForm title="Service Name">
              <Select
                name="integrationId"
                title="Service Name"
                outerClassName="mb0"
                isReq={true}
                error={errors.integrationId}
                options={integrationList}
                value={integrationId}
                labelKey="name"
                valueKey="integrationId"
                validationFunc={this.onInputValidate}
                onChangeFunc={this.onChangeService}
              />
              {integrationId && description && (
                <ReadMore characters="140" className="integration-desc">
                  {description}
                </ReadMore>
              )}
            </ElementForm>
          </div>
        </div>

        {integrationId ? this.renderPlatform() : null}
      </div>
    );
  }
  renderStep2() {
    const { intent } = this.state;
    const { intentList, boxes, botElementId } = this.props;
    return (
      <div className="ele-content">
        <SelectIntent
          value={intent}
          intentList={intentList}
          botElementId={botElementId}
          boxes={boxes}
          onChangeFunc={(name, value) => this.setState({ [name]: value })}
        />
      </div>
    );
  }

  renderStepJS() {
    return (
      <ActionJavaScript
        {...this.props}
        value={this.state.actionJS}
        dataParam={this.state.objResponse}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionJavaScriptRef = e)}
      />
    );
  }

  renderStepSetAttr() {
    return (
      <ActionSetUserData
        {...this.props}
        value={this.state.actionAttr}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionSetUserDataRef = e)}
      />
    );
  }

  renderStepDecision() {
    return (
      <ActionDecision
        {...this.props}
        value={this.state.actionDecision}
        prevElementStructure={this.state.prevElementStructure}
        ref={e => (this.actionDecisionRef = e)}
      />
    );
  }

  render() {
    const { apiVerified, url, step, integrationList } = this.state;
    const { resetElement, botElementId } = this.props;
    return (
      <React.Fragment>
        {url &&
          (apiVerified ? (
            <span className="verify-lbl text-success">
              <i className="fa fa-check-circle"></i>Verified
            </span>
          ) : (
            <span className="verify-lbl text-danger">
              <i className="fa fa-exclamation-circle"></i>Not Verified
            </span>
          ))}
        <ScrollBox key={step} scrollClass="bt-json-api" boxHeight={400}>
          {step === 1 && this.renderStep1()}
          {step === 2 && this.renderStepJS()}
          {step === 3 && this.renderStepSetAttr()}
          {step === 4 && this.renderStepDecision()}
          {step === 5 && this.renderStep2()}
        </ScrollBox>
        {integrationList.length !== 0 && (
          <SubmitElement
            step={step}
            totalSteps={5}
            resetElement={resetElement}
            botElementId={botElementId}
            handleSubmit={this.handleSubmit}
            handleStep={this.handleStep}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    merchantAttr: state.common.merchantAttr,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(IntegrationApi);
