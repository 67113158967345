import { getAiList } from "./GeneralUtils";

export const actionList = [
  { label: "Node", value: "postback" },
  { label: "Url (Open in new window)", value: "web_url" },
  { label: "Url (Open in same window)", value: "web_url_self" },
  { label: "Call", value: "call" },
];

export const actionMeetingList = [...actionList, { label: "Close", value: "close" }];

export const BOT_ATTRIBUTES = {
  ACCESS_TOKEN: "${(pgt.access_token)!}",
  SESSION_ID: "${(pgt.sessionId)!(userInfo.userId)!}",
  MERCHANT_ID: "${(merchant.id)!}",
  ENGAGEMENT_ID: "${(visitorInfo.activeEngagementID?c)!(visitorInfo.engagements[0].engagementID?c)!}",
  ENGAGEMENT_NAME: "${(visitorInfo.engagements[0].engagementName)!}",
  AI_AGENT: "${(visitorInfo.engagements[0].aiAgent)!}",
  REFERENCE_ID: "${(visitorInfo.referenceID)!}",
  CHANNEL_NAME: "${(visitorInfo.engagements[0].channel)!}",
  ACTIVE_CONVERSATION_ID: "${(visitorInfo.engagements[0].activeConversation)!}",
  MERCHANT_NAME: "${(merchant.merchantName)!}",
  MERCHANT_CURRENCY: "${(merchant.currency)!}",
  VISITOR_COUNTRY: "${(visitorInfo.country)!}",
  MERCHANT_TIMEZONE: "${(merchant.timeZone)!}",
  LAST_ACCESSED_PAGE: "${(visitorInfo.firstAccessedPage)!}",
  SERVICE_ID: "${(userInfo.userId)!}",
  SERVICE_NAME: "${(engagementInfo.channel.name)!}",
  SERVER_NAME: "${(visitorInfo.serverName)!}",
  LANGUAGE: "${(userInput.pgLanguage)!}",
};

export const SAMPLE_IMG = "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==";

export const GUPSHUP_SMS_URL = "https://media.smsgupshup.com/GatewayAPI/rest";
export const GUPSHUP_URL = "https://api.gupshup.io/sm/api/v1/msg";

export const authTypeList = [
  { label: "OTP-Email", value: "OTP-EMAIL" },
  // { label: 'OTP-Mobile', value: 'OTP-MOBILE' },
  // { label: 'Simple Email', value: 'OTP-Mobile' },
  // { label: 'Email and Mobile-User Entered', value: 'Email and Mobile' },
  // { label: 'Fingerprint', value: 'Fingerprint' },
  // { label: 'Single Sign On', value: 'Single Sign On' },
];

export const supportBotAllowedRoles = [
  "CALLCENTER_USER1",
  "CALLCENTER_USER2",
  "CALLCENTER_USER3",
  "CALLCENTER_SUPERVISOR",
  "M_USER",
  "ADMIN",
];

export const botTypeList = [
  { label: "Chat Bot", value: "chatbot" },
  { label: "Flow Bot", value: "flowbot" },
  { label: "Robot", value: "robot" },
];

export const botAccessList = [
  { label: "Private", value: "Private" },
  { label: "Public", value: "Public" },
];

export const LANGUAGE_LIST = [
  { label: "Afar", value: "aa" },
  { label: "Abkhazian", value: "ab" },
  { label: "Avestan", value: "ae" },
  { label: "Afrikaans", value: "af" },
  { label: "Akan", value: "ak" },
  { label: "Amharic", value: "am" },
  { label: "Aragonese", value: "an" },
  { label: "Arabic", value: "ar" },
  { label: "Assamese", value: "as" },
  { label: "Avaric", value: "av" },
  { label: "Aymara", value: "ay" },
  { label: "Azerbaijani", value: "az" },
  { label: "Bashkir", value: "ba" },
  { label: "Belarusian", value: "be" },
  { label: "Bulgarian", value: "bg" },
  { label: "Bihari languages", value: "bh" },
  { label: "Bislama", value: "bi" },
  { label: "Bambara", value: "bm" },
  { label: "Bengali", value: "bn" },
  { label: "Tibetan", value: "bo" },
  { label: "Breton", value: "br" },
  { label: "Bosnian", value: "bs" },
  { label: "Catalan; Valencian", value: "ca" },
  { label: "Chechen", value: "ce" },
  { label: "Chamorro", value: "ch" },
  { label: "Corsican", value: "co" },
  { label: "Cree", value: "cr" },
  { label: "Czech", value: "cs" },
  { label: "Church Slavic; Slavonic; Old Bulgarian", value: "cu" },
  { label: "Chuvash", value: "cv" },
  { label: "Welsh", value: "cy" },
  { label: "Danish", value: "da" },
  { label: "German", value: "de" },
  { label: "Divehi; Dhivehi; Maldivian", value: "dv" },
  { label: "Dzongkha", value: "dz" },
  { label: "Ewe", value: "ee" },
  { label: "Greek, Modern (1453-)", value: "el" },
  { label: "English", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Spanish; Castilian", value: "es" },
  { label: "Estonian", value: "et" },
  { label: "Basque", value: "eu" },
  { label: "Persian", value: "fa" },
  { label: "Fulah", value: "ff" },
  { label: "Finnish", value: "fi" },
  { label: "Fijian", value: "fj" },
  { label: "Faroese", value: "fo" },
  { label: "French", value: "fr" },
  { label: "Western Frisian", value: "fy" },
  { label: "Irish", value: "ga" },
  { label: "Gaelic; Scottish Gaelic", value: "gd" },
  { label: "Galician", value: "gl" },
  { label: "Guarani", value: "gn" },
  { label: "Gujarati", value: "gu" },
  { label: "Manx", value: "gv" },
  { label: "Hausa", value: "ha" },
  { label: "Hebrew", value: "he" },
  { label: "Hindi", value: "hi" },
  { label: "Hiri Motu", value: "ho" },
  { label: "Croatian", value: "hr" },
  { label: "Haitian; Haitian Creole", value: "ht" },
  { label: "Hungarian", value: "hu" },
  { label: "Armenian", value: "hy" },
  { label: "Herero", value: "hz" },
  { label: "Interlingua", value: "ia" },
  { label: "Indonesian", value: "id" },
  { label: "Interlingue; Occidental", value: "ie" },
  { label: "Igbo", value: "ig" },
  { label: "Sichuan Yi; Nuosu", value: "ii" },
  { label: "Inupiaq", value: "ik" },
  { label: "Ido", value: "io" },
  { label: "Icelandic", value: "is" },
  { label: "Italian", value: "it" },
  { label: "Inuktitut", value: "iu" },
  { label: "Japanese", value: "ja" },
  { label: "Javanese", value: "jv" },
  { label: "Georgian", value: "ka" },
  { label: "Kongo", value: "kg" },
  { label: "Kikuyu; Gikuyu", value: "ki" },
  { label: "Kuanyama; Kwanyama", value: "kj" },
  { label: "Kazakh", value: "kk" },
  { label: "Kalaallisut; Greenlandic", value: "kl" },
  { label: "Central Khmer", value: "km" },
  { label: "Kannada", value: "kn" },
  { label: "Korean", value: "ko" },
  { label: "Kanuri", value: "kr" },
  { label: "Kashmiri", value: "ks" },
  { label: "Kurdish", value: "ku" },
  { label: "Komi", value: "kv" },
  { label: "Cornish", value: "kw" },
  { label: "Kirghiz; Kyrgyz", value: "ky" },
  { label: "Latin", value: "la" },
  { label: "Luxembourgish; Letzeburgesch", value: "lb" },
  { label: "Ganda", value: "lg" },
  { label: "Limburgan; Limburger; Limburgish", value: "li" },
  { label: "Lingala", value: "ln" },
  { label: "Lao", value: "lo" },
  { label: "Lithuanian", value: "lt" },
  { label: "Luba-Katanga", value: "lu" },
  { label: "Latvian", value: "lv" },
  { label: "Malagasy", value: "mg" },
  { label: "Marshallese", value: "mh" },
  { label: "Maori", value: "mi" },
  { label: "Macedonian", value: "mk" },
  { label: "Malayalam", value: "ml" },
  { label: "Mongolian", value: "mn" },
  { label: "Marathi", value: "mr" },
  { label: "Malay", value: "ms" },
  { label: "Maltese", value: "mt" },
  { label: "Burmese", value: "my" },
  { label: "Nauru", value: "na" },
  { label: "Norwegian Bokmål", value: "nb" },
  { label: "Ndebele, North; North Ndebele", value: "nd" },
  { label: "Nepali", value: "ne" },
  { label: "Ndonga", value: "ng" },
  { label: "Dutch; Flemish", value: "nl" },
  { label: "Norwegian Nynorsk", value: "nn" },
  { label: "Norwegian", value: "no" },
  { label: "Ndebele, South; South Ndebele", value: "nr" },
  { label: "Navajo; Navaho", value: "nv" },
  { label: "Chichewa; Chewa; Nyanja", value: "ny" },
  { label: "Occitan (post 1500)", value: "oc" },
  { label: "Ojibwa", value: "oj" },
  { label: "Oromo", value: "om" },
  { label: "Oriya", value: "or" },
  { label: "Ossetian; Ossetic", value: "os" },
  { label: "Panjabi; Punjabi", value: "pa" },
  { label: "Pali", value: "pi" },
  { label: "Polish", value: "pl" },
  { label: "Pushto; Pashto", value: "ps" },
  { label: "Portuguese", value: "pt" },
  { label: "Quechua", value: "qu" },
  { label: "Romansh", value: "rm" },
  { label: "Rundi", value: "rn" },
  { label: "Romanian; Moldavian; Moldovan", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Kinyarwanda", value: "rw" },
  { label: "Sanskrit", value: "sa" },
  { label: "Sardinian", value: "sc" },
  { label: "Sindhi", value: "sd" },
  { label: "Northern Sami", value: "se" },
  { label: "Sango", value: "sg" },
  { label: "Sinhala; Sinhalese", value: "si" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Samoan", value: "sm" },
  { label: "Shona", value: "sn" },
  { label: "Somali", value: "so" },
  { label: "Albanian", value: "sq" },
  { label: "Serbian", value: "sr" },
  { label: "Swati", value: "ss" },
  { label: "Sotho, Southern", value: "st" },
  { label: "Sundanese", value: "su" },
  { label: "Swedish", value: "sv" },
  { label: "Swahili", value: "sw" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  { label: "Tajik", value: "tg" },
  { label: "Thai", value: "th" },
  { label: "Tigrinya", value: "ti" },
  { label: "Turkmen", value: "tk" },
  { label: "Tagalog", value: "tl" },
  { label: "Tswana", value: "tn" },
  { label: "Tonga (Tonga Islands)", value: "to" },
  { label: "Turkish", value: "tr" },
  { label: "Tsonga", value: "ts" },
  { label: "Tatar", value: "tt" },
  { label: "Twi", value: "tw" },
  { label: "Tahitian", value: "ty" },
  { label: "Uighur; Uyghur", value: "ug" },
  { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbek", value: "uz" },
  { label: "Venda", value: "ve" },
  { label: "Vietnamese", value: "vi" },
  { label: "Volapük", value: "vo" },
  { label: "Walloon", value: "wa" },
  { label: "Wolof", value: "wo" },
  { label: "Xhosa", value: "xh" },
  { label: "Yiddish", value: "yi" },
  { label: "Yoruba", value: "yo" },
  { label: "Zhuang; Chuang", value: "za" },
  { label: "Chinese", value: "zh" },
  { label: "Zulu", value: "zu" },
];

export const productsFeatureList = [
  { label: "Product Link", value: "pageUrl" },
  { label: "Product Location", value: "productLocation" },
  { label: "Product Media", value: "productMedia" },
];

export const promotionsFeatureList = [
  { label: "Product Link", value: "pageUrl" },
  { label: "Product Id", value: "productId" },
  { label: "Product Category", value: "productCategory" },
];

export const mlModelList = [
  {
    label: "Personalized Offers",
    value: "personalized_offers",
    data: {
      name: "PG_Recommendation",
      tag: "DLRM, DCN v2",
      version: "V3.45",
      createdBy: "Purplegrids Inc.",
      createdDate: "07/23/2022",
      responseType: "LIST - [PRODUCT/JSON]",
    },
  },
  {
    label: "Demand Prediction",
    value: "demand_prediction",
    data: {
      name: "Product_Demand_Predict",
      tag: "DLRM, DCN v2",
      version: "V1.2",
      createdBy: "Google",
      createdDate: "03/04/2022",
      responseType: "REPORT",
    },
  },
  {
    label: "Customer Segmentation",
    value: "customer_segmentation",
    data: {
      name: "PG_Segment_Visitor",
      tag: "BERT-T5",
      version: "V4.13",
      createdBy: "Purplegrids Inc.",
      createdDate: "11/04/2022",
      responseType: "REPORT",
    },
  },
  {
    label: "Review Classification",
    value: "review_classification",
    data: {
      name: "PG_Classifier",
      tag: "adwg",
      version: "V1.1",
      createdBy: "Purplegrids Inc.",
      createdDate: "11/01/2022",
      responseType: "REPORT",
    },
  },
  {
    label: "Cross-sell Prediction",
    value: "cross_sell_prediction",
    data: {
      name: "PG_Cross_Sell_Predict",
      tag: "Classify",
      version: "V1.15",
      createdBy: "Purplegrids Inc.",
      createdDate: "07/20/2022",
      responseType: "LIST - [PRODUCT/JSON]",
    },
  },
  {
    label: "Recommendation",
    value: "recommendation",
    data: {
      name: "PG_Product_Recommend",
      tag: "CIKM-13",
      version: "V12.4",
      createdBy: "Purplegrids Inc.",
      createdDate: "04/04/2023",
      responseType: "LIST - [PRODUCT/JSON]",
    },
  },
];

export const rpaEngineList = [
  { label: "UI Path", value: "ui_path" },
  { label: "Automation Anywhere", value: "automation_anywhere" },
];

export const transformationTypes = [
  { label: "GenAI Transformation", value: "ai" },
  { label: "Classic Transformation", value: "classic" },
  { label: "DSL Transformation", value: "dsl" },
  { label: "XML to JSON", value: "xml2json" },
  { label: "JSON to XML", value: "json2xml" },
  // { label: 'GraphQL to JSON', value: 'graphql2json' }
];

export const dataSourceList = [
  { label: "Amazon S3", value: "amazon_s3" },
  { label: "BigData", value: "big_data" },
  { label: "Google Cloud", value: "google_cloud" },
  { label: "Azure Blob", value: "azure_blob" },
  { label: "Microsoft OneDrive", value: "microsoft_onedrive" },
  { label: "Google Drive", value: "google_drive" },
  { label: "Huggingface", value: "huggingface" },
  { label: "Kaggle", value: "kaggle" },
  { label: "Purple Cloud - Transient", value: "purple_cloud" },
  { label: "Purple Cloud - Persistent", value: "purple_cloud_persistent" },
];

export const llmList = [
  { label: "Public LLM", value: "PUBLIC_LLM" },
  { label: "Private LLM", value: "PRIVATE_LLM" },
];

export const aiModelType = [
  {
    label: "Vision",
    value: "VISION",
    foundationModel: [
      { label: "tfhub/EfficientNetV2", value: "TFHUB_EFFICIENTNETV2" },
      { label: "tfvision/vit", value: "TFVISION_VIT" },
      { label: "tfvision/SpineNet", value: "TFVISION_SPINENET" },
      { label: "tfvision/YOLO", value: "TFVISION_YOLO" },
    ],
  },
  {
    label: "GPT",
    value: "GPT",
    foundationModel: [
      { label: "PaLM 2", value: "PALM_2" },
      { label: "Llama 2", value: "LLAMA_2" },
      { label: "Text Embeddings", value: "TEXT_EMBEDDINGS" },
      { label: "Chirp", value: "CHIRP" },
    ],
  },
  {
    label: "NLP",
    value: "NLP",
    foundationModel: [],
  },
];

export const getTokenLengthList = [
  { label: "1024", value: 1024 },
  { label: "2048", value: 2048 },
  { label: "3072", value: 3072 },
  { label: "4096", value: 4096 },
  { label: "8192", value: 8192 },
];

export const modelFoundationList = [
  {
    value: "VERTEXAI",
    foundationModel: [
      { label: "tfhub/EfficientNetV2", value: "tensorflow-hub/efficientnetv2" },
      { label: "tfvision/vit", value: "tfvision/vit-s16" },
      { label: "tfvision/SpineNet", value: "tfvision/spinenet49" },
      { label: "tfvision/YOLO", value: "tfvision/scaled-yolo" },
    ],
  },
  {
    value: "GOOGLEAI",
    foundationModel: [
      {
        label: "Gemini API",
        options: [
          { label: "Gemini 1.0 Pro", value: "gemini-1.0-pro" },
          { label: "Gemini 1.0 Pro Vision", value: "gemini-1.0-pro-vision-001" },
          { label: "Gemini 1.0 Ultra", value: "gemini-1.0-ultra" },
          { label: "Gemini 1.0 Ultra Vision", value: "gemini-1.0-ultra-vision-001" },
          { label: "Gemini 1.5 Pro", value: "gemini-1.5-pro-001" },
          // { label: "Gemini 1.5 Pro Preview", value: "gemini-1.5-pro-preview-0215" },
          { label: "Gemini 1.5 Flash", value: "gemini-1.5-flash-001" },
        ],
      },
      {
        label: "PaLM API",
        options: [
          { label: "PaLM 2 for Text (text-bison)", value: "text-bison" },
          { label: "PaLM 2 for Text (text-unicorn)", value: "text-unicorn" },
          { label: "PaLM 2 for Text 32k", value: "text-bison-32k" },
          { label: "PaLM 2 for Chat", value: "chat-bison" },
          { label: "PaLM 2 for Chat 32k", value: "chat-bison-32k" },
          { label: "Embeddings for Text", value: "textembedding-gecko" },
          { label: "Embeddings for Text multilingual", value: "textembedding-gecko-multilingual" },
        ],
      },
      {
        label: "Codey API",
        options: [
          { label: "Codey for Code Generation", value: "code-bison" },
          { label: "Codey for Code Generation 32k", value: "code-bison-32k" },
          { label: "Codey for Code Chat", value: "codechat-bison" },
          { label: "Codey for Code Chat 32k", value: "codechat-bison-32k" },
          { label: "Codey for Code Completion", value: "code-gecko" },
        ],
      },
      {
        label: "MedLM API",
        options: [
          { label: "MedLM-medium", value: "medlm-medium" },
          { label: "MedLM-large", value: "medlm-large" },
        ],
      },
      {
        label: "Speech-to-Text API",
        options: [{ label: "Chirp", value: "chirp" }],
      },
    ],
  },
  {
    value: "ALEXA",
    foundationModel: [
      { label: "Amazon Titan", value: "AMAZON_TITAN" },
      { label: "Claude", value: "CLAUDE" },
      { label: "Stable Diffusion", value: "STABLE_DIFFUSION" },
      { label: "Jurassic-2", value: "JURASSIC-2" },
    ],
  },
  {
    value: "OPENAI",
    foundationModel: [
      { label: "GPT-3.5", value: "gpt-3.5-turbo" },
      { label: "GPT 4", value: "gpt-4" },
      { label: "GPT 4 Turbo", value: "gpt-4-turbo" },
      { label: "GPT 4o", value: "gpt-4o" },
      { label: "GPT 4o Mini", value: "gpt-4o-mini" },
      { label: "Davinci", value: "text-davinci-003" },
      { label: "Curie", value: "text-curie-001" },
      { label: "Babbage", value: "text-babbage-001" },
    ],
  },
  {
    value: "LLAMA3",
    foundationModel: [
      { label: "Llama3 8B", value: "llama3-8b" },
      { label: "Llama3 70B", value: "llama3-70b-instruct" },
    ],
  },
  {
    value: "CLAUDEAI",
    foundationModel: [
      { label: "Claude 3 Opus", value: "claude-3-opus-20240229" },
      { label: "Claude 3 Sonnet", value: "claude-3-sonnet-20240229" },
      { label: "Claude 3 Haiku", value: "claude-3-haiku-20240307" },
      { label: "Claude 2.1", value: "claude-2.1" },
      { label: "Claude 2.0", value: "Claude 2.0" },
      { label: "Claude Instant 1.2", value: "claude-instant-1.2" },
    ],
  },
  {
    value: "AZUREAI",
    foundationModel: [
      { label: "GPT-35-Turbo (version: 0301)", value: "gpt-35-turbo-0301" },
      { label: "GPT-35-Turbo (version: 0613)", value: "gpt-35-turbo-0613" },
      { label: "GPT-35-Turbo-16K", value: "gpt-35-turbo-16k" },
      {
        label: "Text Embedding ADA 002 (version: 1)",
        value: "text-embedding-ada-002-1",
      },
      {
        label: "Text Embedding ADA 002 (version: 2)",
        value: "text-embedding-ada-002-2",
      },
    ],
  },
  {
    value: "SOLAI",
    foundationModel: [
      {
        label: getAiList("GOOGLEAI", "Generative_AI").name,
        options: [
          { label: "PaLM 2", value: "text-bison" },
          { label: "Med-PaLM 2", value: "MED-PALM_2" },
          { label: "Llama 2", value: "Llama2-7B-001" },
          { label: "Text Embeddings", value: "textembedding-gecko@001" },
          { label: "Chirp", value: "chirp-rnnt1" },
        ],
      },
      {
        label: getAiList("AZUREAI", "Generative_AI").name,
        options: [
          { label: "GPT-35-Turbo (version: 0301)", value: "gpt-35-turbo-0301" },
          { label: "GPT-35-Turbo (version: 0613)", value: "gpt-35-turbo-0613" },
          { label: "GPT-35-Turbo-16K", value: "gpt-35-turbo-16k" },
          {
            label: "Text Embedding ADA 002 (version: 1)",
            value: "text-embedding-ada-002-1",
          },
          {
            label: "Text Embedding ADA 002 (version: 2)",
            value: "text-embedding-ada-002-2",
          },
        ],
      },
    ],
  },
];

export const aiList = [
  {
    name: "Vision AI",
    key: "Computer_Vision",
    description:
      "AI computer vision is a field of artificial intelligence that uses algorithms to analyze and interpret images in order to detect objects, recognize patterns, and track movements in images.",
    img: "img/aiagents/ComputerVision.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
    playground: {
      services: [
        {
          label: "Scan Image",
          value: "scan_image",
          features: ["LABEL", "LANDMARK"],
          searchParams: {
            label: "About",
            query: "Tell me about: ",
          },
        },
        {
          label: "Emotion Detection & Sentiment Analysis",
          value: "emotion_detection_sentiment_analysis",
          features: ["EMOTION", "SAFE_SEARCH"],
        },
        {
          label: "Object Identifier",
          value: "object_identifier",
          features: ["LABEL"],
        },
        {
          label: "Image Sensitivity",
          value: "image_sensitivity",
          features: ["SAFE_SEARCH", "EMOTION"],
        },
        {
          label: "Create Image Caption",
          value: "create_image_caption",
          features: ["LABEL", "LANDMARK", "SAFE_SEARCH", "EMOTION", "TEXT"],
          searchParams: {
            label: "Image Caption",
            query: "Generate image caption in 100 char for the following information:\n",
          },
        },
        {
          label: "Meme Generator",
          value: "meme_generator",
          features: ["LABEL", "LANDMARK", "SAFE_SEARCH", "EMOTION", "TEXT"],
          searchParams: {
            label: "Meme",
            query: "Generate meme in 100 char for the following information:\n",
          },
        },
        {
          label: "Facial Recognition (Coming Soon!)",
          value: "facial_recognition",
          isdisabled: true,
        },
      ],
    },
  },
  {
    name: "Natural Language",
    key: "Natural_Language",
    description:
      "AI NLP (Artificial Intelligence Natural Language Processing) is a branch of artificial intelligence that deals with analyzing, understanding, and generating human language.",
    img: "img/aiagents/NaturalLanguage.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "AI Platform", key: "ai" },
      { label: "Session Timeout", key: "sessionTimeout" },
      { label: "Status", key: "status" },
      { label: "Username", key: "username" },
      { label: "Password", key: "password" },
      { label: "Workspace Id", key: "workspaceId" },
    ],
  },
  {
    name: "Generative AI",
    key: "Generative_AI",
    description:
      "Generative AI is a type of Artificial Intelligence that focuses on the creation of new data or content, such as images, audio, or text, from scratch.",
    img: "img/aiagents/generativeAI.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "AI Platform", key: "ai" },
      { label: "Status", key: "status" },
      { label: "Workspace Id", key: "workspaceId" },
    ],
    playground: {
      services: [
        {
          label: "Generate Promotions",
          value: "generate_promotions",
          searchQuery: "Generate Promotions: ",
        },
        {
          label: "Generate Travel Blog",
          value: "generate_travel_blog",
          searchQuery: "Generate Travel Blog: ",
        },
        {
          label: "Ad Generator",
          value: "ad_generator",
          searchQuery: "Generate Ad: ",
        },
        {
          label: "Create Campaign",
          value: "create_campaign",
          searchQuery: "Create Campaign: ",
        },
        {
          label: "Meme Generator",
          value: "meme_generator",
          searchQuery: "Generate Meme: ",
        },
      ],
    },
  },
  {
    name: "Predictive Analysis",
    key: "Predictive_Analysis",
    description:
      "AI predictive analysis is a process that uses machine learning algorithms to analyze data and make predictions about future outcomes.",
    img: "img/aiagents/PredictiveAnalysis.svg",
    fields: [{ label: "Attributes", key: "attributes" }],
  },
  {
    name: "Optical Character Recognition",
    key: "Optical_Scan",
    description:
      "AI Optical Scan is a form of artificial intelligence technology that uses optical scanning and pattern recognition to identify objects, people, and text.",
    img: "img/aiagents/OpticalScan.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
    playground: {
      services: [
        {
          label: "Scan Document",
          value: "scan_document",
          sampleImgUrl: "/img/brochure.png",
        },
        {
          label: "Scan Driving License",
          value: "scan_driving_license",
          sampleImgUrl:
            "https://res.cloudinary.com/meiyappan/image/upload/v1686205599/Californian_sample_driver_s_license__c._2019_nm625y.jpg",
        },
        {
          label: "Scan Prescription",
          value: "scan_prescription",
        },
        {
          label: "Scan Emirated Id",
          value: "scan_emirated_id",
          sampleImgUrl:
            "https://res.cloudinary.com/meiyappan/image/upload/v1686205599/arabianbusinessdotcom-2-1024x685_ikhogz.jpg",
        },
        {
          label: "Scan QR Code",
          value: "scan_qr_code",
        },
        {
          label: "Scan Bar Code",
          value: "scan_bar_code",
        },
      ],
    },
  },
  {
    name: "Translation AI",
    key: "Translation",
    description:
      "AI translation is the use of artificial intelligence technology to assist in the translation of text from one language to another.",
    img: "img/aiagents/Translation.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
  },
  {
    name: "Speech to Text",
    key: "Speech_to_Text",
    description:
      "AI Speech to Text is a technology that uses artificial intelligence to convert spoken words into written text. It can be used for dictation, transcription, and other tasks that require converting spoken language into text.",
    img: "img/aiagents/SpeechtoText.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
  },
  {
    name: "Recommendataion",
    key: "Recommendataion",
    description:
      "AI recommendation is the use of artificial intelligence to provide tailored advice or product recommendations to users.",
    img: "img/aiagents/Recommendation.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
  },
  {
    name: "Classification",
    key: "Classification",
    description:
      "AI classification is a type of machine learning technique that uses algorithms to automatically classify data into predefined groups.",
    img: "img/aiagents/Classification.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
  },
  {
    name: "Responsible AI",
    key: "Responsible_AI",
    description:
      "Responsible AI ensures ethical development, deployment, and usage of AI systems, promoting transparency, fairness, privacy, safety, and accountability, while mitigating risks.",
    img: "img/aiagents/ResponsibleAI.svg",
    fields: [
      { label: "Name", key: "aiName" },
      { label: "Attributes", key: "attributes" },
    ],
    playground: {
      services: [
        {
          label: "Check Safety",
          value: "check_safety",
        },
        {
          label: "Check Image Sensitivity",
          value: "check_image_sensitivity",
        },
        {
          label: "Check Document Sensitivity",
          value: "check_document_sensitivity",
        },
      ],
    },
  },
];

export const statusOptions = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

export const documentTypeOption = [
  { value: "Website", label: "Website" },
  { value: "Document", label: "Document" },
  { value: "API", label: "API" },
  { value: "GenAI", label: "GenAI" },
  { value: "Template File", label: "Template File" },
];

export const documentTypeList = [
  { value: "Text", label: "Text" },
  { value: "Image", label: "Image" },
  { value: "Doc", label: "Doc" },
  { value: "PDF", label: "PDF" },
];

export const rateLimitOptions = [
  { label: 2, value: "2" },
  { label: 5, value: "5" },
  { label: 10, value: "10" },
  { label: 15, value: "15" },
  { label: 30, value: "30" },
];

export const scheduleOptions = [
  { label: "Now", value: "Now" },
  { label: "Once", value: "Once" },
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];

export const executionTypeList = [
  { label: "Static", value: "Static" },
  { label: "Dynamic", value: "Dynamic" },
];

export const mlTypeList = [
  { label: "Generative Model", value: "GENERATIVE" },
  { label: "Traditional Model", value: "TRADITIONAL" },
];

export const sourceList = [
  { label: "Static List - Add individual cards", value: "static-card" },
  { label: "Dynamic List", value: "dynamic-entity" },
];

export const genderList = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
];

export const emotionList = [
  { name: "Empathetic", value: "Empathetic" },
  { name: "Jovial", value: "Jovial" },
  { name: "Enthusiastic", value: "Enthusiastic" },
  { name: "Confident", value: "Confident" },
  { name: "Sarcastic", value: "Sarcastic" },
  { name: "None", value: "None" },
];

export const datasetTypeOptions = [
  { value: "API", label: "API" },
  { value: "AWS S3", label: "AWS S3" },
  { value: "Azure Blob", label: "Azure Blob" },
  { value: "One Drive", label: "One Drive" },
  { value: "GCS", label: "GCS" },
  { value: "Google Drive", label: "Google Drive" },
  { value: "Huggingface", label: "Huggingface" },
  { value: "Kaggle", label: "Kaggle" },
  { value: "CSV Files", label: "CSV Files" },
  { value: "Zip Files", label: "Zip Files" },
];
