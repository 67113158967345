import React, { useState, useEffect } from "react";
import { Label, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Button, Select, Input, ScrollBox } from "../../../../../common/components/index.js";
import Text from "./Text.js";
import Dropdown from "./Dropdown.js";
import Paragraph from "./Paragraph.js";
import Textarea from "./Textarea.js";
import Radio from "./Radio.js";
import Checkbox from "./Checkbox.js";
import Datepicker from "./Datepicker.js";
import Image from "./Image.js";
import Submit from "./Submit.js";
import classnames from "classnames";
import { isArray } from "../../../../../utils/GeneralUtils.js";
import { get, cloneDeep } from "lodash";

const FormBuilderProperties = ({
  section,
  title,
  subtitle,
  groups,
  groupIndex,
  elementIndex,
  formElements,
  setList,
  setActionButton,
  onInputChange,
  onGroupChange,
  onGroupChangeValidate,
  onGroupUpdate,
  deleteGroup,
  deleteElement,
}) => {
  const [state, setState] = useState({
    section,
    title,
    subtitle,
    groups,
    groupIndex,
    elementIndex,
  });

  const validationList = [
    {
      label: "Required",
      value: "required",
    },
    {
      label: "Regex",
      value: "regex",
    },
  ];

  const [activeTab, setActiveTab] = useState("general");
  const [selectedValidation, setSelectedValidation] = useState("");
  const [propertyList, setPropertyList] = useState({});

  const elementValidation = get(groups, `[${groupIndex}].elementList[${elementIndex}].properties.validations`, []);
  const elementType = get(groups, `[${groupIndex}].elementList[${elementIndex}].elementType`);
  const selectedElement =
    groupIndex >= 0 && elementIndex >= 0 ? groups[groupIndex]["elementList"][elementIndex] : "FORM";

  useEffect(() => {
    if (groups[groupIndex]) {
      const obj = groups[groupIndex].elementList[elementIndex];
      if (obj) {
        if (Array.isArray(Object.keys(obj.properties))) {
          setPropertyList(obj.properties);
        } else {
          const formEle = formElements.find(x => x.value === obj.elementType) || { properties: {} };
          setPropertyList(formEle.properties);
        }
      }
    }
  }, [groupIndex, elementIndex, section, groups]);

  useEffect(() => {
    setState({
      section,
      title,
      subtitle,
      groups,
      groupIndex,
      elementIndex,
    });
  }, [section, title, subtitle, groups, groupIndex, elementIndex]);

  const renderFormDetails = type => {
    switch (type) {
      case "TITLE":
        return renderDetailsFormTitle();

      case "GROUP":
        return renderDetailsFormGroup();

      case "ELEMENT":
        return (
          <div>
            {renderDetailsFormElement()}
            {elementIndex >= 0 && (
              <div className="mt-3">
                <Button
                  cancelBtn
                  customIcon="fa fa-trash"
                  text="Delete"
                  onClickFunc={() => deleteElement(groupIndex, elementIndex)}
                />
              </div>
            )}
          </div>
        );
      default:
        return renderDetailsFormMessage();
    }
  };

  const renderDetailsFormMessage = () => {
    return (
      <div class="plus fs13 text-center text-gray-600 w-100">
        <div class="mt-2 px-5">Choose an element from the list to design the form.</div>
      </div>
    );
  };

  const renderDetailsFormTitle = () => {
    return (
      <React.Fragment>
        <Label className="req-lbl mb-1 fw-semibold">Title</Label>
        <Input name="title" placeholder="Enter Title" value={title} onChangeFunc={onInputChange} />
        <Label className="mb-1 fw-semibold">Subtitle</Label>
        <Input name="subtitle" placeholder="Enter Subtitle" value={subtitle} onChangeFunc={onInputChange} />
      </React.Fragment>
    );
  };

  const renderDetailsFormGroup = () => {
    const group = groups[groupIndex];
    return (
      <React.Fragment>
        <Label className="mb-1 req-lbl fw-semibold">Group Name</Label>
        <Input
          name="groupName"
          title="Group Name"
          placeholder="Enter Group Name"
          outerClassName="mb2"
          value={group.groupName}
          isReq={true}
          error={group.errors && group.errors.groupName ? group.errors.groupName : null}
          onChangeFunc={(name, value) => onGroupChange(name, value, groupIndex)}
          validationFunc={(n, e) => onGroupChangeValidate(n, e, groupIndex)}
        />
        <div className="position-relative">
          <Label className="mb-1 fw-semibold">Group Title</Label>
          <Input
            name="groupTitle"
            placeholder="Enter Group Title"
            outerClassName="mb0"
            value={group.groupTitle}
            onChangeFunc={(name, value) => {
              onGroupChange(name, value, groupIndex);
            }}
          />
        </div>
        <div className="mt-3">
          <Button cancelBtn customIcon="fa fa-trash" text="Delete" onClickFunc={() => deleteGroup(groupIndex)} />
        </div>
      </React.Fragment>
    );
  };
  const onChangeProperties = (name, value) => {
    setPropertyList(prevState => {
      const newList = { ...prevState, [name]: value };
      setList(newList, "properties", elementIndex, groupIndex);
      return newList;
    });
  };

  const setAction = () => {
    setActionButton(groupIndex, elementIndex);
  };

  const renderDetailsFormElement = () => {
    const elementComponents = {
      text: Text,
      dropdown: Dropdown,
      paragraph: Paragraph,
      textarea: Textarea,
      radio: Radio,
      checkbox: Checkbox,
      datepicker: Datepicker,
      image: Image,
      submit: Submit,
      reset: Submit,
      button: Submit,
    };
    const obj = groups[groupIndex]["elementList"][elementIndex];
    if (!obj) {
      return renderDetailsFormMessage();
    }
    const elementProps = {
      obj,
      propertyList,
      onChangeProperties,
      setAction,
    };
    const Component = elementComponents[obj.elementType];
    if (!Component) {
      return renderDetailsFormMessage();
    }
    return <Component {...elementProps} />;
  };

  const addValidation = () => {
    if (selectedValidation) {
      const updatedGroups = groups.map((group, gIndex) => {
        if (gIndex !== groupIndex) return group;
        const updatedElementList = group.elementList.map((element, eIndex) => {
          if (eIndex !== elementIndex) return element;
          let newValidation = { type: selectedValidation, message: "" };
          switch (selectedValidation) {
            case "regex":
              newValidation.pattern = "";
              break;
          }
          return {
            ...element,
            properties: {
              ...element.properties,
              validations: [...(element.properties.validations || []), newValidation],
            },
          };
        });
        return {
          ...group,
          elementList: updatedElementList,
        };
      });
      onGroupUpdate(updatedGroups);
      setSelectedValidation("");
    }
  };

  const onChangeValidation = (name, value, index) => {
    let updatedGroups = cloneDeep(groups);
    let validations = updatedGroups[groupIndex].elementList[elementIndex].properties.validations;
    validations[index] = { ...validations[index], [name]: value };
    updatedGroups[groupIndex].elementList[elementIndex].properties.validations = validations;
    onGroupUpdate(updatedGroups);
  };

  const deleteValidation = index => {
    let updatedGroups = cloneDeep(groups);
    if (updatedGroups[groupIndex].elementList[elementIndex].properties.validations) {
      updatedGroups[groupIndex].elementList[elementIndex].properties.validations.splice(index, 1);
      onGroupUpdate(updatedGroups);
    }
  };

  const renderValidation = (validation, index) => {
    switch (validation.type) {
      case "required":
        return (
          <div className="border border-gray-100 p-2 mt-3 br4">
            <div className="d-flex justify-content-between">
              <div className="mb-1 font-weight-bold">Required</div>
              <div>
                <i className="fa fa-trash cursor-pointer" onClick={() => deleteValidation(index)}></i>
              </div>
            </div>
            <Label className="mb-1 fw-semibold">Validation Message</Label>
            <Input
              type="text"
              placeholder="Enter Message"
              name="message"
              outerClassName="mb-2"
              value={validation.message}
              onChangeFunc={(name, value) => onChangeValidation(name, value, index)}
            />
          </div>
        );

      case "regex":
        return (
          <div className="border border-gray-100 p-2 mt-3 br4">
            <div className="d-flex justify-content-between">
              <div className="mb-1 font-weight-bold">Regex</div>
              <div>
                <i className="fa fa-trash cursor-pointer" onClick={() => deleteValidation(index)}></i>
              </div>
            </div>
            <Label className="mb-1 fw-semibold">Pattern</Label>
            <Input
              type="text"
              placeholder="Enter Pattern"
              name="pattern"
              outerClassName="mb-2"
              value={validation.pattern}
              onChangeFunc={(name, value) => onChangeValidation(name, value, index)}
            />
            <Label className="mb-1 fw-semibold">Validation Message</Label>
            <Input
              type="text"
              placeholder="Enter Message"
              name="message"
              outerClassName="mb-2"
              value={validation.message}
              onChangeFunc={(name, value) => onChangeValidation(name, value, index)}
            />
          </div>
        );
    }
  };

  return (
    <div className="form-details">
      <div className="form-title">
        {section === "ELEMENT" && selectedElement ? selectedElement.elementType.toUpperCase() : section || "FORM"}
      </div>
      <div className="media-fileupload p-0 border-0">
        <Nav className="nav-tab">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "general" })}
              onClick={() => {
                setActiveTab("general");
              }}
            >
              General
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "advance" })}
              onClick={() => {
                setActiveTab("advance");
              }}
            >
              Advance
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="general" className="px-0">
            <ScrollBox className="px-3" boxHeight={280}>
              {renderFormDetails(section)}
            </ScrollBox>
          </TabPane>

          <TabPane tabId="advance" className="px-0">
            <ScrollBox
              scrollClass="bt-validation"
              key={(elementValidation || []).length}
              className="px-3"
              boxHeight={280}
            >
              {section === "GROUP" && (
                <React.Fragment>
                  <Label className="mb-1 fw-semibold">Group Data</Label>
                  <Input
                    name="groupData"
                    title="Group Data"
                    placeholder="Enter Group Data"
                    outerClassName="mb2"
                    value={groups[groupIndex].groupData}
                    onChangeFunc={(name, value) => onGroupChange(name, value, groupIndex)}
                  />
                </React.Fragment>
              )}
              {section === "ELEMENT" &&
                ["text", "textarea", "dropdown", "radio", "checkbox", "datepicker"].includes(elementType) && (
                  <div className="validation-content">
                    <Label className="mb-1 fw-semibold">Validation</Label>
                    <div className="d-flex">
                      <Select
                        placeholder="Select Validation Type"
                        outerClassName="mb-0 w-100"
                        name="selectedValidation"
                        value={selectedValidation}
                        onChangeFunc={(_, value) => setSelectedValidation(value)}
                        options={validationList}
                      />
                      <Button
                        createBtn
                        text="Add"
                        className="ml-2"
                        style={{ width: 80 }}
                        onClickFunc={() => addValidation()}
                      />
                    </div>
                    {isArray(elementValidation) &&
                      elementValidation.map((val, index) => (
                        <React.Fragment key={index}>{renderValidation(val, index)}</React.Fragment>
                      ))}
                  </div>
                )}
            </ScrollBox>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default FormBuilderProperties;
