"use strict";

import { roleStaticList } from "../utils/GeneralUtils";
import {
  SET_TAB_INDEX,
  SET_MESSAGE_LIST,
  SET_RAW_VISITOR_LIST,
  SET_RAW_VISITOR_REFERENCE_LIST,
  SET_RESPONSE_REVIEW_LIST,
  MODAL_POPUP,
  SET_ACTIVE_VISITOR_LIST,
  SET_VISITOR_LIST,
  SET_ACTIVE_LIVE_VISITOR_LIST,
  SET_MERCHANT_ATTR,
  SET_TASK_TYPE,
  SET_ROLE_LIST,
  SET_MERCHANT_PROFILE,
  SET_AGENT_METRICS,
  SET_LIVE_AGENTS,
  SET_RAW_VISITOR_PAGINATION_LIST,
  SET_RAW_VISITOR_EXPORT_CSV,
  SET_AGENT_TEAM_LIST,
  SET_ROLE_PERMISSION_LIST,
  SET_CHAT_LIST,
  REMOVE_CHAT_LIST,
  SET_CHAT_MESSAGE_LIST,
  SET_BOT_ELEMENTS,
  SET_ACTIVE_AGENT_LIST,
} from "./actionTypes";

const msgListObj = {
  fetching: true,
  list: [],
  pendingList: [],
  pendingStatusList: [],
  repliedList: [],
  deletedList: [],
  junkList: [],
  draftList: [],
};

const listObj = {
  list: [],
  fetching: false,
};

const attrObj = {
  data: null,
  fetching: false,
};

const rolePermissionObj = {
  menu: [],
  routes: [],
  fetching: true,
};

export const setTabIndexPayload = (index, data) => {
  return {
    type: SET_TAB_INDEX,
    payload: {
      tabIndex: index,
      tabData: data,
    },
  };
};

export const setMessageListPayload = (obj = msgListObj) => {
  return {
    type: SET_MESSAGE_LIST,
    payload: {
      messages: { ...obj },
    },
  };
};

export const setResponseReviewListPayload = (obj = listObj) => {
  return {
    type: SET_RESPONSE_REVIEW_LIST,
    payload: {
      responseMessages: { ...obj },
    },
  };
};

export const handleModalPopup = obj => {
  return {
    type: MODAL_POPUP,
    payload: {
      popup: obj,
    },
  };
};

export const setActiveVisitorPayload = (list = [], fetching = false) => {
  return {
    type: SET_ACTIVE_VISITOR_LIST,
    payload: {
      activeVisitors: { list, fetching },
    },
  };
};

export const setActiveLiveVisitorPayload = (list = [], fetching = false) => {
  return {
    type: SET_ACTIVE_LIVE_VISITOR_LIST,
    payload: {
      activeLiveVisitors: { list, fetching },
    },
  };
};

export const setAgentTeamPayload = (obj = attrObj) => {
  return {
    type: SET_AGENT_TEAM_LIST,
    payload: {
      agentTeam: { ...obj },
    },
  };
};

export const setActiveAgentPayload = (obj = attrObj) => {
  return {
    type: SET_ACTIVE_AGENT_LIST,
    payload: {
      activeAgent: { ...obj },
    },
  };
};

export const setRolePermissionPayload = (obj = rolePermissionObj) => {
  return {
    type: SET_ROLE_PERMISSION_LIST,
    payload: {
      rolePermission: {
        ...rolePermissionObj,
        ...obj,
      },
    },
  };
};

export const setRawListPayload = (obj = listObj) => {
  return {
    type: SET_RAW_VISITOR_LIST,
    payload: {
      rawVisitors: { ...obj },
    },
  };
};

export const setRawReferenceListPayload = (obj = listObj) => {
  return {
    type: SET_RAW_VISITOR_REFERENCE_LIST,
    payload: {
      rawVisitorsReference: { ...obj },
    },
  };
};

export const setRawListPaginationPayload = (obj = { ...listObj, totalVisitorPages: 0, totalRecords: 0 }) => {
  return {
    type: SET_RAW_VISITOR_PAGINATION_LIST,
    payload: {
      rawVisitorsPagination: { ...obj },
    },
  };
};

export const setRawListExportCSVPayload = (obj = { ...listObj, totalVisitorPages: 0, totalRecords: 0 }) => {
  return {
    type: SET_RAW_VISITOR_EXPORT_CSV,
    payload: {
      rawVisitorsExportCSV: { ...obj },
    },
  };
};

export const setMerchantAttrPayload = (obj = attrObj) => {
  return {
    type: SET_MERCHANT_ATTR,
    payload: {
      merchantAttr: { ...obj },
    },
  };
};

export const setTaskTypePayload = (obj = attrObj) => {
  return {
    type: SET_TASK_TYPE,
    payload: {
      taskTypeList: { ...obj },
    },
  };
};

export const setBotElementPayload = list => {
  return {
    type: SET_BOT_ELEMENTS,
    payload: {
      botElements: list,
    },
  };
};

export const setRoleListPayload = (list = roleStaticList) => {
  return {
    type: SET_ROLE_LIST,
    payload: {
      roleList: list,
    },
  };
};

export const setMerchantProfilePayload = (obj = attrObj) => {
  return {
    type: SET_MERCHANT_PROFILE,
    payload: {
      merchantProfile: { ...obj },
    },
  };
};

export const setAgentMetricsPayload = (obj = { ...listObj, totalList: [] }) => {
  return {
    type: SET_AGENT_METRICS,
    payload: {
      agentMetrics: { ...obj },
    },
  };
};

export const setLiveAgentsPayload = (obj = listObj) => {
  return {
    type: SET_LIVE_AGENTS,
    payload: {
      liveAgents: { ...obj },
    },
  };
};

export const setChatListPayload = obj => {
  return {
    type: SET_CHAT_LIST,
    payload: {
      chat: obj,
    },
  };
};

export const removeChatListPayload = list => {
  return {
    type: REMOVE_CHAT_LIST,
    payload: {
      sessionList: list,
    },
  };
};

export const setChatMessageList = list => {
  return {
    type: SET_CHAT_MESSAGE_LIST,
    payload: {
      messageList: list,
    },
  };
};
