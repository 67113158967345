import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

const TextArea = props => {
  let {
    value,
    name,
    onChangeFunc,
    className,
    style,
    rows,
    cols,
    placeholder,
    title,
    error,
    appendIcon,
    disabled,
    autoResize,
    forceAutoResize,
  } = props;
  const reqErrorMsg = `Please enter ${title}`;
  const inputRef = useRef();

  const inputProps = {
    rows: rows,
    cols: cols,
    className: className,
    value: value,
  };
  if (style) inputProps.style = style;
  if (placeholder) inputProps.placeholder = placeholder;
  if (name) inputProps.name = name;
  if (disabled) inputProps.disabled = disabled;
  if (autoResize) {
    inputProps.style = { ...(style || {}), resize: "none", overflow: "hidden" };
    inputProps.onInput = () => {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = inputRef.current.scrollHeight + "px";
    };
  }

  useEffect(() => {
    if (!autoResize || !forceAutoResize) return;

    inputRef.current.style.height = "auto";
    inputRef.current.style.height = inputRef.current.scrollHeight + "px";
  }, [autoResize ? forceAutoResize : false]);

  return (
    <React.Fragment>
      <div className="d-flex position-relative" style={{ flexDirection: "column" }}>
        <textarea
          {...inputProps}
          ref={inputRef}
          onChange={e => changeHandler(e, onChangeFunc)}
          onBlur={e => validationHandler(e, props, reqErrorMsg)}
        />
        {appendIcon && React.cloneElement(appendIcon, { inputRef: inputRef })}
      </div>
      {error && <span className="req-msg">{error === true ? reqErrorMsg : error}</span>}
    </React.Fragment>
  );
};
const validationHandler = (e, props, reqErrorMsg) => {
  const { value, name } = e.target;
  const { title, isReq, reqType, minLength } = props;
  let errorMsg = isReq ? null : undefined;
  if (props.onBlurFunc) {
    props.onBlurFunc(name, value);
  }
  if (!props.validationFunc) return;

  if (!value && isReq) errorMsg = reqErrorMsg;
  else if (value && reqType && !getRegExp(reqType).test(value)) errorMsg = `Please enter valid ${title}.`;
  else if (minLength && value.length < minLength) errorMsg = `${title} must be at least ${minLength} characters long.`;
  props.validationFunc(name, errorMsg);
};

const changeHandler = (e, onChangeFunc) => {
  onChangeFunc(e.target.name, e.target.value, e);
};
TextArea.defaultProps = {
  className: "form-control",
  rows: 5,
  cols: 15,
};

TextArea.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChangeFunc: PropTypes.func,
  onClick: PropTypes.func,
  rows: PropTypes.number,
  cols: PropTypes.number,
  className: PropTypes.string,
  outerClassName: PropTypes.string,
  outerStyle: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any,
  error: PropTypes.any,
  validationFunc: PropTypes.func,
  autoResize: PropTypes.bool,
};

export default TextArea;
