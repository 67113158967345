import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import loadable from "@loadable/component";
import { Container } from "reactstrap";

import Header from "./common/Layouts/Header/";
import Sidebar from "./common/Layouts/Sidebar/";
import Aside from "./common/Layouts/Aside/";
import Footer from "./common/Layouts/Footer/";
import { ModalPopup } from "./common/components";

const Loading = () => {
  return (
    <div>
      <div className="load-bar">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className="list-notfound">
        <i className="fa fa-spinner fa-pulse fa-fw"></i> Please wait while we load your data...
      </div>
    </div>
  );
};

const ErrorHandling = loadable(() => import("./utils/ErrorHandling.js"), { fallback: <Loading /> });
const LocationListener = loadable(() => import("./utils/LocationListener.js"), { fallback: <Loading /> });
const Dashboard = loadable(() => import("./pages/Dashboard/Dashboard.js"), { fallback: <Loading /> });
const Product = loadable(() => import("./pages/ManageProduct/ProductDetails.js"), { fallback: <Loading /> });
const Fulfilment = loadable(() => import("./pages/Fulfilment/Fulfilment.js"), { fallback: <Loading /> });
const Studio = loadable(() => import("./pages/Studio/Studio.js"), { fallback: <Loading /> });
const Chat = loadable(() => import("./pages/Chat/Chat.js"), { fallback: <Loading /> });
const Leads = loadable(() => import("./pages/Leads/Leads.js"), { fallback: <Loading /> });
const LeadInfo = loadable(() => import("./pages/Leads/LeadInfoNew/index.js"), { fallback: <Loading /> });
const ContactInfo = loadable(() => import("./pages/Contacts/ContactInfo/index.js"), { fallback: <Loading /> });
const LeadsNew = loadable(() => import("./pages/Leads/LeadsNew.js"), { fallback: <Loading /> });
const RobotAgentDetails = loadable(() => import("./pages/RobotAgents/RobotAgentDetails"), { fallback: <Loading /> });
const Integration = loadable(() => import("./pages/Integration/Integration.js"), { fallback: <Loading /> });
const Settings = loadable(() => import("./pages/Settings/Settings.js"), { fallback: <Loading /> });
const Visitors = loadable(() => import("./pages/Visitors/Visitors.js"), { fallback: <Loading /> });
const ExportVisitorsMenu = loadable(() => import("./pages/Visitors/ExportVisitors.js"), { fallback: <Loading /> });
const AllContacts = loadable(() => import("./pages/Contacts/AllContacts.js"), { fallback: <Loading /> });
const ExportContactMenu = loadable(() => import("./pages/Contacts/ExportContacts.js"), { fallback: <Loading /> });
const ExportBusinessMenu = loadable(() => import("./pages/Business/ExportBusiness.js"), { fallback: <Loading /> });
const ExportFulfilment = loadable(() => import("./pages/Fulfilment/ExportFulfilment.js"), { fallback: <Loading /> });
const ExportStudio = loadable(() => import("./pages/Studio/ExportStudio.js"), { fallback: <Loading /> });
const ExportKnowledge = loadable(() => import("./pages/KnowledgeBase/ExportKnowledge.js"), { fallback: <Loading /> });
const KnowledgeBase = loadable(() => import("./pages/KnowledgeBase/KnowledgeBase.js"), { fallback: <Loading /> });
const ExportMessage = loadable(() => import("./pages/Visitors/ExportMessage.js"), { fallback: <Loading /> });
const Engagements = loadable(() => import("./pages/Engagements/EngagementTab.js"), { fallback: <Loading /> });
const Routing = loadable(() => import("./pages/Engagements/Routing"), { fallback: <Loading /> });
const Scoring = loadable(() => import("./pages/Engagements/Scoring"), { fallback: <Loading /> });
const ExportAnalytics = loadable(() => import("./pages/Analytics/ExportAnalytics"), { fallback: <Loading /> });
const AutomationRules = loadable(() => import("./pages/Automation/Rules"), { fallback: <Loading /> });
const Reports = loadable(() => import("./pages/Reports/Reports.js"), { fallback: <Loading /> });
const Profile = loadable(() => import("./pages/Profile/Profile.js"), { fallback: <Loading /> });
const ScreenShare = loadable(() => import("./pages/ScreenShare/ScreenShare.js"), { fallback: null });
const VideoChat = loadable(() => import("./components/VideoChat.js"), { fallback: null });
const ZoomMeeting = loadable(() => import("./components/ZoomMeeting.js"), { fallback: null });
const ZoomOut = loadable(() => import("./components/ZoomOut.js"), { fallback: null });
const Join = loadable(() => import("./pages/Join/Join.js"), { fallback: null });
const ChatHistory = loadable(() => import("./pages/ChatHistory/ChatHistory.js"), { fallback: null });
const ExportChat = loadable(() => import("./pages/ChatHistory/ExportChat.js"), { fallback: null });
const BotDetails = loadable(() => import("./pages/Studio/BotDetails.js"), { fallback: null });
const BotDetailsV2 = loadable(() => import("./pages/Studio/BotDetailsV2.js"), { fallback: null });
const AddKnowledgeBase = loadable(() => import("./pages/ModelStudio/AddKnowledgeBase/index.js"), { fallback: null });
const DocumentStatus = loadable(() => import("./pages/ModelStudio/DocumentStatus"), { fallback: null });

import AADLogin from "./pages/Login/AADLogin";
import Login from "./pages/Login/Login";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Verify from "./pages/Login/Verify";
import Support from "./pages/Login/Support";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Unsubscribe from "./pages/Unsubscribe/Unsubscribe";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { CLOUDINARY_ASSETS, visitorCheck, hashMobileAccess, getLoginUrl } from "./utils/GeneralUtils.js";
import { toastLiveChat } from "./utils/ToastUtils.js";
import { setTabIndex, handlePopup } from "./actions";
import { sessionAccessToken, sessionRole, sessionUser, sessionUserId } from "./utils/SessionUtils.js";
import FeatureEngineering from "./pages/ModelStudio/MLModel/components/FeatureEngineering.js";
import { withRouter } from "./withRouter.js";

class Full extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      isWatch: false,
    };

    this.toastVisitorPopup = this.toastVisitorPopup.bind(this);
    this.reqCBAssistList = [];
    this.agentVisitorList = [];
    this.visitorMsgList = [];
    this.notificationTune = new Audio(CLOUDINARY_ASSETS.NOTIFICATION_TUNE);
    this.agentNotificationTune = new Audio(CLOUDINARY_ASSETS.AGENT_NOTIFICATION_TUNE);
    this.visitorMsgTune = new Audio(CLOUDINARY_ASSETS.VISITOR_MSG_TUNE);
  }

  toastVisitorPopup(props) {
    if (sessionAccessToken()) {
      const reqCallBack = [];
      const reqCallBackAll = [];
      let receivedMyVisitorMsg = [];

      let isAgentAssigned = false;
      props.activeLiveVisitorsList.map(v => {
        const reqObj = visitorCheck(v);
        if (reqObj.isReqAssist) {
          reqCallBackAll.push({ ...reqObj.reqObj, ...reqObj.vObj });

          const rqCBIndex = this.reqCBAssistList.findIndex(x => x.visitorId === v.referenceID);
          if (rqCBIndex === -1) reqCallBack.push({ ...reqObj.reqObj, ...reqObj.vObj });
        }

        const isSelfAssigned =
          this.props.tabData && this.props.tabData.liveChatSId && this.props.tabData.liveChatSId === v.sessionID
            ? true
            : false;
        if (isSelfAssigned) {
          this.props.setTabIndex();
        }

        if (
          reqObj.isAgentAssigned &&
          this.agentVisitorList.findIndex(a => a === v.sessionID) === -1 &&
          !isSelfAssigned
        ) {
          isAgentAssigned = true;
          this.agentVisitorList.push(v.sessionID);
          if (!reqObj.isReqAssist) {
            reqCallBackAll.push({
              ...reqObj.reqObj,
              ...reqObj.vObj,
              isAgentAssigned,
            });
            const rqCBIndex = this.reqCBAssistList.findIndex(x => x.visitorId === v.referenceID);
            if (rqCBIndex === -1)
              reqCallBack.push({
                ...reqObj.reqObj,
                ...reqObj.vObj,
                isAgentAssigned,
              });
          }
        } else if (v.activeAgent !== sessionUserId()) {
          this.agentVisitorList = this.agentVisitorList.filter(a => a !== v.sessionID);

          const reqAllIndex = reqCallBackAll.findIndex(a => a.sessionID === v.sessionID && a.isAgentAssigned);
          if (reqAllIndex > -1) reqCallBackAll.splice(reqAllIndex, 1);

          const reqIndex = reqCallBack.findIndex(a => a.sessionID === v.sessionID && a.isAgentAssigned);
          if (reqIndex > -1) reqCallBack.splice(reqIndex, 1);
        }

        if (v.activeAgent == sessionUserId() && v.totalMessages - v.viewMessageCount > 0) {
          const vmCBIndex = this.visitorMsgList.findIndex(
            x =>
              x.sessionID === v.sessionID &&
              x.referenceID === v.referenceID &&
              x.totalMessages === v.totalMessages &&
              x.viewMessageCount === v.viewMessageCount
          );

          if (vmCBIndex === -1) {
            this.visitorMsgList = this.visitorMsgList.filter(
              x => !(x.sessionID === v.sessionID && x.referenceID === v.referenceID)
            );
            receivedMyVisitorMsg.push({
              sessionID: v.sessionID,
              referenceID: v.referenceID,
              totalMessages: v.totalMessages,
              viewMessageCount: v.viewMessageCount,
            });
          }
        }
      });

      if (this.props.settings.enableNotification) {
        reqCallBack.map((x, i) => {
          if (i < 2) {
            const _this = this;
            toastLiveChat(x.name, x.userAgentId, x.channel, false, () => {
              _this.props.setTabIndex("livechat", { sessionID: x.sessionID });
              if (_this.props.history.location.pathname !== "/livechat") {
                _this.props.navigate("/livechat");
              }
            });
          }
          if (i === 2) {
            const _this = this;
            toastLiveChat(x.name, x.userAgentId, x.channel, true, () => {
              // _this.props.setTabIndex('livechat', { sessionID: x.sessionID });
              if (_this.props.history.location.pathname !== "/livechat") {
                _this.props.navigate("/livechat");
              }
            });
          }
        });

        const { isIOS, isAndroid } = hashMobileAccess();
        if (reqCallBack.filter(x => !x.isAgentAssigned).length > 0 && !isIOS && !isAndroid) {
          this.notificationTune.play();
        }
        if (isAgentAssigned && !isIOS && !isAndroid) {
          this.agentNotificationTune.play();
        }
        this.reqCBAssistList = reqCallBackAll;

        if (
          receivedMyVisitorMsg.length > 0 &&
          props.merchantAttr &&
          props.merchantAttr.customConfig &&
          props.merchantAttr.customConfig.notifyAgentLiveMessage
        ) {
          setTimeout(() => this.visitorMsgTune.play(), 500);
          this.visitorMsgList = [...this.visitorMsgList, ...receivedMyVisitorMsg];
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (sessionAccessToken() && !isEqual(this.props.activeLiveVisitorsList, nextProps.activeLiveVisitorsList)) {
      this.toastVisitorPopup(nextProps);
    }
  }

  render() {
    const { rolePermission } = this.props;
    const isV1Design = [
      "dashboard",
      "reports",
      "knowledge",
      "leads",
      "leadsnew",
      "leadinfo",
      "livechat",
      "tickets",
      "chatbot-templates",
      "flowbots",
      "robotflow",
      "bot-store",
      "automation/scheduler",
      "robot-agents",
      "business/new",
      "contacts",
      "visitors/teams",
      "visitors/online-visitors",
      "business/catalog",
      "business/promotions",
      "business/bulk-upload",
      "contacts",
      "visitors/teams",
      "visitors/appt",
      "visitors/broadcast-message",
      "fulfilment/Order",
      "fulfilment/order-details",
      "fulfilment/payment",
      "fulfilment/schedule",
      "visitors/tasks",
      "app-store",
      "analytics/visitors-enquiry",
      "visitors/unassigned-messages",
      "visitors/my-messages",
      "visitors/team-messages",
      "visitors/sent-messages",
      "visitors/drafts",
      "visitors/deleted",
      "visitors/labels",
      "analytics/visitors-analytics",
      "model-management",
      "model_knowledge_base",
      "documents",
      "model-studio",
      "insights",
      "/designer/virtual-agents",
      "/automation/bot-details",
    ].some(x => window.location.pathname.includes(x));
    const isPublicV1Design = [
      "clogin",
      "chat",
      "login",
      "aadlogin",
      "register",
      "forgotpassword",
      "verify",
      "support",
      "resetpassword",
    ].some(x => window.location.pathname.includes(x));

    if (!sessionUser()) {
      return (
        <Container className={`${isPublicV1Design ? "v1-design" : ""}`} fluid>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/clogin/:mId" element={<Login />} />
            <Route path="/aadlogin" element={<AADLogin />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/verify" element={<Verify />} />
            <Route path="/support" element={<Support />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route path="/product" element={<Product />} />
            <Route path="/screenshare" element={<ScreenShare />} />
            <Route path="/videochat" element={<VideoChat />} />
            <Route path="/zoomout" element={<ZoomOut />} />
            <Route path="/zoom" element={<ZoomMeeting />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/join" element={<Join />} />
            <Route path="/chat/live" element={<ExportChat />} />
            <Route path="/chat" element={<ChatHistory />} />
            <Route path="*" element={<Navigate to={getLoginUrl()} />} />
          </Routes>
          <ModalPopup />
        </Container>
      );
    } else {
      return (
        <div className={`app ${sessionRole()}`}>
          <LocationListener>
            <Header setSidebarOpen={open => this.setState({ sidebarOpen: open })} />
            <div>
              <div className="app-body">
                <Sidebar sidebarOpen={this.state.sidebarOpen} {...this.props} />
                <main className={`main${isV1Design ? " v1-design" : ""}`}>
                  <Container fluid className={isV1Design ? "" : "mt10"}>
                    <ErrorHandling>
                      <Routes>
                        <Route path="/designer/robot-agents/:id" element={<RobotAgentDetails />} />
                        <Route path="/model-studio/documents/:knowledgeId/status/:docId" element={<DocumentStatus />} />
                        <Route
                          path="/model-studio/ml-model/feature-engineering/:modelId"
                          element={<FeatureEngineering />}
                        />

                        {rolePermission.routes.map((x, i) => (
                          <Route key={i} exact={x.exact} path={x.path} element={<x.component page={x.page} />} />
                        ))}

                        <Route path="/profile" element={<Profile />} />
                        <Route path="/contacts/leadinfo/:referenceId/:conversationId?" element={<LeadInfo />} />
                        <Route path="/contacts/contactinfo/:referenceId/" element={<ContactInfo />} />
                        <Route path="/model-studio/add-knowledge-base" element={<AddKnowledgeBase />} />
                        <Route path="/automation/bot-details/:botId/:merchantId?" element={<BotDetailsV2 />} />

                        {rolePermission.routes.length > 0 && (
                          <Route path="*" element={<Navigate to={rolePermission.routes[0].url} />} />
                        )}
                      </Routes>
                    </ErrorHandling>
                  </Container>
                </main>
                <Aside />
              </div>
            </div>
            <Footer />
          </LocationListener>
          <ModalPopup />
          <ToastContainer
            position="bottom-right"
            autoClose={10000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
          />
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    activeLiveVisitorsList: state.common.activeLiveVisitors.list,
    tabData: state.common.tabData,
    rolePermission: state.common.rolePermission,
    settings: state.common.settings,
    merchantAttr: state.common.merchantAttr.data,
  };
};

const mapDispatchToProps = {
  setTabIndex,
  handlePopup,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Full));
