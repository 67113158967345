import { API_PRODUCTION, JELLO_API_PRODUCTION } from "./Config";

const getEndpoint = (url, customUrl) => {
  if (customUrl) return customUrl;

  const endPoint = url && url.startsWith("webchnl") ? JELLO_API_PRODUCTION : API_PRODUCTION;
  return endPoint + url;
};

const HttpType = {
  get: function (url, headers, customUrl, cancelRequest) {
    const apiUrl = getEndpoint(url, customUrl);

    let init = { method: "GET" };
    if (headers) init.headers = headers;
    if (cancelRequest) init.signal = cancelRequest;

    return fetch(apiUrl, init)
      .then(response => this.handleResponse(response))
      .catch(error => this.handleError(error));
  },
  post: function (url, bodyObj, headers, setAuthHeader, customUrl, customBody, cancelRequest) {
    const apiUrl = getEndpoint(url, customUrl);

    let init = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: customBody ? customBody : JSON.stringify(bodyObj),
    };
    if (cancelRequest) init.signal = cancelRequest;

    if (setAuthHeader) {
      init.headers = {
        "Content-Type": "application/json",
        auth: "",
        sessionId: "awvavtybysdvwxxax223455r5ghv",
        version: 1.0,
        context: "Chimes",
      };
    }
    if (headers) init.headers = headers;

    return fetch(apiUrl, init)
      .then(response => this.handleResponse(response))
      .catch(error => this.handleError(error));
  },
  put: function (url, bodyObj, headers, customUrl) {
    const apiUrl = getEndpoint(url, customUrl);

    let init = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObj),
    };
    if (headers) init.headers = headers;

    return fetch(apiUrl, init)
      .then(response => this.handleResponse(response))
      .catch(error => this.handleError(error));
  },
  delete: function (url, bodyObj, headers) {
    const apiUrl = getEndpoint(url);

    let init = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObj),
    };
    if (headers) init.headers = headers;

    return fetch(apiUrl, init)
      .then(response => this.handleResponse(response))
      .catch(error => this.handleError(error));
  },
  handleResponse: function (response) {
    if (response.status == 401 && response.url.indexOf("access_token") > -1) {
      return response.json().then(unAuthRes => {
        if (unAuthRes.error === "invalid_token") {
          if (document.querySelector("#purplegrid-internal-chat-frame")) {
            document.querySelector("#purplegrid-internal-chat-frame").style.zIndex = 999;
          }
          document.getElementById("unauthorized-box").style.display = "block";
        } else {
          return { error: true, status: response.status, data: unAuthRes };
        }
      });
    }

    return response
      .clone()
      .json()
      .catch(() => response.text())
      .then(res => {
        if (!response.ok) {
          return { error: true, status: response.status, data: res };
        }
        return res;
      })
      .catch(err => {
        if (response.status == 201 || response.status == 204) return { success: true, noContent: true };
        return { error: true, status: response.status };
      });
  },
  handleError: function (error) {
    if (error.message == "Unexpected token S in JSON at position 0") {
      return true;
    }
    console.log("API ERROR ::: ", JSON.stringify(error));
    return {
      error: true,
      message: error,
    };
  },
};

export default HttpType;
