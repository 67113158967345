import _ from "./HttpInterceptor.js";
import $ from "jquery";
import { PG_AUTH, API_ORCHESTRATOR, API_ECLOUDBL, OPEN_AI_AUTH, API_PRODUCTION, AAD_CLIENT_ID } from "./Config.js";
import {
  getOrdersDF,
  getOrderByIdDF,
  getProductsListDF,
  getEngagementsDF,
  getUserAgentsDF,
  getAIAgentsDF,
  getKnowledgeBaseDF,
  getKnwIntentDF,
  getReportDF,
  getLoginSessionsDF,
  getAllUsersDF,
  getConversationsDF,
  getVisitSummaryDF,
  getDepartmentByMerchantIdDF,
  getMerchantBotDF,
  getMerchantPageDF,
  getContactByMerchantIdDF,
  getContactListDF,
  getVisitorsDF,
  getAgentMetricsDF,
  getMerchantDetailsDF,
  getActiveVisitorsDF,
  getRawVisitorsListDF,
  createVisitorDF,
  createRawVisitorDF,
  updateVisitorNotesDF,
  getTaskDF,
  getEscalationDF,
  getEscalationLogDF,
  getExecutionPlanDF,
  getUserAgentByIdDF,
  getBroadcastDF,
  formatCustomConfig,
} from "./APIFormatter.js";
import {
  sessionMerchantId,
  sessionAccessToken,
  sessionUser,
  sessionUserId,
  sessionRefreshToken,
  sessionBusinessType,
  sessionRole,
  sessionFirstName,
  sessionLastName,
  sessionUserTemp,
  sessionEmail,
} from "./SessionUtils.js";
import {
  getRandomNumber,
  replaceToken,
  convertVertexPredictData,
  stripHTML,
  transposeBreakLine,
} from "./GeneralUtils.js";
import moment from "moment";

const CommonUtils = {
  getRolePermissionByRole: function () {
    let url = `curo/rolePermission?roleName=${sessionRole()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      // res.map(x => {
      //   if (x.name === "Model Studio") {
      //     x.submenu.push({
      //       name: "Knowledge Graph",
      //       order: 9.1,
      //       pageId: "model_knowledge_graph#all",
      //       permission: "READ",
      //     });
      //     x.submenu.push({
      //       name: "Extensions",
      //       order: 9.2,
      //       pageId: "model_extensions#all",
      //       permission: "READ",
      //     });
      //   }
      // });
      return res;
    });
  },
  sendGridHeader: function (sendGridAuth) {
    let auth = sendGridAuth;
    if (!sendGridAuth.includes("Bearer")) {
      auth = `Bearer ${sendGridAuth}`;
    }
    return { Authorization: auth };
  },
  getProduct: function () {
    //can not find
    let url = `chimesbot/b_getProduct`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getAppointmentSlots: function (merchantId, merchant, selectedDate) {
    let url = `jellogateway/gcalendar/availableSlots`;
    let bodyObj = {
      merchantId: merchantId,
      merchant: merchant,
      date: selectedDate,
    };
    return _.post(url, bodyObj, null, true).then(res => {
      return res;
    });
  },
  getAddEvent: function (data, queryParam) {
    let url = `jellogateway/gcalendar/addEvent`;
    if (queryParam) url = `jellogateway/gcalendar/addEvent${queryParam}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },

  getCustomers: function () {
    const customer = [
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
      },
    ];
    return customer;
  },

  getMerchantBotStats: function () {
    const botStats = [
      {
        MBot_ID: 345,
        Merchant_ID: 23455,
        Channel: "Facebook",
        Conversation_Cnt: 1299,
        Order_Cnt: 12,
        BOT_CSR: 2.3,
        Payment_Cnt: 3,
        Total_Payment: [
          {
            Currency: "USD",
            Amt: "456.78",
          },
        ],
        Negative_CSR_Cnt: 3,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 346,
        Merchant_ID: 123546,
        Channel: "Twitter",
        Conversation_Cnt: 139,
        Order_Cnt: 222,
        BOT_CSR: 7.4,
        Payment_Cnt: 7,
        Total_Payment: [
          {
            Currency: "INR",
            Amt: "789.78",
          },
        ],
        Negative_CSR_Cnt: 8,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 987,
        Merchant_ID: 99999,
        Channel: "Email",
        Conversation_Cnt: 3232,
        Order_Cnt: 99,
        BOT_CSR: 5.3,
        Payment_Cnt: 6,
        Total_Payment: [
          {
            Currency: "USD",
            Amt: "888.78",
          },
        ],
        Negative_CSR_Cnt: 3,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 444,
        Merchant_ID: 123546,
        Channel: "Twitter",
        Conversation_Cnt: 139,
        Order_Cnt: 222,
        BOT_CSR: 7.4,
        Payment_Cnt: 7,
        Total_Payment: [
          {
            Currency: "INR",
            Amt: "789.78",
          },
        ],
        Negative_CSR_Cnt: 8,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 555,
        Merchant_ID: 123546,
        Channel: "Twitter",
        Conversation_Cnt: 139,
        Order_Cnt: 222,
        BOT_CSR: 7.4,
        Payment_Cnt: 7,
        Total_Payment: [
          {
            Currency: "INR",
            Amt: "789.78",
          },
        ],
        Negative_CSR_Cnt: 8,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 666,
        Merchant_ID: 123546,
        Channel: "Twitter",
        Conversation_Cnt: 139,
        Order_Cnt: 222,
        BOT_CSR: 7.4,
        Payment_Cnt: 7,
        Total_Payment: [
          {
            Currency: "INR",
            Amt: "789.78",
          },
        ],
        Negative_CSR_Cnt: 8,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
      {
        MBot_ID: 777,
        Merchant_ID: 123546,
        Channel: "Twitter",
        Conversation_Cnt: 139,
        Order_Cnt: 222,
        BOT_CSR: 7.4,
        Payment_Cnt: 7,
        Total_Payment: [
          {
            Currency: "INR",
            Amt: "789.78",
          },
        ],
        Negative_CSR_Cnt: 8,
        Last_Conversation_Dt: "03/03/2018 12:00:00",
        Last_Activity_Dt: "03/03/2018 12:00:00",
        Created_Dt: "03/03/2018 12:00:00",
      },
    ];
    return botStats;
  },

  getCustomerStats: function () {
    const CustomerStats = [
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "10,782",
        Customers: "242",
        Orders: "26",
        BotRate: "30%",
      },
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "17,882",
        Customers: "52",
        Orders: "26",
        BotRate: "39%",
      },
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "10,782",
        Customers: "242",
        Orders: "26",
        BotRate: "30%",
      },
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "16,792",
        Customers: "82",
        Orders: "96",
        BotRate: "50%",
      },
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "20,582",
        Customers: "42",
        Orders: "28",
        BotRate: "10%",
      },
      {
        mBotId: 345,
        merchantId: 23455,
        custId: 12,
        channel: ["FACEBOOK", "WEB"],
        type: "NEW",
        custReference: [
          {
            email: "jack@gmail.com",
            userId: "jk77",
          },
          {
            userId: "jksd_77",
            phone: "4084445555",
          },
        ],
        usageCnt: 34,
        custValue: 0,
        upturnRate: 30,
        orderCnt: 4,
        paymentCnt: 2,
        totalPayment: {
          currency: "USD",
          amount: 456.78,
        },
        negativeCSRCnt: 3,
        firstActivityDt: "30-5-2018 09:29:38",
        lastActivityDt: "30-5-2018 09:29:38",
        createdDt: "30-5-2018 09:29:38",
        locale: "en_US",
        timeZone: "America/Los_Angeles",
        lead: false,
        prospect: true,
        Conversations: "10,75",
        Customers: "22",
        Orders: "6",
        BotRate: "60%",
      },
    ];
    return CustomerStats;
  },

  login: function (userName, password) {
    let encodedPass = encodeURIComponent(password);
    let url = `pgOauth/oauth/token?grant_type=password&username=${userName}&password=${encodedPass}`;
    let bodyObj = {};
    let headers = {
      contentType: "application/json",
      Authorization: PG_AUTH,
    };
    return _.post(url, bodyObj, headers).then(res => {
      return res;
    });
  },
  refreshToken: function () {
    let url = `pgOauth/oauth/token?grant_type=refresh_token&refresh_token=${sessionRefreshToken()}`;
    let bodyObj = {};
    let headers = {
      contentType: "application/json",
      Authorization: PG_AUTH,
    };
    return _.post(url, bodyObj, headers).then(res => {
      return res;
    });
  },
  register: function (data) {
    let url = `curo/merchant/createAccount`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getOrders: function ({ startDate, endDate, orderStatus, pageIndex, pageCount, referenceId }) {
    let url = `chimes/getOrderNew?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      merchantId: sessionMerchantId(),
    };
    if (startDate && endDate) {
      bodyObj.startDate = startDate;
      bodyObj.endDate = endDate;
    }
    orderStatus ? (bodyObj.orderStatus = orderStatus) : null;
    bodyObj.pageIndex = pageIndex;
    bodyObj.pageCount = pageCount;
    if (referenceId) {
      bodyObj.customer = {
        channel: {
          userId: referenceId,
        },
      };
    }
    return _.post(url, bodyObj, null, true).then(res => {
      return getOrdersDF(res);
    });
  },
  updateOrder: function (data) {
    let url = `chimes/updateOrder?access_token=${sessionAccessToken()}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  updateOrderStatus: function (data) {
    let url = `chimes/updateAllOrderStatuses?access_token=${sessionAccessToken()}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  createOrder: function (data) {
    let url = `chimes/createOrder?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      ...data,
      merchant: { id: sessionMerchantId() },
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getOrderById: function (orderId) {
    let url = `chimes/order/${sessionMerchantId()}/${orderId}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return getOrderByIdDF(res);
    });
  },
  getDashboardData: function () {
    //not used
    let url = `chimesbot/dashboard/merchant/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getRecentusers: function () {
    //not used
    let url = `chimesbot/recentusers`;
    let bodyObj = {
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getActiveusers: function () {
    //not used
    let url = `chimesbot/activeusers`;
    let bodyObj = {
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getProductsList: function (includeNotAvailable = true, includeInactive = true) {
    let url = `chimes/getProduct?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      merchantId: sessionMerchantId(),
      includeNotAvailable,
      includeInactive,
    };
    return _.post(url, bodyObj, null, true).then(res => {
      return getProductsListDF(res);
    });
  },
  getRecommendedProductsList: function (referenceID) {
    let url = `chimes/v2/recommendedProducts?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&visitorReferenceID=${referenceID}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getPromotionsList: function () {
    let url = `chimes/promotion?merchantId=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getMerchantId: function (userName) {
    let url = `pgOauth/users/user/${userName}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  sendOtpRequest: function () {
    let url = `curo/merchant/user/sendOTP`;
    let bodyObj = {
      firstName: sessionFirstName(),
      userName: sessionUserTemp(),
      email: sessionEmail(),
      merchantId: sessionMerchantId(),
    };

    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  verifyOTP: function (token) {
    let url = `curo/merchant/user/validateOTP`;
    let bodyObj = {
      firstName: sessionFirstName(),
      userName: sessionUserTemp(),
      email: sessionEmail(),
      merchantId: sessionMerchantId(),
      token: token,
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getBookedSlots: function () {
    let url = `jellogateway/gcalendar/bookedSlots/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getChannelsList: function () {
    let url = `curo/channels/getChannels`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getMerchantChannels: function () {
    //not used
    let url = `curo/merchant/${sessionMerchantId()}/channels`;
    return _.get(url).then(res => {
      return res;
    });
  },
  sendAndReceiveMes: function (message) {
    let url = `chimesbot/web/callback`;
    let bodyObj = {
      user: {
        userId: "1740262239352040",
      },
      sessionId: "1740262239352040",
      channel: {
        name: "FB",
      },
      reqType: "Text",
      merchant: { merchantId: sessionMerchantId() },
      payload: {
        type: "text",
        data: message,
      },
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createEngagement: function (data) {
    let url = `chimes/createEngagement?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateEngagement: function (data) {
    let url = `chimes/updateEngagement?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getEngagements: function () {
    let url = `chimes/engagementList?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getEngagementsDF(res);
    });
  },
  getEngagementById: function (engagementId) {
    let url = `chimes/engagementList?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&engagementId=${engagementId}`;
    return _.get(url).then(res => {
      return getEngagementsDF(res);
    });
  },
  deleteEngagement: function (data) {
    let url = `chimes/removeEngagement?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getEngagementsCount: function () {
    let url = `chimes/engagementList/true?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getVisitors: function (params, cancelRequest) {
    let url = `chimes/visitorsList?merchantID=${sessionMerchantId()}`;
    if (params) {
      const { engagementId, tracking_status, user_type, referenceID, activePage, perPageRecords, filterCriteria } =
        params;
      if (engagementId) url = `${url}&engagementID=${engagementId}`;
      if (referenceID) url = `${url}&referenceID=${referenceID}`;
      if (tracking_status) url = `${url}&tracking_status=${tracking_status}`;
      if (user_type) url = `${url}&user_type=${user_type}`;
      if (activePage) url = `${url}&pageIndex=${activePage - 1}`;
      if (perPageRecords) url = `${url}&pageCount=${perPageRecords}`;
      if (filterCriteria) url = `${url}${filterCriteria}`;
    }
    return _.get(url, null, null, cancelRequest).then(res => {
      if (res.success) res.visitors = [];
      return getVisitorsDF(res);
    });
  },
  getAllChannels: function () {
    // static data
    let url = `chimesbot/getAllChannels`;
    return _.get(url).then(res => {
      // return res;
      let response = [];
      response.push({
        description: "Facebook Messenger",
        id: 10,
        name: "Facebook Messenger",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Web",
        id: 11,
        name: "Web",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Twitter",
        id: 12,
        name: "Twitter",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Slack",
        id: 13,
        name: "Slack",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "WhatsApp",
        id: 14,
        name: "WhatsApp",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Skype",
        id: 15,
        name: "Skype",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Email",
        id: 16,
        name: "Email",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Apple Chat",
        id: 17,
        name: "Apple Chat",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Messenger",
      });
      response.push({
        description: "Twillio",
        id: 18,
        name: "Twillio",
        status: "Active",
        subscription: "",
        tagline: "",
        type: "Chat Messenger",
      });
      return response;
    });
  },
  getActiveVisitors: function () {
    let url = `webchnl/visitors?merchantId=${sessionMerchantId()}&randId=${getRandomNumber()}`;
    return _.get(url).then(res => {
      return getActiveVisitorsDF(res);
    });
  },
  // getActiveVisitors: function () {
  //   let url = `webchnl/online`;
  //   let bodyObj = {
  //     type: "visitor",
  //     filter: {
  //       "visitor.merchantID": sessionMerchantId()
  //     }
  //   };
  //   return _.post(url, bodyObj).then(res => {
  //     return getActiveVisitorsDF(res);
  //   });
  // },
  getMerchantDetails: function (merchantId = sessionMerchantId()) {
    // no date
    let url = `chimes/getMerchantAttributes?access_token=${sessionAccessToken()}&merchantId=${merchantId}`;
    return _.get(url).then(res => {
      const merchantDetailsDF = getMerchantDetailsDF(res);
      if (
        merchantDetailsDF.merchant &&
        merchantDetailsDF.merchant.customConfig &&
        merchantDetailsDF.merchant.customConfig.AgentQR &&
        typeof merchantDetailsDF.merchant.customConfig.AgentQR === "object" &&
        !Array.isArray(merchantDetailsDF.merchant.customConfig.AgentQR)
      ) {
        return formatCustomConfig(merchantDetailsDF);
      }
      return merchantDetailsDF;
    });
  },
  getUserAgents: function () {
    let url = `webchnl/userAgent?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getUserAgentsDF(res);
    });
  },
  getUserAgentById: function () {
    let url = `webchnl/userAgent?merchantId=${sessionMerchantId()}&agentId=${sessionUserId()}`;
    return _.get(url).then(res => {
      return getUserAgentByIdDF(res);
    });
  },
  createUserAgents: function (userDetails) {
    let url = `chimes/createUser?access_token=${sessionAccessToken()}`;
    let bodyObj = userDetails;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateUserAgent: function (userDetails) {
    let url = `webchnl/userAgent`;
    let bodyObj = userDetails;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteUserAgent: function () {
    let url = `webchnl/userAgent?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&userId=${sessionUserId()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  clearMessageReadVisitor: function (data) {
    let url = `webchnl/userAgent/clearMessageReadVisitor`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getAIAgents: function (getActive = false) {
    let url = `chimes/aiAgents/${sessionMerchantId()}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      if (res.error) {
        return res;
      }
      if (getActive) {
        let list = res.aiAgents.filter(item => item.status === "Active");
        return { aiAgents: list };
      }
      return getAIAgentsDF(res);
    });
  },
  createAIAgent: function (data) {
    let url = `chimes/createAIAgent?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  removeAIAgent: function (id) {
    let url = `chimes/removeAIAgent/${id}?access_token=${sessionAccessToken()}`;
    let bodyObj = {};
    return _.post(url).then(res => {
      return res;
    });
  },
  getKnowledgeBase: function (merchantId = null) {
    let url = `chimes/getKnowledgeBase?merchantID=${
      merchantId || sessionMerchantId()
    }&access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return getKnowledgeBaseDF(res);
    });
  },
  getKnowledgeBaseCount: function () {
    let url = `chimes/getKnowledgeBase/true?merchantID=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getDocStatus: function (data) {
    let url = `chimes/job/getDocStatus?access_token=${sessionAccessToken()}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  createOrUpdateEntity: function (data) {
    let url = `chimes/createOrUpdateEntity?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getEntityByMerchantId: function (merchantId) {
    const merchantID = merchantId || sessionMerchantId();
    let url = `chimes/getEntityByMerchantId/${merchantID}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getEntityByMerchantIdEnv: function (merchantId, endpoint, token) {
    let url = `${endpoint}chimes/getEntityByMerchantId/${merchantId}?access_token=${token}`;
    return _.get(null, null, url).then(res => {
      return res;
    });
  },
  deleteEntityRecord: function (entityId, recordId) {
    let url = `chimes/removeEntityRecord/${entityId}/${recordId}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },

  deleteEntity: function (entityId) {
    let url = `chimes/deleteEntity/${entityId}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },

  getKnowledgeBaseDocs: function () {
    // not used
    let url = `chimes/listKnowledgeBaseDocs?merchantID=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getIntentByKnwId: function (knowledgeBaseId, documentId, merchant = false) {
    let url = `chimes/listKnwIntent?merchantID=${
      merchant ? 100 : sessionMerchantId()
    }&access_token=${sessionAccessToken()}&knowledgeBaseId=${knowledgeBaseId}&documentId=${documentId}`;
    return _.get(url).then(res => {
      return getKnwIntentDF(res);
    });
  },
  getKnwIntent: function () {
    let url = `chimes/listKnwIntent?merchantID=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return getKnwIntentDF(res);
    });
  },
  createKnowledgeBase: function (data) {
    let url = `chimes/createKnowledgeBase?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteKnowledgeBase: function (knowledgeBaseId) {
    let url = `chimes/deleteKnowledgeBase?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&knowledgeBaseId=${knowledgeBaseId}`;
    return _.put(url).then(res => {
      return res;
    });
  },

  createKnowledgeBaseDocs: function (data) {
    let url = `chimes/createKnowledgeBaseDocs?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  modifyKnowledgeBaseDocs: function (data) {
    let url = `chimes/modifyKnowledgeBaseDocs?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteDocument: function (knowledgeBaseId, documentId) {
    let url = `chimes/deleteDocument/${sessionMerchantId()}/${knowledgeBaseId}/${documentId}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  insertScheduleJob: function (data, frequency, startDate) {
    let url = `quartzScheduler/api/scheduler/${sessionMerchantId()}/insert-job?frequency=${frequency}`;
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createKnwIntent: function (data) {
    let url = `chimes/createKnwIntent?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  modifyKnwIntent: function (data) {
    let url = `chimes/modifyKnwIntent?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateVisitorProfile: function (data) {
    let url = `webchnl/visitor`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateVisitorAgent: function (data) {
    let url = `webchnl/visitor/agentInfo`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateTracking: function (data) {
    let url = `webchnl/track`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  addEditProduct: function (data, isUpdate = false) {
    let type = isUpdate ? "updateProduct" : "addProduct";
    let url = `chimes/${type}?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      merchant: {
        id: sessionMerchantId(),
        products: data,
      },
    };
    return _.post(url, bodyObj, null, true).then(res => {
      return res;
    });
  },
  addUpdatePromotions: function (data, promotionId) {
    let url = `chimes/promotion/update?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    if (promotionId) url = `${url}&promotionId=${promotionId}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  deletePromotion: function (productId) {
    let url = `chimes/promotion/${sessionMerchantId()}/${productId}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  updateMerchantDetails: function (data) {
    let url = `chimes/updateMerchantAttributes?access_token=${sessionAccessToken()}`;
    let bodyObj = Object.assign({}, data, {
      id: sessionMerchantId(),
    });
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getPayments: function (startDate, endDate) {
    let url = `chimes/paymentDetails/${sessionMerchantId()}?access_token=${sessionAccessToken()}&startDate=${startDate}&endDate=${endDate}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getBotStore: function () {
    // not used
    let url = `curo/getBotsFromStore`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getBotsFromStoreByGrpNme: function (access) {
    const url = `curo/getBotsFromStoreByGrpNme?status=Active${access ? `&access=${access}` : ""}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getMerchantBot: function (botId = null, merchantId = null) {
    let url = `chimes/getMerchantBot?access_token=${sessionAccessToken()}&merchantId=${merchantId || sessionMerchantId()}`;
    if (botId) {
      url += `&botId=${botId}`;
    }
    return _.get(url).then(res => {
      return getMerchantBotDF(res);
    });
  },
  getMerchantBotCount: function () {
    let url = `chimes/getMerchantBot/true?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createMerchantBot: function (data) {
    let url = `chimes/createMerchantBot?access_token=${sessionAccessToken()}`;
    let bodyObj = [
      {
        merchantId: sessionMerchantId(),
        bot: data,
      },
    ];
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateRoute: function (data) {
    let url = `chimes/updateRoute?access_token=${sessionAccessToken()}`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  createRoute: function (data) {
    let url = `chimes/createRoute?access_token=${sessionAccessToken()}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getRoutes: function () {
    // date not used in UI
    let url = `chimes/getRoutes?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createKnwIntentByBotId: function (botId) {
    let url = `chimes/clone/bot/${botId}/intents/to/${sessionMerchantId()}?access_token=${sessionAccessToken()}`;
    return _.put(url).then(res => {
      return res;
    });
  },
  cloneStoryDialog: function (botName, toMerchantId) {
    let url = `jelloBuilder/cloneStoryDialog?access_token=${sessionAccessToken()}&fromMerchantId=${toMerchantId}&botName=${botName}&toMerchantId=${sessionMerchantId()}`;
    return _.post(url).then(res => {
      return res;
    });
  },
  deleteMerchantBot: function (id) {
    let url = `chimes/deleteMerchantBot?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&botId=${id}`;
    return _.put(url).then(res => {
      return res;
    });
  },
  createMerchantPage: function (data) {
    let url = `chimes/createMerchantPage?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateMerchantPage: function (data) {
    let url = `chimes/modifyMrchntPages?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteMerchantPages: function (data) {
    let url = `chimes/deleteMerchantPages?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&pageId=${
      data.pageId
    }&pageTemplateId=${data.pageTemplateId}`;
    return _.put(url).then(res => {
      return res;
    });
  },
  getMerchantPage: function () {
    let url = `chimes/getMerchantPage?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getMerchantPageDF(res);
    });
  },
  createWatsonIntentWithResponse: function (data) {
    let url = `chimes/createWatsonIntentWithResponse?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      ...data,
      password: "U73O1m28VoCf",
      username: "210df8f6-8c4d-4880-b655-5e1b9b819de7",
      workspaceId: "ac7ccde8-2b7e-43ac-9d2c-d8a9df443e87",
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  startAiTraining: function (knowledgeBaseId, aiId) {
    let url = `chimes/aiTraining/${sessionMerchantId()}/${knowledgeBaseId}/${aiId}/start?access_token=${sessionAccessToken()}`;
    let bodyObj = {};
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  startSolAiTraining: function (data) {
    let url = `chimes/job/train?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  // getMessages: function () { // Not in use now
  //   let url = `chimes/pendingMessage?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
  //   return _.get(url).then(res => {
  //     return res;
  //   });
  // },
  getInboxMessages: function (visitorObj = null) {
    // redux
    let url = `chimes/messages/v1?merchantId=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    let initVisitorObj = {
      fromDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      toDate: moment().endOf("day").format("YYYY-MM-DD"),
      ...(visitorObj && visitorObj),
    };
    if (initVisitorObj) {
      const { status, assignedTo, replyStatus, fromDate, toDate } = initVisitorObj;
      url = `${url}&fromDate=${fromDate}&toDate=${toDate}`;
      if (status) url = `${url}&status=${status}`;
      if (assignedTo) url = `${url}&assignedTo=${assignedTo}`;
      if (replyStatus) url = `${url}&replyStatus=${replyStatus}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },

  getAssignedMessagesCount: function (visitorObj = null) {
    let url = `chimes/messages/v1/true?merchantId=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    let initVisitorObj = {
      fromDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
      toDate: moment().endOf("day").format("YYYY-MM-DD"),
      ...(visitorObj && visitorObj),
    };
    if (initVisitorObj) {
      const { status, assignedTo, replyStatus, fromDate, toDate } = initVisitorObj;
      url = `${url}&fromDate=${fromDate}&toDate=${toDate}`;
      if (status) url = `${url}&status=${status}`;
      if (assignedTo) url = `${url}&assignedTo=${assignedTo}`;
      if (replyStatus) url = `${url}&replyStatus=${replyStatus}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },
  getBroadcasts: function () {
    // date not used in UI
    let url = `jelloBroadcast/broadcast/messages/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getBroadcastDF(res);
    });
  },
  getExecutionPlan: function (broadcastId) {
    // date not used in UI
    let url = `jelloBroadcast/broadcast/${broadcastId}/executionPlan/fetch`;
    return _.get(url).then(res => {
      return getExecutionPlanDF(res);
    });
  },
  createBroadcast: function (data) {
    let url = `jelloBroadcast/broadcast/message/create`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  sendBroadcast: function () {
    let url = `jelloBroadcast/broadcast/message/publish?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  publishMsg: function () {
    let url = `jelloBroadcast/broadcast/message/publish?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  updateBroadcast: function (data) {
    let url = `jelloBroadcast/broadcast/message/update`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteBroadcast: function (id) {
    let url = `jelloBroadcast/broadcast/message/delete/${id}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  updateVisitorMsgDtl: function (data) {
    let url = `chimes/messages/v1?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateMessages: function (data) {
    let url = `chimes/message?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      ...data,
    };
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateVisitorTrack: function (data) {
    let url = `chimes/updateVisitorTrack`;
    // let url = `chimes/updateVisitorTrack?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      ...data,
    };
    // bodyObj.id= "5d1add28b9f8cd0001d06387";
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getIPAddress() {
    let API_REQ_URL = "https://ipinfo.io";
    if (window.location.href.includes("localhost")) {
      API_REQ_URL = "https://api.ipstack.com/175.100.175.193?access_key=355bdadbb0593ce890e30923d012916c";
    }
    return $.getJSON(API_REQ_URL);
  },
  getReport(data) {
    let url = `report/`;
    let bodyObj = {
      ...data,
    };
    return _.post(url, bodyObj).then(res => {
      return getReportDF(res);
    });
  },
  getReportMetadata(data) {
    // date not use in UI
    let url = `report/metadata`;
    let bodyObj = {
      ...data,
    };
    return _.post(url, bodyObj).then(res => {
      if (data.params.reportName == "pendingMessages") {
        res = {
          format: ["PDF", "XLS", "CSV", "Preview"],
          status: "active",
          version: 1,
          allColumns: {
            ip: "IP Address",
            os: "Operating System",
            csr: "CSR",
            url: "URL",
            city: "City",
            lang: "Language",
            lead: "Lead",
            name: "Name",
            type: "Status",
            email: "Email",
            phone: "Phone",
            score: "Score",
            state: "State",
            device: "Device",
            aiAgent: "AI Agent",
            browser: "Browser",
            channel: "Channel",
            country: "Country",
            product: "Product",
            business: "Company",
            messages: "Messages",
            products: "Products",
            timezone: "Timezone",
            leadOwner: "Lead Owner",
            accessDate: "Last Accessed",
            categories: "Categories",
            engagement: "Engagement",
            firstVisit: "First Visit",
            merchantId: "Merchant Id",
            postalCode: "Postal Code",
            createdDate: "Created Date",
            recentVisit: "Most Recent Visit",
            avgTimeSpent: "Avg. Time Spent",
            visitorRefId: "Visitor Reference Id",
            conversations: "Total Conversations",
            lastPageVisited: "Last Page Visited",
            visitedChannels: "Channels",
            firstPageVisited: "First Page Visited",
            lastModifiedDate: "Modified Date",
            noOfProductBrowsed: "Total Products",
            totalNoOfDaysVisited: "Days Visited",
          },
          reportName: "leads_engagement",
          reportType: "Tabular|Summary|Matrix|Chart",
          createdDate: "20-Aug-2019 06:10:13 PM",
          previledges: ["ALL"],
          reportTitle: "Leads By Engagement Report",
          serviceName: "http://pg.somthing.com/reportcon",
          customReport: "True",
          printPreview: "YES",
          templateFile: "leads_engagement.jrxml",
          columnMappings: [
            {
              type: "string",
              level: -1,
              fieldName: "merchantId",
              jsonQuery: "/merchantId",
              columnName: "merchantId",
            },
            {
              type: "string",
              level: -1,
              fieldName: "visitorRefId",
              jsonQuery: "/visitorRefId",
              columnName: "visitorRefId",
            },
            {
              type: "string",
              level: -1,
              fieldName: "country",
              jsonQuery: "/info/country",
              columnName: "info.country",
            },
            {
              type: "string",
              level: -1,
              fieldName: "browser",
              jsonQuery: "/info/browser",
              columnName: "info.browser",
            },
            {
              type: "string",
              level: -1,
              fieldName: "os",
              jsonQuery: "/info/os",
              columnName: "info.os",
            },
            {
              type: "string",
              level: -1,
              fieldName: "device",
              jsonQuery: "/info/device",
              columnName: "info.device",
            },
            {
              type: "string",
              level: -1,
              fieldName: "ip",
              jsonQuery: "/info/ip",
              columnName: "info.ip",
            },
            {
              type: "string",
              level: -1,
              fieldName: "url",
              jsonQuery: "/info/url",
              columnName: "info.url",
            },
            {
              type: "string",
              level: -1,
              fieldName: "lang",
              jsonQuery: "/info/lang",
              columnName: "info.lang",
            },
            {
              type: "string",
              level: -1,
              fieldName: "timezone",
              jsonQuery: "/info/timezone",
              columnName: "info.timezone",
            },
            {
              type: "string",
              level: -1,
              fieldName: "leadOwner",
              jsonQuery: "/info/leadOwner",
              columnName: "info.leadOwner",
            },
            {
              type: "date",
              level: -1,
              fieldName: "accessDate",
              jsonQuery: "/leadInfo/accessDate",
              columnName: "leadInfo.accessDate",
            },
            {
              type: "string",
              level: -1,
              fieldName: "type",
              jsonQuery: "/leadInfo/type",
              columnName: "leadInfo.type",
            },
            {
              type: "string",
              level: -1,
              fieldName: "engagement",
              jsonQuery: "/leadInfo/engagement",
              columnName: "leadInfo.engagement",
            },
            {
              type: "string",
              level: -1,
              fieldName: "product",
              jsonQuery: "/leadInfo/product",
              columnName: "leadInfo.product",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "score",
              jsonQuery: "/leadInfo/score",
              columnName: "leadInfo.score",
            },
            {
              type: "string",
              level: -1,
              fieldName: "channel",
              jsonQuery: "/conversationInfo/channel",
              columnName: "conversationInfo.channel",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "conversations",
              jsonQuery: "/conversationInfo/conversations",
              columnName: "conversationInfo.conversations",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "messages",
              jsonQuery: "/conversationInfo/messages",
              columnName: "conversationInfo.messages",
            },
            {
              type: "string",
              level: -1,
              fieldName: "aiAgent",
              jsonQuery: "/conversationInfo/aiAgent",
              columnName: "conversationInfo.aiAgent",
            },
            {
              type: "string",
              level: -1,
              fieldName: "csr",
              jsonQuery: "/conversationInfo/csr",
              columnName: "conversationInfo.csr",
            },
            {
              type: "string",
              level: -1,
              fieldName: "lead",
              jsonQuery: "/lead",
              columnName: "contactInfo.name",
              columnIfNull: "visitorRefId",
            },
            {
              type: "string",
              level: -1,
              fieldName: "name",
              jsonQuery: "/contactInfo/name",
              columnName: "contactInfo.name",
            },
            {
              type: "string",
              level: -1,
              fieldName: "email",
              jsonQuery: "/contactInfo/email",
              columnName: "contactInfo.email",
            },
            {
              type: "string",
              level: -1,
              fieldName: "phone",
              jsonQuery: "/contactInfo/phone",
              columnName: "contactInfo.phone",
            },
            {
              type: "string",
              level: -1,
              fieldName: "city",
              jsonQuery: "/contactInfo/city",
              columnName: "contactInfo.city",
            },
            {
              type: "string",
              level: -1,
              fieldName: "state",
              jsonQuery: "/contactInfo/state",
              columnName: "contactInfo.state",
            },
            {
              type: "string",
              level: -1,
              fieldName: "business",
              jsonQuery: "/contactInfo/business",
              columnName: "contactInfo.business",
            },
            {
              type: "string",
              level: -1,
              fieldName: "postalCode",
              jsonQuery: "/contactInfo/postalCode",
              columnName: "contactInfo.postalCode",
            },
            {
              type: "date",
              level: -1,
              fieldName: "recentVisit",
              jsonQuery: "/visitSummary/recentVisit",
              columnName: "visitSummary.recentVisit",
            },
            {
              type: "date",
              level: -1,
              fieldName: "firstVisit",
              jsonQuery: "/visitSummary/firstVisit",
              columnName: "visitSummary.firstVisit",
            },
            {
              type: "string",
              level: -1,
              fieldName: "firstPageVisited",
              jsonQuery: "/visitSummary/firstPageVisited",
              columnName: "visitSummary.firstPageVisited",
            },
            {
              type: "string",
              level: -1,
              fieldName: "lastPageVisited",
              jsonQuery: "/visitSummary/lastPageVisited",
              columnName: "visitSummary.lastPageVisited",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "avgTimeSpent",
              jsonQuery: "/visitSummary/avgTimeSpent",
              columnName: "visitSummary.avgTimeSpent",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "totalNoOfDaysVisited",
              jsonQuery: "/visitSummary/totalNoOfDaysVisited",
              columnName: "visitSummary.totalNoOfDaysVisited",
            },
            {
              type: "string",
              level: -1,
              fieldName: "visitedChannels",
              jsonQuery: "/visitSummary/visitedChannels",
              columnName: "visitSummary.visitedChannels",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "noOfProductBrowsed",
              jsonQuery: "/visitSummary/noOfProductBrowsed",
              columnName: "visitSummary.noOfProductBrowsed",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "products",
              jsonQuery: "/visitSummary/products",
              columnName: "visitSummary.products",
            },
            {
              type: "numeric",
              level: -1,
              fieldName: "categories",
              jsonQuery: "/visitSummary/categories",
              columnName: "visitSummary.categories",
            },
            {
              type: "date",
              level: -1,
              fieldName: "createdDate",
              jsonQuery: "/createdDate",
              columnName: "createdDate",
            },
            {
              type: "date",
              level: -1,
              fieldName: "lastModifiedDate",
              jsonQuery: "/lastModifiedDate",
              columnName: "lastModifiedDate",
            },
          ],
          defaultColumns: [
            "engagement",
            "accessDate",
            "country",
            "browser",
            "os",
            "device",
            "channel",
            "lead",
            "name",
            "business",
            "email",
            "phone",
            "product",
            "conversations",
            "messages",
            "totalNoOfDaysVisited",
            "type",
          ],
          groupByColumns: [
            {
              type: "string",
              level: -1,
              fieldName: "engagement",
              jsonQuery: "/leadInfo/engagement",
              columnName: "leadInfo.engagement",
            },
          ],
          reportCategory: "leadReport",
          customMerchantID: [12345, 56789, 64578],
        };
      }
      return res;
    });
  },
  getReportDataSink(data) {
    const url = "datasink/execute/aggregate";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getScheduleReport(reportIds, type) {
    let url = `datasink/reports/fetch?merchantId=${sessionMerchantId()}`;
    if (reportIds) {
      url += `&reportId=${reportIds}`;
    }
    if (type) {
      url += `&reportType=${type}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },
  getScheduleReportFilters(reportId) {
    let url = `datasink/report/filters/fetch?reportId=${reportId}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  scheduleReport(data) {
    const url = `datasink/execute/query`;
    let bodyObj = {
      ...data,
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  schedulerReport(data) {
    const url = `datasink/scheduler`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getSchedulerReport(data) {
    const url = `datasink/scheduler/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getFrequencyType(data) {
    const url = `datasink/scheduler/frequency-types`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getLoginSessions: function () {
    let url = `chimes/loginSessions?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}`;
    const role = sessionRole();
    if (role && role.toLowerCase() !== "admin") {
      url = url + `&email=${sessionUser()}`;
    }
    return _.get(url).then(res => {
      return getLoginSessionsDF(res);
    });
  },
  getAllUsers: function () {
    let url = `curo/userDetails?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getAllUsersDF(res);
    });
  },
  inviteUser: function (email, role, authType) {
    let url = `curo/merchant/user/register/${sessionMerchantId()}/${email}/${role}/${authType}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createUserAccount: function (secureToken, data) {
    let url = `curo/merchant/createUserAccount?secureToken=${secureToken}&action=register`;
    let bodyObj = {
      ...data,
    };
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  forgotPassword: function (mEmail) {
    let url = `curo/merchant/manageAccount/r/${mEmail}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  forgotPasswordPost: function (mEmail) {
    let url = `curo/merchant/manageAccount/r`;
    let bodyObj = {
      email: mEmail,
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  changePassword: function (data) {
    let url = `curo/merchant/manageAccount`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  resetPassword: function (data) {
    let url = `curo/merchant/manageAccount/u/${data.userEmail}?secureToken=${data.secureToken}`;
    let bodyObj = { password: data.password };
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getConversations: function (id) {
    let url = `webchnl/conversation/${id}`;
    return _.get(url).then(res => {
      return getConversationsDF(res);
    });
  },
  getConversationsList: function (id) {
    let url = `webchnl/conversation?sid=${id}`;
    return _.get(url).then(res => {
      return getConversationsDF(res);
    });
  },
  sendOfflineMessage: function (data) {
    let url = `chimes/message?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  resurrectDialogContext: function (data) {
    let url = `webchnl/dialogContext/resurrect`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getRawVisitorsList: function (rawVisitorsObj = null) {
    // redux
    let url = `chimes/rawVisitorsList?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}`;
    if (rawVisitorsObj) {
      const { referenceID, startDate, endDate, activePage, perPageRecords, filterCriteria } = rawVisitorsObj;
      if (referenceID) url = `${url}&referenceID=${referenceID}`;
      if (startDate) url = `${url}&startDate=${startDate}`;
      if (endDate) url = `${url}&endDate=${endDate}`;
      if (activePage) url = `${url}&pageIndex=${activePage - 1}`;
      if (perPageRecords) url = `${url}&pageCount=${perPageRecords}`;
      if (filterCriteria) url = `${url}${filterCriteria}`;
    }
    return _.get(url).then(res => {
      return getRawVisitorsListDF(res);
    });
  },
  getRawVisitorBySessionId: function (sessionId) {
    // redux
    let url = `chimes/rawVisitor?access_token=${sessionAccessToken()}&sessionId=${sessionId}`;
    return _.get(url).then(res => {
      return getRawVisitorsListDF(res);
    });
  },
  // createSendGridList: function (listName, sendGridAuth) {
  //   let url = `https://api.sendgrid.com/v3/contactdb/lists`;
  //   let headers = { Authorization: sendGridAuth };
  //   return _.post(null, null, { name: listName }, headers, null, url).then(res => {
  //     return res;
  //   });
  // },
  // createSendGridRecipients: function (list, sendGridAuth) {
  //   let url = `https://api.sendgrid.com/v3/contactdb/recipients`;
  //   let headers = { Authorization: sendGridAuth };
  //   return _.post(null, null, list, headers, null, url).then(res => {
  //     return res;
  //   });
  // },
  // addSendGridRecipientsToList: function (listId, list, sendGridAuth) {
  //   let url = `https://api.sendgrid.com/v3/contactdb/lists/${listId}/recipients`;
  //   let headers = { Authorization: sendGridAuth };
  //   return _.post(null, null, list, headers, null, url).then(res => {
  //     return res;
  //   });
  // },
  createSendGridCampaign: function (data, sendGridAuth) {
    let url = `https://api.sendgrid.com/v3/campaigns`;
    const headers = this.sendGridHeader(sendGridAuth);
    return _.post(null, data, headers, null, url).then(res => {
      return res;
    });
  },
  sendEmailSendGridCampaign: function (campaignId, timestamp, sendGridAuth) {
    let url = `https://api.sendgrid.com/v3/campaigns/${campaignId}/schedules/now`;
    let bodyObj = null;
    if (timestamp) {
      bodyObj = {
        send_at: timestamp,
      };
    }
    const headers = this.sendGridHeader(sendGridAuth);
    return _.post(null, bodyObj, headers, null, url).then(res => {
      return res;
    });
  },
  sendTestEmailSendGridCampaign: function (campaignId, toAddress, sendGridAuth) {
    let url = `https://api.sendgrid.com/v3/campaigns/${campaignId}/schedules/test`;
    let bodyObj = null;
    bodyObj = {
      to: toAddress,
    };
    const headers = this.sendGridHeader(sendGridAuth);
    return _.post(null, bodyObj, headers, null, url).then(res => {
      return res;
    });
  },

  getVisitorProduct: function (id) {
    // getting response "VISITOR_RESPONSE_CODE_0001"
    let url = `chimes/getVisitorProduct?access_token=${sessionAccessToken()}`;
    let bodyObj = {
      visitor: {
        merchantId: sessionMerchantId(),
        visitor_ref: `${id}`,
      },
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createTask: function (data) {
    let url = `chimes/createTask?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateTask: function (data) {
    let url = `chimes/updateTask?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateTaskAttachment: function (data, taskId) {
    let url = `chimes/task/${taskId}/attachment`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteTask: function (taskId) {
    let url = `chimes/removeTask/${taskId}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  createContact: function (data) {
    let url = `chimes/createContact`;
    // let url = `chimes/createContact?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteContact: function (contactId) {
    let url = `chimes/delete/contact/${contactId}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  updateContact: function (data) {
    let url = `chimes/updateContact?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getContactByMerchantId: function () {
    let url = `chimes/contactByMerchant/${sessionMerchantId()}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      if (res.error) res = [];
      return getContactByMerchantIdDF(res);
    });
  },
  getContactByReferenceId: function (referenceId) {
    let url = `chimes/contactByCustomer/${referenceId}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createContactList: function (data) {
    let url = `chimes/create/contact/list?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getContactList: function (getActive = false) {
    // date not used in UI
    let url = `chimes/${sessionMerchantId()}/contact/list?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      if (getActive) {
        let list = res.length > 0 && res.filter(item => item.status === "active");
        return list;
      }
      return getContactListDF(res);
    });
  },
  deleteContactList: function (id) {
    let url = `chimes/delete/contact/list/${id}?access_token=${sessionAccessToken()}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  updateContactList: function (data) {
    let url = `chimes/udpate/contact/list?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  uploadContacts: function (file) {
    // no date
    let url = `${sessionMerchantId()}/upload/contacts/${file}`;
    return _.post(url).then(res => {
      return res;
    });
  },
  socialMediaPost: function (data) {
    let url = `${API_ECLOUDBL}/socialMedia/post`;
    return _.post(null, data, null, null, url).then(res => {
      return res;
    });
  },
  getSocialMediaAnalytics: function (data) {
    let url = `${API_ECLOUDBL}/socialMedia/analytics`;
    return _.post(null, data, null, null, url).then(res => {
      return res;
    });
  },
  getSocialMediaComments: function (data) {
    let url = `${API_ECLOUDBL}/socialMedia/getComments`;
    return _.post(null, data, null, null, url).then(res => {
      return res;
    });
  },
  autoAssignTicket: function (agentId) {
    const data = {
      merchantId: sessionMerchantId(),
      agentId: agentId || sessionUserId(),
    };
    let url = `${API_ECLOUDBL}/ticket/auto-assign`;
    return _.post(null, data, null, null, url).then(res => {
      return res;
    });
  },
  uploadGCS: function (data, directory) {
    const headers = {};
    let url = `${API_ECLOUDBL}/gcs/upload?merchantId=${sessionMerchantId()}&directory=${directory}`;
    return _.post(null, null, headers, null, url, data).then(res => {
      return res;
    });
  },
  deleteGCS: function (data) {
    let url = `${API_ECLOUDBL}/gcs/delete`;
    return _.post(null, data, null, null, url).then(res => {
      return res;
    });
  },
  getChurnScore: function (data) {
    let url = `${API_ECLOUDBL}/churn`;
    let bodyObj = {
      ...data,
      merchantId: sessionMerchantId(),
    };
    return _.post(null, bodyObj, null, null, url).then(res => {
      return res;
    });
  },
  getChannelStatics: function (data) {
    // no date
    let url = `analytics/channel/getstats`;
    let bodyObj = {
      ...data,
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getAgentMetrics: function (startDate, endDate) {
    let url = `chimes/aiAgents/metrics/${sessionMerchantId()}?access_token=${sessionAccessToken()}${
      startDate && endDate ? `&fromDate=${startDate}&toDate=${endDate}` : ""
    }`;
    return _.get(url).then(res => {
      return getAgentMetricsDF(res);
    });
  },
  getVisitSummary: function (data) {
    let url = `analytics/customer/getvisitsummary`;
    let bodyObj = {
      ...data,
      merchantId: sessionMerchantId(), //135699100284
    };
    return _.post(url, bodyObj).then(res => {
      return getVisitSummaryDF(res);
    });
  },
  createScoreRecord: function (data) {
    let url = `chimes/createScore?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getScoreList: function () {
    // date not used in UI
    let url = `chimes/scoreList?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  runScore: function () {
    let url = `chimes/visitorsScore/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  merchantDepartment: function (data) {
    let url = `chimes/merchantDepartment?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getMerchantDepartment: function () {
    let url = `chimes/merchantDepartment/${sessionMerchantId()}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getDepartmentByMerchantId: function () {
    let url = `chimes/departmentByMerchant/${sessionMerchantId()}?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return getDepartmentByMerchantIdDF(res);
    });
  },
  updateDepartment: function (data) {
    let url = `chimes/updateDepartment?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createVisitor: function (data) {
    let url = `chimes/createVisitor?access_token=${sessionAccessToken()}`;
    let bodyObj = createVisitorDF(data);
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  createRawVisitor: function (data) {
    let url = `chimes/createRawVisitor?access_token=${sessionAccessToken()}`;
    let bodyObj = createRawVisitorDF(data);
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateVbP: function (data, merchantId, referenceID) {
    let url = `chimes/updateVbP/${merchantId}/${referenceID}?access_token=${sessionAccessToken()}`;
    let bodyObj = createVisitorDF(data);
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateField: function (referenceID, fieldName, fieldValue) {
    let url = `chimes/updateVisitorField?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&referenceID=${referenceID}&fieldName=${fieldName}&filedValue=${fieldValue}`;
    return _.post(url).then(res => {
      return res;
    });
  },
  emailEngagementStats: function (engagementId) {
    let url = `report/email/engagement/stats/daily/${sessionMerchantId()}/${engagementId}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getAnalyticsByEngagement: function (engagementId) {
    let url = `analytics/getEngagement/${engagementId}`;
    let payload = {
      periodType: "month",
      periodValue: 201910,
      statsType: "visitorStatsByEngagement",
      merchantId: "135699100289",
    };
    return _.post(url, payload).then(res => {
      return res;
    });
  },

  createRolePermissions: function (data) {
    let url = `curo/role/permission/create`;
    return _.post(url, data).then(res => {
      return res;
    });
  },

  updateRolePermissions: function (data) {
    let url = `curo/role/permission/update`;
    return _.put(url, data).then(res => {
      return res;
    });
  },

  getRolePermissions: function () {
    let url = `curo/user/permission?userId=${sessionUserId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },

  getChatMessage: function (visitorObj = null) {
    let url = `chimes/chatmessage?merchantId=${sessionMerchantId()}&access_token=${sessionAccessToken()}`;
    if (visitorObj) {
      const { fromDate, toDate } = visitorObj;
      if (fromDate) url = `${url}&fromDate=${fromDate}`;
      if (toDate) url = `${url}&toDate=${toDate}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },
  updateChatMessage: function (data) {
    let url = `chimes/chatmessage?access_token=${sessionAccessToken()}`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  updateUserAccount: function (data) {
    let url = `curo/updateUserAccount`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  getAllRole: function () {
    let url = `curo/role/all`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getRoleByMerchantId: function () {
    let url = `curo/role/merchant/${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createRole: function (roleName) {
    let url = `curo/role/create/${roleName}`;
    return _.post(url).then(res => {
      return res;
    });
  },

  deleteRole: function (roleName) {
    let url = `curo/role/delete/${roleName}`;
    return _.delete(url).then(res => {
      return res;
    });
  },

  updateMultiTags: function (params, data, referenceID) {
    let url = `chimes/updateTags?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}${
      referenceID ? `&referenceID=${referenceID}` : ""
    }`;
    const { filterCriteria } = params;
    if (filterCriteria) {
      url = `${url}${filterCriteria}`;
    }
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },

  getAppointments: function (params) {
    let url = `chimes/Appointments/v2?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    const { filterCriteria } = params;
    if (filterCriteria) {
      url = `${url}${filterCriteria}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },
  getAppointmentsCount: function (apptDate, endDate) {
    let url = `chimes/Appointments/v2/true?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&apptDate=${apptDate}&endDate=${endDate}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createAppointment: function (data) {
    let url = `chimes/createAppointment?linkedVisitor=false&&access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateAppointment: function (data) {
    let merhcantId = sessionMerchantId();
    let appointmentId = data.appointmentId;
    let url = `chimes/appointment/${merhcantId}/${appointmentId}?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateVisitorNotes: function (data) {
    // let url = `chimes/updateVisitorNotes?access_token=${sessionAccessToken()}`;
    let url = `webchnl/visitor/notes`;
    return _.put(url, data).then(res => {
      return updateVisitorNotesDF(res);
    });
  },
  updateVisitorSummary: function (data) {
    let url = `webchnl/visitorSummary`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  updateVisitorContext: function (data) {
    let url = `webchnl/visitor/context?access_token=${sessionAccessToken()}`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  updateVisitorCSR: function (data) {
    let url = `webchnl/visitor/updateCSR`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  updateVisitorAttributes: function (referenceID, data) {
    let url = `chimes/visitor/visitorAttributes?referenceID=${referenceID}&access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}`;
    return _.put(url, data).then(res => {
      return res;
    });
  },

  createMeeting: function (sessionId, appointmentId, merchantId = sessionMerchantId()) {
    let url = `jellogateway/meeting`;
    let bodyObj = {
      merchantId,
      visitor: [{ sessionId }],
    };
    if (appointmentId) {
      bodyObj.visitor[0].appointmentId = appointmentId;
    }
    if (sessionUserId()) {
      bodyObj.agent = {
        firstName: sessionFirstName(),
        lastName: sessionLastName(),
        userName: sessionUserId(),
      };
    }
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createStoryNode: function (data) {
    let url = `jelloBuilder/createStoryNode?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createStoryNodeEnv: function (data, endpoint, token) {
    let url = `${endpoint}jelloBuilder/createStoryNode?access_token=${token}`;
    let bodyObj = data;
    return _.post(null, bodyObj, null, null, url).then(res => {
      return res;
    });
  },
  createStoryDialog: function (data) {
    let url = `jelloBuilder/createStoryDialog?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createStoryDialogEnv: function (data, endpoint, token) {
    let url = `${endpoint}jelloBuilder/createStoryDialog?access_token=${token}`;
    let bodyObj = data;
    return _.post(null, bodyObj, null, null, url).then(res => {
      return res;
    });
  },
  updateStoryDialog: function (data) {
    let url = `jelloBuilder/updateStoryDialog?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getStoryDialog: function (botId, merchantId = sessionMerchantId()) {
    let url = `jelloBuilder/storyDialog?access_token=${sessionAccessToken()}&merchantId=${merchantId}&botId=${botId}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  publishStoryDialog: function (data) {
    let url = `jelloBuilder/storyDialog/publish?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  publishStoryDialogEnv: function (data, endpoint, token) {
    let url = `${endpoint}jelloBuilder/storyDialog/publish?access_token=${token}`;
    let bodyObj = data;
    return _.put(null, bodyObj, null, url).then(res => {
      return res;
    });
  },
  updateStoryNode: function (data) {
    let url = `jelloBuilder/updateStoryNode?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getStoryNode: function (merchantId = sessionMerchantId()) {
    let url = `jelloBuilder/storyNode?access_token=${sessionAccessToken()}&merchantId=${merchantId}&status=Active`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createBot: function (data) {
    let url = `jelloBuilder/storyDialog/addBotTemplate?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  createBotEnv: function (data, endpoint, token) {
    let url = `${endpoint}jelloBuilder/storyDialog/addBotTemplate?access_token=${token}`;
    let bodyObj = data;
    return _.put(null, bodyObj, null, url).then(res => {
      return res;
    });
  },
  updateBot: function (data) {
    let url = `jelloBuilder/storyDialog/update/botInfo?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  hubContactList: function () {
    let url = `chimes/hubContactsList?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  importHubContacts: function (data) {
    let url = `chimes/importHubContacts?access_token=${sessionAccessToken()}`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getDynamicParams: function (botName) {
    let url = `jelloBuilder/story/dynamicParams?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&botName=${botName}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getEntityById: function (entityId) {
    let url = `chimes/getEntityById/${entityId}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getAnalyticsTopIntent: function (data) {
    let url = `analytics/merchant/gettopintent`;
    let bodyObj = {
      ...data,
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getTopIntentLabels: function (engagementId) {
    let url = `jelloBuilder/intentLabels/${sessionMerchantId()}${
      engagementId ? "/" + engagementId : ""
    }?access_token=${sessionAccessToken()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getOrchestratorApi: function (data, isSoap) {
    let url = `${API_ORCHESTRATOR}/api/${isSoap ? "soapapi" : "restapi"}`;
    const reqObj = {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: JSON.stringify(replaceToken(data)),
    };
    return fetch(url, reqObj)
      .then(res => res.json())
      .then(response => {
        return response;
      })
      .catch(error => {
        return { success: false, error, status: "Error!", statusText: "" };
      });
  },
  transformJSON: function (data, cancelRequest = "") {
    let url = `orchestrator/api/transform/json`;
    return _.post(url, data, null, null, null, null, cancelRequest).then(res => {
      return res;
    });
  },
  validateJs: function (data, cancelRequest = "") {
    let url = `orchestrator/api/validate/js`;
    return _.post(url, data, null, null, null, null, cancelRequest).then(res => {
      return res;
    });
  },
  getSendGridSenders: function (bearerToken) {
    let url = "https://api.sendgrid.com/v3/senders";
    const headers = this.sendGridHeader(bearerToken);
    return _.get(null, headers, url).then(res => {
      return res;
    });
  },
  getTaskList: function (filterObj) {
    let url = `chimes/getTasks?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    if (filterObj) {
      Object.keys(filterObj).map(key => {
        url += `&${key}=${filterObj[key]}`;
      });
    }
    return _.get(url).then(res => {
      return getTaskDF(res);
    });
  },
  getPendingTaskCount: function (type = "task") {
    let url = `chimes/getTasks/true?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}&mode=${type}&taskStatus=Open,Pending`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getPaginatedTask: function (filterObj, cancelRequest) {
    let url = `chimes/ui/getTasks?access_token=${sessionAccessToken()}&merchantId=${sessionMerchantId()}`;
    if (filterObj) {
      Object.keys(filterObj).map(key => {
        url += `&${key}=${filterObj[key]}`;
      });
    }
    return _.get(url, null, null, cancelRequest).then(res => {
      return getTaskDF(res);
    });
  },
  sendEmailAPI: function (data) {
    const url = "jellogateway/actions";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  sendNotification: function (data) {
    const url = "jellogateway/notification/";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  deleteVisitor: function (data) {
    let url = "webchnl/visitor";
    return _.delete(url, data).then(res => {
      return res;
    });
  },
  sendEmailMessage: function (data) {
    const url = "clbk/emailchnl/message/outbound";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  createUpdateEscalation: function (data) {
    let url = `escalation/create`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  getEscalation: function () {
    let url = `escalation/fetch?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return getEscalationDF(res);
    });
  },
  deleteEscalation: function (id) {
    let url = `escalation/delete/${id}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  getEscalationLog: function (type, sourceId) {
    let url = `escalation/log/query?merchantId=${sessionMerchantId()}`;
    if (type) url += `&type=${type}`;
    if (sourceId) url += `&id=${sourceId}`;

    return _.get(url).then(res => {
      return getEscalationLogDF(res);
    });
  },
  replyToIGComment: function (data, engId, commentId) {
    let url = `clbk/aW5zdGFncmFt/post/reply?merchantId=${sessionMerchantId()}&engId=${engId}&userId=${commentId}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  replyToPlayStoreComment: function (data, engId, reviewId) {
    let url = `clbk/playstore/message/reply?merchantId=${sessionMerchantId()}&engId=${engId}&userId=${reviewId}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  replyToGBusinessReview: function (data, engId, reviewId) {
    let url = `clbk/gBusiness/message/reply?merchantId=${sessionMerchantId()}&engId=${engId}&userId=${reviewId}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  replyToFacebookComment: function (data, engId, commentId) {
    let url = `clbk/ZmFjZWJvb2tw/post/reply?merchantId=${sessionMerchantId()}&engId=${engId}&userId=${commentId}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  updateVisitorRealAgent: function (data) {
    const url = "webchnl/visitor/realAgent";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  invokeNextIntent: function (data) {
    const url = "clbk/jelloapichannel";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  translateText: function (data) {
    const url = "languageservice/translate";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getSupportedLanguages: function () {
    const url = "languageservice/supportedLanguages";
    return _.get(url).then(res => {
      return res;
    });
  },
  getVisitorInsights: function (tripPlannerId, cancelRequest) {
    const url = `ecloudbl/trip/${tripPlannerId}/insights`;
    return _.get(url, null, null, cancelRequest).then(res => {
      return res;
    });
  },
  // extractAiInfo: function (message, cancelRequest) {
  //   const url = 'generativeaiservice/extract/ai/info';
  //   const data = {
  //     message,
  //     merchantConfiguration: {
  //       aiName: "OpenAI",
  //       authKey: OPEN_AI_AUTH,
  //       model: "text-davinci-003"
  //     },
  //     merchantId: sessionMerchantId()
  //   }
  //   return _.post(url, data, null, null, null, null, cancelRequest).then(res => {
  //     return res;
  //   });
  // },
  // generateAiContent: function (message, jsonTemplate, cancelRequest) {
  //   const url = 'generativeaiservice/generateContent';
  //   const data = {
  //     message,
  //     jsonTemplate,
  //     merchantConfiguration: {
  //       aiName: "OpenAI",
  //       authKey: OPEN_AI_AUTH,
  //       model: "text-davinci-003"
  //     },
  //     merchantId: sessionMerchantId()
  //   }
  //   return _.post(url, data, null, null, null, null, cancelRequest).then(res => {
  //     return res;
  //   });
  // },
  scanImage: function (imageUrl, features) {
    const url = "visionai/v1.0/images";
    const data = {
      merchantId: sessionMerchantId(),
      contentURI: imageUrl,
      discoverFeatures: features || ["LANDMARK", "EMOTION", "SAFE_SEARCH", "TEXT"],
      model: "Jello_vision_model_3.2",
    };
    return _.post(url, data).then(res => {
      return res;
    });
  },
  vertexPredict: function (message, cancelRequest, formatType) {
    let url = `${API_PRODUCTION}vertexllm/predict`;
    const data = {
      query: message,
    };
    const reqObj = {
      headers: { "content-type": "application/json" },
      method: "POST",
      body: JSON.stringify(data),
    };
    if (cancelRequest) reqObj.signal = cancelRequest;
    return fetch(url, reqObj)
      .then(async res => {
        const vertexResponse = convertVertexPredictData(await res.text());
        switch (formatType) {
          case "HTML_CONTENT":
            return { data: vertexResponse };
          case "NEWLINE_CONTENT":
            return { data: stripHTML(transposeBreakLine(vertexResponse)) };
          default:
            return { data: stripHTML(vertexResponse) };
        }
      })
      .catch(error => {
        return { error: true, message: error };
      });
  },
  authToken: function (data, query = "") {
    const url = `ecloudbl/auth/token${query}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getModelList: function (data) {
    const url = "model-service/model/getModelDetails";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getDocuments: function (data) {
    const url = "model-service/knowledgeBaseDocument/getDocuments";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  getKnowledgeBaseDetails: function (data) {
    const url = "model-service/knowledgeBase/getKnowledgeBaseDetails";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  createModel: function (data) {
    let url = `model-service/model/create`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  knowledgeBaseCreate: function (data) {
    let url = `model-service/knowledgeBase/create`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  startTraining: function (data) {
    let url = `model-service/model/train`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateModel: function (modelId, data) {
    let url = `model-service/model/update/${sessionMerchantId()}/${modelId}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteModel: function (modelId) {
    let url = `model-service/model/remove/${sessionMerchantId()}/${modelId}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  deleteKnowledge: function (knowledgeBaseId) {
    let url = `model-service/knowledgeBase/remove/${sessionMerchantId()}/${knowledgeBaseId}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  getSubDocStatus: function (data) {
    let url = `model-service/knowledgeBaseDocument/getSubDocStatus`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  deleteModelDocument: function (documentId) {
    let url = `model-service/knowledgeBaseDocument/remove/${sessionMerchantId()}/${documentId}`;
    return _.delete(url).then(res => {
      return res;
    });
  },
  createDocument: function (data) {
    let url = `model-service/knowledgeBaseDocument/create`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  updateDocument: function (kBId, docId, data) {
    let url = `model-service/knowledgeBaseDocument/update/${sessionMerchantId()}/${kBId}/${docId}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  updateKnowledge: function (kBId, data) {
    let url = `model-service/knowledgeBase/update/${sessionMerchantId()}/${kBId}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  createAnalyticToken: function () {
    const url = `ecloudbl/tableau/token`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getModelIntent: function (documentId) {
    let url = `model-service/knowledgeBaseDocIntent/get?merchantId=${sessionMerchantId()}`;
    if (documentId) {
      url += `&documentId=${documentId}`;
    }
    return _.get(url).then(res => {
      return res;
    });
  },
  createKnwDocIntent: function (data) {
    let url = `model-service/knowledgeBaseDocIntent/create`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  modifyKnwDocIntent: function (data) {
    let url = `model-service/knowledgeBaseDocIntent/modify`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getKnwAIDetails: function () {
    const url = `model-service/knowledgeBase/getModelDetailsForAI`;
    const bodyObj = {
      ai: "WATSONAI",
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createOrUpdateModelEntity: function (data) {
    let url = `model-service/entity/createOrUpdateEntity`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getModelEntityByMerchantId: function (type) {
    let url = `model-service/entity/getEntityByMerchantId/${sessionMerchantId()}${type ? `?type=${type}` : ""}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  deleteModelEntity: function (entityId) {
    let url = `model-service/entity/deleteEntity/${entityId}?merchantId=${sessionMerchantId()}`;
    return _.post(url).then(res => {
      return res;
    });
  },
  createResponsibleAI: function (param, data) {
    let url = `responsibleai/v1/responsibleai/${param}`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  checkMongodbConnection: function (data) {
    let url = `orchestrator/api/check-mongodb-connection`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  updateVisitorPersona: function (referenceID, data) {
    let url = `chimes/visitor/visitorPersona?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&referenceID=${referenceID}`;
    let bodyObj = data;
    return _.put(url, bodyObj).then(res => {
      return res;
    });
  },
  blockVisitor: function (referenceID, blockVisitor) {
    let url = `chimes/visitor/block?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&referenceID=${referenceID}&blockVisitor=${blockVisitor}`;
    return _.put(url).then(res => {
      return res;
    });
  },
  requestApi: function (data) {
    let url = `ecloudbl/request/test`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  generateAI: function (data) {
    let url = `aiservices/api/v1/genAI/generate`;
    let bodyObj = data;
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getPrompt: function () {
    const url = `model-service/promptlab/getPrompt`;
    const bodyObj = {
      merchantId: sessionMerchantId(),
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  createPrompt: function (data) {
    const url = `model-service/promptlab/create`;
    return _.post(url, data).then(res => {
      return res;
    });
  },
  modifyPrompt: function (data, promptId) {
    const url = `model-service/promptlab/modify/${sessionMerchantId()}/${promptId}`;
    return _.put(url, data).then(res => {
      return res;
    });
  },
  createSentinel: function (botId) {
    let url = `sentinel/token/create`;
    const bodyObj = {
      expiryInMinutes: "3000000",
      merchantId: sessionMerchantId(),
      entitlements: [
        {
          merchantId: sessionMerchantId(),
          id: botId,
        },
      ],
    };
    return _.post(url, bodyObj).then(res => {
      return res;
    });
  },
  getProductBrowserV2: function (referenceID) {
    let url = `chimes/v2/browsedProducts?access_token=${sessionAccessToken()}&merchantID=${sessionMerchantId()}&visitorReferenceID=${referenceID}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  getKnowledgeGraph: function () {
    const url = `model-service/knowledgeGraph/get?merchantId=${sessionMerchantId()}`;
    return _.get(url).then(res => {
      return res;
    });
  },
  createKnowledgeGraph: function (data) {
    const url = "model-service/knowledgeGraph/create";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  modifyKnowledgeGraph: function (data) {
    const url = "model-service/knowledgeGraph/modifyKnowledgeGraph";
    return _.post(url, data).then(res => {
      return res;
    });
  },
  deleteKnowledgeGraph: function (knowledgeGraphId) {
    const url = `model-service/knowledgeGraph/deleteById?merchantId=${sessionMerchantId()}&id=${knowledgeGraphId}`;
    return _.get(url).then(res => {
      return res;
    });
  },
};

export default CommonUtils;
