import React, { Component } from "react";
import { Row, Col, Card, CardBody, Label } from "reactstrap";
import CommonUtils from "../../utils/CommonUtils";
import { getAllQueryParams, getMerchantLogo } from "../../utils/GeneralUtils";
import { Input, Button } from "../../common/components";
import { getFormDetails } from "../../common/components/helper";
import { sessionClear, sessionUser } from "../../utils/SessionUtils";
import { BRAND_DOMAIN, BRAND_NAME } from "../../utils/Config";
import FooterContent from "../../components/FooterContent";
import { createUserAgents, getMerchantDetails, redirectToAccount } from "../../utils/LoginUtil";
import { withRouter } from "../../withRouter";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: null,
      apiLogin: false,
      showPwd: false,
      form: {
        userName: "",
        password: "",
        errors: {
          userName: null,
          password: null,
        },
      },
      allowRegistration: false,
      mId: null,
    };
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputValidate = this.onInputValidate.bind(this);
  }
  componentDidMount() {
    const { mId } = this.props.match.params;
    this.setState({ mId });
    if (mId && mId.length > 6) {
      sessionStorage.setItem("cLogin", mId);
    } else {
      sessionStorage.removeItem("cLogin");
      //this.props.navigate('/');
    }
  }

  UNSAFE_componentWillMount() {
    document.title = BRAND_DOMAIN;
    let params = getAllQueryParams(this.props.location.search);
    if (params.internal === "true") {
      this.setState({ allowRegistration: true });
    }
    if (sessionUser()) {
      redirectToAccount(this.props);
    } else {
      sessionClear();
    }
  }

  onInputChange(name, value) {
    this.setState({ form: { ...this.state.form, [name]: value } });
  }

  onInputValidate(name, error) {
    let errors = this.state.form.errors;
    errors[name] = error;
    this.setState({ form: { ...this.state.form, errors } });
  }

  async Login(e) {
    e.preventDefault();
    const { form, mId } = this.state;
    const { userName, password } = form;
    const obj = getFormDetails(form, this.onInputValidate);
    if (!obj) return false;

    this.setState({ apiLogin: true });

    const data = {
      authType: "PG",
      userName,
      password,
    };

    const response = await CommonUtils.authToken(data, window.location.search);
    if (response.error) {
      this.setState({ errorMsg: response.data.error, apiLogin: false });
      return false;
    }

    if (response.twoFA.isTwoFactorEnabled && response.twoFA.isInvalid) {
      this.props.navigate("/support");
      return;
    }

    sessionStorage.setItem("saveUserTemp", response.user.username);
    sessionStorage.setItem("access_token", response.token.access_token);
    sessionStorage.setItem("expires_at", Math.floor(Date.now() / 1000) + response.token.expires_in);
    sessionStorage.setItem("refresh_token", response.token.refresh_token);
    sessionStorage.setItem("stayAlive", "1");

    sessionStorage.setItem("id", response.user.id);
    sessionStorage.setItem("Role", response.user.role);
    sessionStorage.setItem("MerchantId", response.user.merchant.merchantId);
    sessionStorage.setItem("firstName", response.user.firstName || "");
    sessionStorage.setItem("lastName", response.user.lastName || "");
    sessionStorage.setItem("imageUrl", response.user.imageUrl || "");
    sessionStorage.setItem("email", response.user.email || "");
    sessionStorage.setItem("authType", response.user.authType || "PG");

    if (response.twoFA.isTwoFactorEnabled) {
      this.props.navigate("/verify", { state: { user: response.user } });
      return;
    }

    if (response.merchant && response.merchant.type)
      sessionStorage.setItem("businessType", response.merchant.type || "");
    if (response.merchant && response.merchant.timeZone)
      sessionStorage.setItem("timeZone", response.merchant.timeZone || "");
    if (response.merchant && response.merchant.settings)
      localStorage.setItem("wdb", response.merchant.settings.widgetDisableBranding ? "1" : "0");

    await createUserAgents(response.user, userName);
    redirectToAccount(this.props);
  }

  renderIcon(name, type) {
    if (name == "userName" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-user"></i>
          </span>
        </div>
      );
    } else if (name == "password" && type == "prepend") {
      return (
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className="icon-lock"></i>
          </span>
        </div>
      );
    } else if (name == "password" && type == "append") {
      return (
        <div className="input-group-append" onClick={() => this.setState({ showPwd: !this.state.showPwd })}>
          <span className="input-group-text">
            <i className={`fa fa-eye${this.state.showPwd ? "" : "-slash"}`}></i>
          </span>
        </div>
      );
    }
  }

  render() {
    const { form, allowRegistration, errorMsg, showPwd, apiLogin, mId } = this.state;
    const { userName, password, errors } = form;
    return (
      <div className="login-v1">
        <div className="content m-0">
          <div className="box c-box">
            <Card className="m-box border-0 m-0">
              <div className="mlogo">
                <img src={getMerchantLogo(mId)} height="100%" />
              </div>
              <CardBody className={`text-gray-700 ${!mId ? "pb-0" : ""}`}>
                <form onSubmit={this.Login.bind(this)}>
                  <div className="vc-fw-600 fs21 text-center text-muted">Sign in</div>
                  <p className="mb-4 text-center text-muted">to access your account</p>
                  <div className="mb-3">
                    <label className="vc-fw-600">Username</label>
                    <Input
                      outerClassName="mb0"
                      type="text"
                      title="username"
                      name="userName"
                      value={userName}
                      placeholder="Enter username"
                      onChangeFunc={this.onInputChange}
                      isReq={true}
                      error={errors.userName}
                      validationFunc={this.onInputValidate}
                    />
                  </div>
                  <div className="input-password">
                    <label className="vc-fw-600">Password</label>
                    <Input
                      appendIcon={this.renderIcon("password", "append")}
                      outerClassName="mb0"
                      type={showPwd ? "text" : "password"}
                      title="password"
                      name="password"
                      value={password}
                      placeholder="Enter password"
                      onChangeFunc={this.onInputChange}
                      isReq={true}
                      error={errors.password}
                      validationFunc={this.onInputValidate}
                    />
                  </div>
                  <div className="text-right">
                    <Button
                      text="Forgot password?"
                      linkBtn
                      className="px-0 vc-fw-600 fs13"
                      onClickFunc={() => this.props.navigate("/forgotpassword")}
                    />
                  </div>
                  <Button
                    text="Sign in"
                    submitBtn
                    type="submit"
                    className="px-4 w-100 mt-3"
                    style={{ height: 40 }}
                    size="normal"
                    displayIcon={false}
                    loading={apiLogin}
                  />
                  {errorMsg ? (
                    <Row>
                      <Col className="text-center">
                        <Label className="error mb0">{errorMsg}</Label>
                      </Col>
                    </Row>
                  ) : null}
                  {!mId && (
                    <React.Fragment>
                      <div className="link-box mt-3 text-center fs14">
                        <div className="vc-secondary strike-around-text p-2">Or Sign in with</div>
                        <div>
                          <Button
                            text={
                              <React.Fragment>
                                <img src="./img/microsoft.png" width={20} className="mr-2" /> Microsoft
                              </React.Fragment>
                            }
                            outline
                            className="btn-transparent-grey px-4 w-100 mt-3 d-flex justify-content-center align-items-center"
                            style={{ height: 40 }}
                            onClickFunc={() => this.props.navigate("/aadlogin")}
                            displayIcon={false}
                          />
                        </div>

                        {allowRegistration && (
                          <div className="mt-3">
                            New to {BRAND_NAME}?{" "}
                            <a
                              href="javascript:;"
                              className="link"
                              onClick={() => {
                                this.props.navigate("/register", { state: { allowAccess: true, internal: true } });
                              }}
                            >
                              Sign up
                            </a>
                          </div>
                        )}
                      </div>
                      <div className="footer text-muted mt-5">
                        <FooterContent />
                      </div>
                    </React.Fragment>
                  )}
                </form>
              </CardBody>
            </Card>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(Login);
