import React, { Component } from "react";
// import { ControlledEditor } from "@monaco-editor/react";
import Editor from "@monaco-editor/react";
import CommonUtils from "../../../../utils/CommonUtils";
import { debounce } from "lodash";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";
import { Button } from "../../../../common/components";
import { transformationTypes } from "../../../../utils/ConstUtils";
import { mapEnvironment } from "../../../../utils/GeneralUtils";

class Workbench extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: JSON.stringify(props.code, null, 2),
      schema: props.schema,
      transformResult: "",
      editorWidth: 750,
      editorHeight: 350,
      isSchemaLoading: false,
    };
    this.options = {
      fontFamily: "monospace",
      fontSize: 13,
      lineHeight: 20,
      lineNumbers: "off",
      minimap: {
        enabled: false,
      },
    };
    this.controller = new AbortController();

    this.renderSubTitle = this.renderSubTitle.bind(this);
    this.onResizeWidth = this.onResizeWidth.bind(this);
    this.onResizeHeight = this.onResizeHeight.bind(this);
    this.changeTransformationText = this.changeTransformationText.bind(this);
    this.transformationSchemaTxt = debounce(() => this.changeTransformationText(), 1000, { maxWait: 5000 });
  }

  componentDidMount() {
    this.changeTransformationText();
  }

  componentWillUnmount() {
    this.controller.abort();
  }

  onResizeWidth(event, { node, size, handle }) {
    this.setState({ editorWidth: size.width });
  }

  onResizeHeight(event, { node, size, handle }) {
    this.setState({ editorHeight: size.height });
  }

  handleOnChange(name, value) {
    this.setState({ [name]: value }, () => {
      this.transformationSchemaTxt();
    });
  }

  async changeTransformationText() {
    this.setState({ isSchemaLoading: true });
    const res = await this.getTransformJson();
    this.setState({ transformResult: res, isSchemaLoading: false });
  }

  async getTransformJson() {
    try {
      this.controller.abort();
      this.controller = new AbortController();
      const { code, schema } = this.state;
      const { type } = this.props;
      switch (type) {
        case "javascript":
          const { entity, environmentList, customConfigData } = this.props;
          const mappedScriptText = environmentList
            ? mapEnvironment(
                environmentList.filter(x => x.key && x.value),
                schema,
                customConfigData
              )
            : schema;
          const jsData = {
            entity,
            scriptText: mappedScriptText,
            payload: JSON.parse(code),
          };
          return await CommonUtils.validateJs(jsData, this.controller.signal);

        case "jsonTransformation":
          const { transformationType } = this.props;
          const transformData = {
            transformationType,
            jsonSchemaStr: schema,
            payload: JSON.parse(code),
          };
          return await CommonUtils.transformJSON(transformData, this.controller.signal);
      }
    } catch (error) {
      this.setState({ isSchemaLoading: false });
      return { invalid: true, message: error.message };
    }
  }

  getSchemaLanguage(transformationType) {
    switch (transformationType) {
      case "dsl":
      case "ai":
        return "scheme";
      case "classic":
        return "json";
      default:
        return "javascript";
    }
  }

  renderSubTitle() {
    const { type, transformationType } = this.props;
    let subTitle = "";
    switch (type) {
      case "javascript":
        subTitle = "JavaScript";
        break;
      case "jsonTransformation":
        subTitle = transformationTypes.find(t => t.value === transformationType).label;
        break;
      default:
        break;
    }

    if (!subTitle) return "";
    return <span className="fs13 ml-1">for {subTitle}</span>;
  }

  render() {
    const { code, transformResult, schema, editorWidth, editorHeight, isSchemaLoading } = this.state;
    const { type, transformationType } = this.props;

    return (
      <div className="work-bench-popup">
        <div className="popup-content">
          <header className="popup-header d-flex justify-content-between align-items-center">
            <div>
              <h2>Workbench{this.renderSubTitle()}</h2>
            </div>
            <div className="right-btn">
              <Button
                submitBtn
                className="ml10"
                text="Apply"
                displayIcon={false}
                onClickFunc={() => {
                  this.props.updateSchemaJson(this.state.schema);
                }}
              />
              <Button
                cancelBtn
                text="Close"
                className="ml10"
                displayIcon={false}
                onClickFunc={this.props.closeWorkBench}
              />
            </div>
          </header>
          <div className="workbench-layout">
            <Resizable
              className="panel-left"
              width={editorWidth}
              onResize={this.onResizeWidth}
              resizeHandles={["e"]}
              handle={<div className="panel-left-handle" />}
            >
              <div style={{ width: editorWidth + "px" }}>
                <span className="editor-tag">Input</span>
                <Editor
                  height="100%"
                  language="json"
                  value={code}
                  options={this.options}
                  onChange={value => this.handleOnChange("code", value)}
                />
              </div>
            </Resizable>
            <div className="right-content">
              <Resizable
                className="panel-right"
                height={editorHeight}
                onResize={this.onResizeHeight}
                resizeHandles={["s"]}
                handle={<div className="panel-bottom-handle" />}
              >
                <div style={{ height: editorHeight + "px" }}>
                  <span className="editor-tag">{type === "javascript" ? "JavaScript" : "Schema"}</span>
                  <Editor
                    height="100%"
                    language={this.getSchemaLanguage(transformationType)}
                    value={schema}
                    options={this.options}
                    onChange={value => this.handleOnChange("schema", value)}
                  />
                </div>
              </Resizable>
              <div className="panel-rightBottom panel-disabled">
                {isSchemaLoading && <img src="/img/loader.gif" className="schema-loader" />}
                <span className="editor-tag">Output</span>
                <Editor
                  height="100%"
                  language="json"
                  value={JSON.stringify(transformResult, null, 2)}
                  options={{ ...this.options, readOnly: true }}
                  readOnly={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Workbench;
