import React from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
import momentTz from "moment-timezone";
import countrycode from "./CountryCode.json";
import {
  sessionUser,
  sessionFirstName,
  sessionLastName,
  sessionMerchantId,
  sessionTimeZone,
  sessionCLogin,
  sessionRole,
  sessionUserId,
  sessionAccessToken,
} from "./SessionUtils";
import {
  BRAND_LOGO_URL,
  CLOGIN_DOMAIN,
  CLOGIN_MERCHANT,
  CLOUDINARY_DATA,
  CLOUDINARY_ENDPOINT,
  GCS_BUCKET,
  MESSENGER_URL,
} from "./Config";
import "./../js/cloudinary-upload";
import { cloneDeep, get, unionBy } from "lodash";
import CommonUtils from "./../utils/CommonUtils";

momentDurationFormatSetup(moment);

const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export const gridNoRecord = (
  fetchingData,
  list,
  colSpan,
  waitMsg = "Please wait while fetching records...",
  noRecordMsg = "No record found to display."
) => {
  if (fetchingData) {
    return (
      <tfoot>
        <tr>
          <td className="record-notfound" colSpan={colSpan}>
            <div>
              <i className="fa fa-refresh fa-spin fa-fw"></i> {waitMsg}
            </div>
          </td>
        </tr>
      </tfoot>
    );
  } else if (list && list.length == 0) {
    return (
      <tfoot>
        <tr>
          <td className="record-notfound" colSpan={colSpan}>
            <div>
              <i className="fa fa-info-circle info-icon"></i>
              {noRecordMsg}
            </div>
          </td>
        </tr>
      </tfoot>
    );
  } else return null;
};
export const listNoRecord = (
  fetchingData,
  list,
  waitMsg = "Please wait while fetching records...",
  noRecordMsg = "No record found to display."
) => {
  if (fetchingData) {
    return (
      <div className="list-notfound">
        <i className="fa fa-refresh fa-spin fa-fw"></i> {waitMsg}
      </div>
    );
  } else if (list && list.length == 0) {
    return (
      <div className="list-notfound">
        <i className="fa fa-info-circle info-icon"></i>
        {noRecordMsg}
      </div>
    );
  } else return null;
};

export const phoneValidate = event => {
  if (event.which < 48 || event.which > 57) {
    event.preventDefault();
  }
};

export const trimData = (obj, isPayload = false) => {
  obj = cloneDeep(obj);
  const isObjType = obj && typeof obj === "object";
  if (isObjType) {
    Object.keys(obj).map(key => {
      if (isPayload && key == "errors") {
        delete obj[key];
      }
      if (typeof obj[key] === "object") {
        obj[key] = trimData(obj[key], isPayload);
      } else if (typeof obj[key] === "string") {
        obj[key] = obj[key].trim();
      }
    });
  }
  return obj;
};

export const maskString = (value, start = 2, end = 2, maskcount = 4) => {
  value = String(value);
  const length = value.length;
  if (start + end >= length) {
    return "*".repeat(length);
  }
  maskcount = maskcount >= 0 ? maskcount : length - (start + end);
  const maskedPart = "*".repeat(maskcount);
  return value.slice(0, start) + maskedPart + value.slice(-end);
};

export const numberValidate = event => {
  let isValid = document.getElementById(event.target.id).value.indexOf(".") != -1;
  if ((event.which != 46 || isValid) && (event.which < 48 || event.which > 57)) {
    event.preventDefault();
  }
};
export const isValidNumber = value => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};
export const isEmpty = value => {
  return (typeof value == "string" && !value.trim()) || typeof value == "undefined" || value === null;
};
export const isURL = value => {
  var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  return re.test(value);
};
export const isHttpURL = value => {
  var re =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return re.test(value);
};
export const isVideoURL = value => {
  const re = /^.*\.(avi|AVI|wmv|WMV|flv|FLV|mpg|MPG|mp4|MP4)/;
  return re.test(value);
};
export const isImgURL = value => {
  const re = /^.*\.(gif|png|jpg|jpeg|bmp|webp|svg)/;
  return re.test(value);
};
export const isDocURL = value => {
  const re = /^.*\.(doc|docx)/;
  return re.test(value);
};
export const isCsvURL = value => {
  const re = /^.*\.(csv)/;
  return re.test(value);
};
export const isPdfURL = value => {
  const re = /^.*\.(pdf)/;
  return re.test(value);
};
export const isTextURL = value => {
  const re = /^.*\.(txt)/;
  return re.test(value);
};
export const checkURL = (url = "") => {
  const imgURL = url.trim().toLowerCase();
  var base64Re = /^data:image\/(?:gif|png|jpg|jpeg|bmp|webp)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/g;
  var imgURLRe = /^(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/g;
  return !imgURL.includes(" ") && (base64Re.test(imgURL) || imgURL.match(imgURLRe));
};
export const isChannelId = (engagementData, ids) =>
  engagementData.channel ? (ids.includes(engagementData.channel.channelId) ? true : false) : false;

export const getDate = (dtValue = null, format = "DD/MM/YYYY hh:mm:ss A", inputDtFormat) => {
  if (dtValue) return (inputDtFormat ? moment(dtValue, inputDtFormat) : moment(dtValue)).format(format);
  else return moment().format(format);
};
export const getUtcDate = (format = null) => {
  return moment().utc().format(format);
};
export const getMerchantDate = (startDate = null, format, inputDtFormat = null) => {
  const timeZone = sessionTimeZone();
  let merchantDt = null;

  if (startDate) merchantDt = (inputDtFormat ? moment(startDate, inputDtFormat) : moment(startDate)).tz(timeZone, true);
  else merchantDt = moment().tz(timeZone, true);

  if (format) merchantDt = merchantDt.format(format);

  return merchantDt;
};
export const getStatus = (isActive = true) => {
  return isActive ? "Active" : "Inactive";
};
export const getVersion = () => {
  return "1.0";
};
export const getDateDiff = (startDtValue = null, endDtValue = null) => {
  const days = moment(endDtValue).diff(startDtValue, "days");
  const hours = moment(endDtValue).diff(startDtValue, "hours");
  const mins = moment(endDtValue).diff(startDtValue, "minutes");

  return days == 0 ? (hours == 0 ? `${mins} mins` : `${hours} h`) : !isNaN(days) ? `${days} days` : "-";
};
export const getDateDiffInUnit = (startDt, endDt, unit) => {
  return moment(endDt).diff(startDt, unit);
};
export const getCurrencySymbol = (currency_name = "USD") => {
  var currency_symbols = {
    AED: "د.إ", // United Arab Emirates Dirham
    AUD: "$", // AUS Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    QAR: "ر.ق", // QAR
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    USD: "$", // US Dollar
    VND: "₫", // Vietnamese Dong
    SAR: "﷼", // Saudi Riyal
  };

  if (currency_symbols[currency_name]) {
    return currency_symbols[currency_name];
  } else {
    return currency_symbols["USD"];
  }
};

// 0|2 - Pending, 1 - Completed, 3 - Mark for Deletion, 4 - Out of Sync, 5 - Deleted
export const getStatusCol = CStatus => {
  const intentStatus = [
    { status: "0", label: "Not Trained", color: "red" },
    { status: "1", label: "Completed", color: "green" },
    { status: "2", label: "Not Trained", color: "red" },
    { status: "3", label: "Mark for Deletion", color: "red" },
    { status: "4", label: "Out of Sync", color: "" },
    { status: "5", label: "Deleted", color: "gray" },
    { status: "in-progress", label: "Not Trained", color: "red" },
  ];
  let obj = intentStatus.find((x, i) => x.status == CStatus);
  return obj || {};
};

export const getCurrencyList = () => {
  return [
    "AED", // United Arab Emirates Dirham
    "AUD", // AUS Dollar
    "EUR", // Euro
    "CRC", // Costa Rican Colón
    "GBP", // British Pound Sterli
    "ILS", // Israeli New Sheqel
    "INR", // Indian Rupee
    "JPY", // Japanese Yen
    "KRW", // South Korean Won
    "NGN", // Nigerian Naira
    "PHP", // Philippine Peso
    "PLN", // Polish Zloty
    "PYG", // Paraguayan Guarani
    "THB", // Thai Baht
    "UAH", // Ukrainian Hryvnia
    "USD", // US Dollar
    "VND", // Vietnamese Dong
    "SAR", // Saudi Riyal
  ];
};
export const getRandomNumber = (digit = 16) => {
  return Math.random().toFixed(digit).split(".")[1];
};

export const generateRandomNumber = (min = 0, max = 100) => {
  // find diff
  let difference = max - min;

  // generate random number
  let rand = Math.random();

  // multiply with difference
  rand = Math.floor(rand * difference);

  // add with min value
  rand = rand + min;

  return rand;
};

export const isValidDigit = (min, max, value) => {
  var regexObj = new RegExp("^[0-9]{" + min + "," + max + "}$");
  return regexObj.test(value);
};

export const getAiList = (value, type) => {
  const list = [
    { value: "WATSONAI", name: "IBM®Watson AI", type: "Natural_Language" },
    { value: "OPENAI", name: "OpenAI", type: "Generative_AI", keyName: "API Key" },
    { value: "GOOGLEAI", name: "Google Gemini AI", type: "Generative_AI", keyName: "Project File" },
    { value: "LLAMA3", name: "Meta Llama 3", type: "Generative_AI" },
    { value: "AZUREAI", name: "Microsoft Azure OpenAI", type: "Generative_AI" },
    { value: "BEDROCK", name: "Amazon Bedrock", type: "Generative_AI" },
    { value: "CLAUDEAI", name: "Anthropic Claude AI", type: "Generative_AI", keyName: "API Key" },
    { value: "MONDEEAI", name: "Mondee AI™", type: "Generative_AI", keyName: "Vectorspace Id" },
    { value: "SOLAI", name: "Purplegrids SOL™AI", type: "Generative_AI", keyName: "Vectorspace Id" },
    // { value: "VERTEXAI", name: "Google Vertex AI", type: "Computer_Vision" },
    { value: "VISIONAI", name: "Vision AI", type: "Computer_Vision" },
  ];
  if (value) {
    //const obj = list.find(x => x.value === value && (!type || x.type === type));
    const obj = list.find(x => x.value === value && x.type === type);
    if (!obj) return { value: value, name: value };
    return obj;
  }
  return list;
};

export const getIntegrationList = value => {
  const list = [
    {
      value: "EPIC",
      name: "EPIC",
      category: "integration",
      description:
        "The EPIC Integration API is a specialized interface used to connect electronic health record (EHR) systems from EPIC with other healthcare applications. It enables the exchange of patient data, streamlines medical workflows, and improves interoperability, empowering healthcare providers to offer more comprehensive and coordinated patient care while maintaining data accuracy and security.",
    },
    {
      value: "Google Calendar",
      name: "Google Calendar",
      category: "integration",
      description:
        "The Google Calendar Integration API allows developers to connect and interact with Google Calendar within their applications. It enables scheduling, event creation, and calendar management, streamlining time management and appointments. By integrating with Google Calendar, developers can enhance productivity and offer users seamless access to their schedules and events.",
    },
    {
      value: "Hubspot",
      name: "Hubspot",
      category: "integration",
      description:
        "The HubSpot Integration API connects third-party software applications with HubSpot's customer relationship management (CRM) and marketing automation platform. It enables data synchronization, lead management, and marketing automation, enhancing customer engagement and streamlining marketing and sales processes. Developers can create customized solutions that improve user interactions and business efficiency.",
    },
    {
      value: "Marketo",
      name: "Marketo",
      category: "integration",
      description:
        "The Marketo Integration API connects Marketo's marketing automation platform with third-party applications, allowing developers to access, update, and manage marketing data. It streamlines marketing processes, lead nurturing, and campaign management. By integrating with Marketo, businesses can optimize their marketing efforts, improve customer engagement, and track marketing performance more effectively.",
    },
    {
      value: "Salesforce",
      name: "Salesforce",
      category: "integration",
      description:
        "The Salesforce Integration API connects Salesforce's customer relationship management (CRM) platform with external applications. It enables data synchronization, automation, and access to customer data. By integrating with Salesforce, businesses can improve lead management, customer interactions, and sales processes, streamlining CRM operations and enhancing customer relationship management.",
    },
    {
      value: "Zappier",
      name: "Zappier",
      category: "integration",
      description:
        "Zapier Integration API connects over 3,000 apps, automating workflows and enhancing productivity. Developers integrate custom triggers and actions into applications, enabling seamless data exchange between diverse platforms. By automating repetitive tasks, businesses optimize efficiency, streamline processes, and improve collaboration, creating a unified and dynamic digital ecosystem for diverse applications.",
    },
    {
      value: "Slack",
      name: "Slack",
      category: "integration",
      description:
        "Slack Integration API facilitates seamless communication and collaboration within applications. Developers can integrate Slack's messaging, file sharing, and notification features, enhancing team productivity and engagement. By connecting external tools and services, it enables real-time information sharing, task automation, and interactive workflows, creating a unified and efficient workspace for teams across various industries.",
    },
    {
      value: "SendGrid",
      name: "SendGrid",
      category: "integration",
      description:
        "The SendGrid Integration API connects SendGrid's email delivery and marketing platform with external applications. It enables email campaign management, tracking, and engagement analytics. By integrating with SendGrid, developers can enhance email marketing capabilities, improve email deliverability, and create customized email solutions, optimizing communication with their users and customers.",
    },
    {
      value: "LeadSquared",
      name: "LeadSquared",
      category: "integration",
      description:
        "The LeadSquared Integration API enables seamless integration of LeadSquared's marketing automation and CRM platform with third-party applications. It allows developers to access, update, and manage lead data and marketing campaigns. By integrating with LeadSquared, businesses can streamline lead management and marketing processes, enhancing customer engagement and sales efforts.",
    },
    {
      value: "Zoom",
      name: "Zoom",
      category: "integration",
      description:
        "Zoom Integration API empowers developers to embed Zoom's video conferencing capabilities into applications. It enables real-time video and audio communication, webinars, and virtual meetings. By integrating this API, businesses enhance remote collaboration, enabling seamless virtual interactions, webinars, and online events. It fosters dynamic, interactive communication, improving team collaboration and customer engagement.",
    },
    {
      value: "Teams",
      name: "Teams",
      category: "integration",
      description:
        "Teams Integration API by Microsoft enables seamless integration of Microsoft Teams with third-party applications and services. It allows developers to enhance collaboration by embedding chat, meetings, and calling features into their apps. By facilitating real-time communication and data sharing, it creates a unified workspace, enhancing productivity and fostering teamwork across organizations",
    },
    {
      value: "Twilio",
      name: "Twilio",
      category: "integration",
      description:
        "Twilio Integration API empowers developers to embed robust communication capabilities into applications. It offers SMS, voice, and video functionalities, enabling businesses to send messages, make calls, and conduct virtual meetings programmatically. With seamless integration, companies enhance customer engagement, automate notifications, and create interactive communication channels, optimizing user experience and operational efficiency",
    },
    {
      value: "Cloudinary",
      name: "Cloudinary",
      category: "integration",
      description:
        "Cloudinary API is a powerful solution for developers seeking to manage and optimize media assets in their applications. This API allows for easy uploading, transformation, and delivery of images and videos. It enables dynamic media handling, enhancing user experiences by ensuring fast, responsive, and visually appealing content across web and mobile platforms.",
    },
    {
      value: "GTM",
      name: "GoToMeeting",
      category: "integration",
      description:
        "The GoToMeeting Integration API facilitates the integration of GoToMeeting's video conferencing and collaboration services into third-party applications. It empowers developers to embed video meetings, webinars, and scheduling features, enhancing virtual communication capabilities and enabling custom solutions for efficient and seamless remote collaboration and online events.",
    },
    {
      value: "OneDrive",
      name: "OneDrive",
      category: "integration",
      description:
        "The OneDrive Integration API enables developers to connect and interact with Microsoft's cloud-based file storage and synchronization service, OneDrive. It offers features for file management, access control, and data synchronization. By integrating with OneDrive, developers can enhance data storage, sharing, and collaboration capabilities within their applications, streamlining file management and data access.",
    },
    {
      value: "JotForm",
      name: "JotForm",
      category: "integration",
      description:
        "The JotForm Integration API allows seamless integration of JotForm's online form-building and data collection platform with various applications. It empowers developers to access, create, and manage forms and submission data, enhancing data collection, automation, and information flow within software solutions, ultimately improving form-driven processes and data management.",
    },
    {
      value: "API",
      name: "API",
      category: "integration",
      description:
        "API integration is the process of connecting two or more software applications together so that they can share data and functionality. This is done by using APIs, which are sets of rules and specifications that define how different applications can communicate with each other. APIs are often used to connect cloud-based applications to on-premises systems, or to connect different applications within the same organization.",
    },
    {
      value: "Secure API",
      name: "Secure API",
      category: "integration",
      description:
        "Secure API Integration APIs provide a comprehensive set of tools for securely connecting APIs with a wide range of external systems and applications. Built on RESTful principles, these APIs offer granular access to API security features and functionality, enabling developers to create secure integrations that meet the unique needs of their organization.",
    },
    {
      value: "Video Conferencing",
      name: "Video Conferencing",
      category: "integration",
      description:
        "Video Conferencing Integration API empowers developers to seamlessly embed video conferencing features into applications. It enables real-time audio and video communication, screen sharing, and chat functionalities. By integrating this API, businesses enhance virtual collaboration, facilitating remote meetings, webinars, and virtual events. This fosters interactive communication and improves team productivity across diverse industries.",
    },
    {
      value: "Avaya",
      name: "Avaya",
      category: "integration",
      description:
        "The Avaya Integration API is a robust software interface that enables seamless integration of Avaya communication and collaboration solutions with third-party applications. This API empowers developers to create customized communication solutions, enhance customer experiences, and streamline business processes by accessing and controlling Avaya's communication and collaboration features programmatically.",
    },
    {
      value: "Jira",
      name: "Jira",
      category: "integration",
      description:
        "The Jira Integration API connects Jira, a popular project and issue tracking tool, with other software applications. It enables developers to automate workflows, access project data, and enhance issue tracking and management. By integrating with Jira, developers can streamline project management, collaboration, and issue resolution processes for improved productivity.",
    },
    {
      value: "Calendly",
      name: "Calendly",
      category: "integration",
      description:
        "Calendly API provides a gateway for developers to connect their applications with Calendly's scheduling and appointment booking platform. It allows for automated scheduling, event creation, and management, facilitating seamless integration of Calendly's features into various software solutions to streamline appointment setting and calendar management.",
    },
    {
      value: "Google Sheets",
      name: "Google Sheets",
      category: "integration",
      description:
        "Google Sheets Integration API allows developers to connect and interact with Google Sheets in their applications. It offers features for creating, modifying, and retrieving spreadsheet data, enhancing data management and collaboration. By integrating with Google Sheets, developers can streamline data-driven workflows and enable real-time data synchronization, optimizing productivity and information sharing.",
    },
    {
      value: "Stripe",
      name: "Stripe",
      category: "integration",
      description:
        "Stripe Integration API revolutionizes online payment processing. It empowers businesses to securely accept payments, handle subscriptions, and manage transactions. Developers can seamlessly integrate Stripe's powerful features into websites and applications, enhancing customer experience. With robust security measures and user-friendly documentation, it's a go-to solution for businesses seeking efficient and reliable payment solutions.",
    },
    {
      value: "Microsoft Dynamics",
      name: "Microsoft Dynamics",
      category: "integration",
      description:
        "The Microsoft Dynamics Integration API links Microsoft Dynamics, a suite of customer relationship management (CRM) and enterprise resource planning (ERP) applications, with external software. It streamlines data access, customer management, and business processes. By integrating with Microsoft Dynamics, businesses can optimize CRM, ERP, and financial operations, improving data flow and customer interactions.",
    },
    {
      value: "Service Now",
      name: "Service Now",
      category: "integration",
      description:
        "The ServiceNow Integration API links ServiceNow's IT service management platform with external applications. It allows data synchronization, automation, and incident management. By integrating with ServiceNow, organizations can streamline IT service processes, enhance service desk operations, and improve incident resolution, optimizing IT service delivery and support across various industries.",
    },
    {
      value: "Box",
      name: "Box",
      category: "integration",
      description:
        "The BOX API is a versatile interface for connecting with the BOX cloud storage and content management platform. It enables developers to create, access, and manage digital content programmatically, facilitating secure file storage, collaboration, and integration of BOX services into various applications for enhanced document and data management.",
    },
    {
      value: "Shopify",
      name: "Shopify",
      category: "eCommerce",
      description:
        "Shopify Integration API allows seamless connection between Shopify stores and third-party applications. It enables developers to access store data, automate processes, and enhance user experience. Through RESTful endpoints, it facilitates tasks like product management, order processing, and customer interactions, ensuring efficient e-commerce operations and customized solutions for businesses.",
    },
    {
      value: "SAP",
      name: "SAP",
      category: "integration",
      description:
        "SAP Integration API links SAP's enterprise software solutions with external applications. It enables data exchange, business process automation, and seamless integration with SAP's ERP, CRM, and other systems. By integrating with SAP, organizations can optimize business operations, improve data accessibility, and streamline enterprise-wide processes, enhancing efficiency and decision-making.",
    },
    {
      value: "Uber",
      name: "Uber",
      category: "integration",
      description:
        "Uber Integration API allows developers to seamlessly incorporate Uber's ride-hailing services into their applications. It enables users to request rides, estimate fares, and track drivers in real time. By integrating Uber API, businesses enhance customer convenience, providing reliable transportation solutions, and ensuring a seamless and efficient user experience within their own platforms.",
    },
    {
      value: "Lyft",
      name: "Lyft",
      category: "integration",
      description:
        "The Lyft Integration API facilitates integration of Lyft's ride-sharing services into third-party applications. It enables developers to embed ride requests, price estimates, and ride data access, enhancing transportation options for users within their software solutions. By integrating with Lyft, developers can offer convenient and efficient ride-sharing experiences.",
    },
    {
      value: "Softbank Robotics",
      name: "Softbank Robotics",
      category: "integration",
      description:
        "Softbank Robotics Integration API empowers developers to enhance robots like Pepper and NAO with custom functionalities. It allows seamless integration of software applications, enabling robots to interact, understand human emotions, and perform tasks in diverse environments. This API opens doors for innovative applications in retail, healthcare, education, and hospitality, transforming human-robot interactions.",
    },
    {
      value: "TemiCloud",
      name: "TemiCloud",
      category: "integration",
      description:
        "TemiCloud Integration APIs provide a comprehensive set of tools for connecting Temi robots with a wide range of external systems and applications. Built on RESTful principles, these APIs offer granular access to Temi's data and functionality, enabling developers to create seamless integrations that meet the unique needs of their organization. ",
    },
    {
      value: "PuduCloud",
      name: "PuduCloud",
      category: "integration",
      description:
        "PuduCloud Integration APIs provide a comprehensive set of tools for connecting Pudu Robotics' robots with a wide range of external systems and applications. Built on RESTful principles, these APIs offer granular access to Pudu Robotics' robots' data and functionality, enabling developers to create seamless integrations that meet the unique needs of their organization. ",
    },
    {
      value: "UBTECH Robotics",
      name: "UBTECH Robotics",
      category: "integration",
      description:
        "UBTECH Robotics Integration API allows developers to enhance UBTECH's robots with custom functionalities. It provides seamless integration of software applications, enabling robots to interact, recognize objects, and perform tasks autonomously. This API facilitates innovative applications in education, entertainment, and healthcare, revolutionizing human-robot interactions and fostering creativity in diverse industries.",
    },
    {
      value: "Rapid API",
      name: "Rapid API",
      category: "integration",
      description:
        "Rapid API Integration API serves as a unified platform for integrating various APIs into applications. It simplifies API connectivity, offering a marketplace of pre-built APIs and tools for developers. This integration hub streamlines API access and usage, making it easier to incorporate diverse functionalities and data into software solutions.",
    },
    {
      value: "Paypal",
      name: "Paypal",
      category: "payments",
      description:
        "PayPal Integration API connects PayPal's online payment and money transfer services with external applications. It offers features for processing payments, managing accounts, and facilitating e-commerce transactions. By integrating with PayPal, businesses can offer secure online payment options and streamline financial transactions, enhancing online shopping and payment processing capabilities",
    },
    {
      value: "Paytm",
      name: "Paytm",
      category: "payments",
      description:
        "The Paytm Integration API links Paytm's digital payment and financial services with external applications. It provides features for processing payments, mobile recharges, and financial transactions. By integrating with Paytm, developers can offer users a secure and convenient way to make digital payments, boosting mobile commerce and financial services within their applications.",
    },
    {
      value: "Braintree",
      name: "Braintree",
      category: "payments",
      description:
        "The Braintree API is a payment processing system by PayPal, designed for developers to seamlessly integrate secure and flexible online and mobile payment solutions into their applications. It offers features for processing transactions, managing customer information, and ensuring PCI compliance, making it an essential tool for e-commerce and payment processing applications.",
    },
    {
      value: "Razorpay",
      name: "Razorpay",
      category: "payments",
      description:
        "Razorpay Integration API connects the Razorpay payment gateway with applications, facilitating online payment processing, digital wallet integration, and payment gateway management. It simplifies payment transactions, subscription management, and financial data handling. By integrating with Razorpay, businesses can offer secure and efficient online payment options, enhancing e-commerce and financial services.",
    },
    {
      value: "NoqoodyPAY",
      name: "NoqoodyPAY",
      category: "payments",
      description:
        "NoqoodyPAY is a digital payment platform that allows users to make payments online and in-store. It offers a variety of APIs that can be integrated into other platforms, allowing businesses to accept NoqoodyPAY payments. To integrate NoqoodyPAY into their platform, merchants will need to create a NoqoodyPAY account and obtain an API key.",
    },
    {
      value: "Workday",
      name: "Workday",
      category: "integration",
      description:
        "Workday Integration API streamlines the integration of Workday's robust HR and finance solutions into third-party applications. Developers can seamlessly access employee data, payroll information, and financial metrics. By integrating this API, businesses optimize workforce management, automate HR processes, and enhance financial reporting, ensuring efficient, data-driven decision-making and improved organizational efficiency.",
    },
    {
      value: "Zendesk",
      name: "Zendesk",
      category: "integration",
      description:
        "Zendesk Integration API empowers businesses to enhance customer support and service experiences. It allows developers to integrate Zendesk's ticketing, chat, and knowledge base features into their applications. By seamlessly connecting customer interactions and support workflows, businesses provide efficient, personalized service, ensuring customer satisfaction and loyalty while optimizing support operations for streamlined efficiency.",
    },
    {
      value: "Zoho",
      name: "Zoho",
      category: "integration",
      description:
        "Zoho Integration API facilitates seamless integration of Zoho's suite of applications into third-party software. Developers can automate tasks, access customer data, and synchronize workflows across various Zoho tools. By integrating this API, businesses optimize productivity, enhance collaboration, and deliver tailored solutions, ensuring streamlined operations and improved customer experiences.",
    },
    {
      value: "Freshdesk",
      name: "Freshdesk",
      category: "integration",
      description:
        "The Freshdesk Integration API is a versatile tool for seamlessly connecting Freshdesk's customer support and helpdesk platform with other applications. It allows developers to automate support workflows, access customer data, and create tailored solutions, enhancing customer service processes and enabling efficient, integrated customer support experiences across various software applications",
    },
    {
      value: "Nest Thermostat",
      name: "Nest Thermostat",
      category: "integration",
      description:
        "The Nest Thermostat Integration API allows developers to connect and interact with Nest smart thermostats in their applications. It enables temperature control, schedule adjustments, and energy monitoring, providing users with convenient and efficient home climate management. Integrating with Nest thermostats enhances energy savings and home automation capabilities.",
    },
    {
      value: "Arlo Camera",
      name: "Arlo Camera",
      category: "integration",
      description:
        "The Arlo Camera Integration API is a powerful tool that enables third-party developers to integrate Arlo smart cameras into their applications and services. With this API, businesses and individuals can access and control Arlo cameras, leveraging their features for applications such as home security, surveillance, and automation.",
    },
    {
      value: "Pressure Thermometer",
      name: "Pressure Thermometer",
      category: "integration",
      description:
        "A Pressure Thermometer Integration API links pressure measurement devices with applications. It offers real-time access to pressure data, enabling remote monitoring and control of industrial processes. This integration enhances safety and efficiency by providing accurate pressure measurements, supporting predictive maintenance, and streamlining data analysis and management in various industries.",
    },
    {
      value: "Connected Inhalers",
      name: "Connected Inhalers",
      category: "integration",
      description:
        "Connected inhalers refer to smart inhalation devices equipped with IoT technology. These inhalers monitor medication usage and send data to a cloud platform. Patients and healthcare providers can access this data for tracking, adherence monitoring, and treatment optimization. Connected inhalers aim to improve respiratory disease management and enhance patient outcomes.",
    },
    {
      value: "Glucose Monitoring",
      name: "Glucose Monitoring",
      category: "integration",
      description:
        "The Glucose Monitoring Integration API connects glucose monitoring devices and data with healthcare applications. It enables real-time tracking and sharing of blood glucose levels, helping patients manage diabetes effectively. Healthcare providers can access this data, improving patient care and treatment adjustments, ultimately enhancing diabetes management and patient well-being.",
    },
    {
      value: "Heart Rate Monitoring",
      name: "Heart Rate Monitoring",
      category: "integration",
      description:
        "The Heart Rate Monitoring Integration API enables the integration of heart rate monitoring devices into healthcare and fitness applications. It provides real-time heart rate data, allowing developers to track, analyze, and use this information for health monitoring, exercise optimization, and wellness solutions, ultimately enhancing user health and fitness experiences.",
    },
    {
      value: "Patient Monitoring",
      name: "Patient Monitoring",
      category: "integration",
      description:
        "The Patient Monitoring Integration API connects patient monitoring devices with healthcare applications. It allows real-time tracking of vital signs and health data, improving remote patient monitoring and healthcare delivery. This integration enhances patient care, allows timely interventions, and optimizes healthcare resources for improved patient outcomes and medical efficiency.",
    },
    {
      value: "mqtt",
      name: "MQTT",
      category: "integration",
      description:
        "MQTT Integration API facilitates seamless communication between IoT devices and applications using the MQTT protocol. Developers can subscribe and publish messages, ensuring efficient and real-time data exchange. By integrating MQTT API, businesses optimize IoT deployments, enabling secure, low-bandwidth, and reliable messaging, enhancing IoT device coordination, monitoring, and automation in diverse applications.",
    },
    {
      value: "Jello Ticketing App",
      name: "Jello Ticketing App",
      category: "integration",
      description:
        "The Jello Ticketing App Integration API facilitates seamless integration of Jello's ticketing and event management services into other applications. It empowers developers to access ticketing data, manage events, and enhance event ticketing workflows. By integrating with Jello, developers can create custom event solutions, improving ticketing and event management experiences.",
    },
    {
      value: "ADP (Workforce Now)",
      name: "ADP (Workforce Now)",
      category: "integration",
      description:
        "The ADP Workforce Now Integration API is a robust interface that allows seamless connectivity between ADP's HR and payroll platform, Workforce Now, and other third-party applications. It empowers businesses to automate data exchange, streamline processes, and enhance workforce management by enabling the sharing of critical HR and payroll data, such as employee information and timekeeping, with external software systems.",
    },
    {
      value: "Oracle Symphony",
      name: "Oracle Symphony",
      category: "integration",
      description:
        "Oracle Symphony Integration APIs provide a comprehensive set of tools for connecting Oracle Symphony with a wide range of third-party systems and applications. These APIs enable seamless data exchange and workflow integration, empowering businesses to streamline operations, enhance guest experiences, and gain valuable insights into their operations.",
    },
    {
      value: "PeopleSoft",
      name: "PeopleSoft",
      category: "integration",
      description:
        "PeopleSoft Integration API connects Oracle's PeopleSoft enterprise software with external systems. It facilitates data exchange, automates HR, financial, and supply chain processes, and streamlines business operations. By integrating with PeopleSoft, organizations can optimize enterprise resource planning, enhance workflow efficiency, and improve data management and accessibility across their applications.",
    },
    {
      value: "BambooHR",
      name: "BambooHR",
      category: "integration",
      description:
        "BambooHR API is a powerful tool for integrating BambooHR's human resources management software with other applications. It allows developers to access and manipulate HR data, automate processes, and build custom HR solutions, enhancing workforce management and streamlining HR-related tasks.",
    },
    {
      value: "Kone Elevators",
      name: "Kone Elevators",
      category: "integration",
      description:
        "Kone Elevators Integration APIs are a set of powerful tools designed to seamlessly incorporate Kone elevator systems into various applications and smart building solutions. These APIs allow developers to integrate and control Kone elevators, enabling real-time monitoring, status updates, and remote management of elevator functions. They provide a secure and efficient means to enhance user experiences, optimize building operations, and improve accessibility.",
    },
    {
      value: "Mitsubishi Elevators",
      name: "Mitsubishi Elevators",
      category: "integration",
      description:
        "Mitsubishi elevators integration APIs allow building management systems (BMSs) to communicate with Mitsubishi elevators. This allows the BMS to monitor and control the elevators, such as calling elevators to specific floors, sending elevators to maintenance mode, and receiving alerts about elevator problems.",
    },
    {
      value: "Oracle Cerner",
      name: "Oracle Cerner",
      category: "integration",
      description:
        "Oracle Cerner Integration API connects Oracle's healthcare solutions with Cerner's electronic health records (EHR) system. It allows data exchange, patient records access, and healthcare workflow management. By integrating Oracle and Cerner, healthcare providers can streamline EHR processes, enhance patient care, and improve interoperability between healthcare systems and applications.",
    },
    {
      value: "UI Path",
      name: "UI Path",
      category: "integration",
      description:
        "UI Path Integration API empowers businesses to seamlessly integrate robotic process automation (RPA) capabilities into their applications. It enables developers to automate repetitive tasks, streamline workflows, and enhance operational efficiency. By integrating UI Path's API, organizations can create intelligent automation solutions, reducing manual effort and improving accuracy, leading to increased productivity and cost savings.",
    },
    {
      value: "Automation Anywhere",
      name: "Automation Anywhere",
      category: "integration",
      description:
        "Automation Anywhere's Integration API is a versatile interface that allows businesses to seamlessly connect their software systems and automate various tasks. It enables the integration of Automation Anywhere's robotic process automation capabilities with other applications, facilitating efficient data exchange and process automation.",
    },
    {
      value: "Schindler",
      name: "Schindler",
      category: "integration",
      description:
        "Schindler Integration API enables integration with Schindler's elevator and escalator systems, connecting them to third-party applications. It offers access to elevator and escalator data, maintenance information, and operational control. This integration enhances building management, streamlines maintenance processes, and improves elevator and escalator system performance for various industries.",
    },
    {
      value: "Otis Elevator",
      name: "Otis Elevator",
      category: "integration",
      description:
        "Otis Elevator Integration APIs provide a powerful and flexible way to connect Otis elevators with a wide range of building systems and applications. By exposing real-time elevator data and functionality through a standardized interface, these APIs enable developers to create innovative solutions that enhance building operations, improve occupant experiences, and optimize elevator performance.",
    },
    {
      value: "HID Signo Reader",
      name: "HID Signo Reader",
      category: "integration",
      description:
        "The HID Signo Reader Integration API allows for the smooth integration of HID Signo readers into various applications. It enables secure access control, authentication, and data retrieval from these readers. Developers can use this API to enhance physical security, identity verification, and access control within their software solutions",
    },
    {
      value: "HID Omnikey Reader",
      name: "HID Omnikey Reader",
      category: "integration",
      description:
        "The HID Omnikey Reader Integration API facilitates seamless integration of HID Omnikey smart card readers into various applications. It enables secure access to smart card data, card authentication, and encryption features. This API is essential for enhancing physical access control, identity verification, and secure card-based authentication within software solutions.",
    },
    {
      value: "Oracle E-Business Suite",
      name: "Oracle E-Business Suite",
      category: "integration",
      description:
        "The Oracle E-Business Suite Integration API connects Oracle's suite of enterprise software applications with external systems. It enables data exchange, process automation, and business operations integration. By integrating with Oracle E-Business Suite, businesses can optimize financial, HR, and supply chain operations, improving efficiency and streamlining their enterprise-wide processes",
    },
    {
      value: "Upkeep",
      name: "Upkeep",
      category: "integration",
      description:
        "UpKeep Integration API enables seamless integration of maintenance and asset management functionalities into various applications. It allows developers to automate work orders, track assets, and manage maintenance tasks efficiently. By integrating this API, businesses optimize maintenance processes, reduce downtime, and enhance overall operational efficiency, ensuring a smooth and reliable workflow.",
    },
    {
      value: "Maximo by IBM",
      name: "Maximo by IBM",
      category: "integration",
      description:
        "Maximo by IBM Integration API connects IBM's enterprise asset management system, Maximo, with external software applications. It enables data synchronization, work order management, and asset tracking. Developers can integrate Maximo to streamline maintenance and asset management, improve operational efficiency, and enhance asset performance and tracking across various industries.",
    },
    {
      value: "Data Source",
      name: "Data Source",
      category: "integration",
      description:
        "A Data Source Integration API is a software interface that enables the seamless connection and interaction between different data sources, such as databases, web services, or cloud platforms. It empowers developers to access, manipulate, and transfer data across various systems, facilitating data synchronization and enhancing data-driven applications.",
    },
    {
      value: "JelloSDK",
      name: "JelloSDK",
      category: "integration",
      description:
        "JelloSDK integration APIs offer a robust and flexible framework for seamless integration of Jello, a cutting-edge AI and data analytics platform. These APIs empower developers to effortlessly incorporate Jello's advanced features into their applications, enabling real-time data analysis, natural language processing, and predictive insights. ",
    },
    {
      value: "Cisco",
      name: "Cisco",
      category: "integration",
      description:
        "The Cisco API ecosystem encompasses a wide range of application programming interfaces (APIs) offered by Cisco Systems. These APIs enable developers to integrate and interact with Cisco's networking, security, collaboration, and cloud solutions. They empower developers to build, automate, and extend Cisco technologies, enhancing network management, communication, and security within their applications.",
    },
    {
      value: "Genesys",
      name: "Genesys",
      category: "integration",
      description:
        "The Genesys Integration API is a software interface designed to connect Genesys customer experience and contact center solutions with other applications. It enables developers to integrate customer data, communication channels, and contact center capabilities, allowing for tailored solutions that enhance customer interactions, streamline support processes, and improve overall service efficiency.",
    },
    {
      value: "VOXTRON",
      name: "VOXTRON",
      category: "integration",
      description:
        "VOXTRON Integration APIs provide a comprehensive set of tools for connecting VOXTRON's omnichannel contact center solutions with a wide range of external systems and applications. Built on RESTful principles, these APIs offer granular access to VOXTRON's data and functionality, enabling developers to create seamless integrations that meet the unique needs of their organization.",
    },
    {
      value: "Dialpad",
      name: "Dialpad",
      category: "integration",
      description:
        "The Dialpad Integration API is a software tool that facilitates the integration of Dialpad's communication and collaboration services into third-party applications. Developers can leverage this API to embed voice, video, messaging, and call management features, enhancing real-time communication capabilities and creating custom solutions for improved business communication and productivity.",
    },
    {
      value: "Google Maps",
      name: "Google Maps",
      category: "integration",
      description:
        "The Google Maps Integration API facilitates the integration of Google Maps' location-based services into various applications. It enables developers to embed maps, geolocation, and routing features, enhancing user experiences with accurate navigation, location-based information, and geospatial data visualization, making it a valuable tool for diverse location-aware applications and services",
    },
    // { value: 'Google Marketplace', name: 'Google Marketplace',category: 'integration',  description: "" },
    {
      value: "Google Places API",
      name: "Google Places API",
      category: "integration",
      description:
        "The Google Places API Integration API enables developers to integrate Google Places data, including information about businesses, locations, and geographical features, into their applications. It offers location-based search, details, and autocomplete capabilities, enhancing location-aware services, local search, and mapping functionality for various software solutions and platforms.",
    },
    {
      value: "Klook",
      name: "Klook",
      category: "dataSources",
      description:
        "The Klook Integration API enables the integration of Klook's travel booking and experiences platform into third-party applications. It provides access to a wide range of travel activities and booking services. Developers can use this API to enhance travel and tourism apps, offering users a diverse selection of experiences and seamless booking capabilities.",
    },
    {
      value: "OpenTable",
      name: "OpenTable",
      category: "dataSources",
      description:
        "The OpenTable Integration API connects OpenTable's restaurant reservation and guest management system with third-party applications. It offers features for booking tables, managing reservations, and accessing restaurant data. By integrating with OpenTable, developers can enhance dining experiences and streamline restaurant management, making reservations and guest services more efficient.",
    },
    {
      value: "Skyscanner",
      name: "Skyscanner",
      category: "dataSources",
      description:
        "Skyscanner Integration API empowers travel platforms with real-time flight, hotel, and car rental data. Developers can seamlessly integrate Skyscanner's vast travel database into their applications, enabling users to search, compare, and book flights and accommodations globally. It ensures a streamlined and personalized travel booking experience, enhancing user satisfaction and driving business growth.",
    },
    {
      value: "Yelp",
      name: "Yelp",
      category: "dataSources",
      description:
        "Yelp Integration API enables businesses to seamlessly incorporate Yelp's rich local business data into their applications. Developers can access reviews, ratings, photos, and business information. By integrating this API, companies enhance user experience, provide valuable insights to customers, and optimize decision-making, fostering trust and engagement between businesses and consumers",
    },
    {
      value: "Google My Business",
      name: "Google My Business",
      category: "integration",
      description:
        "The Google My Business Integration API connects third-party applications with Google My Business, enabling access to business listing information, reviews, and other location-related data. Developers can use this API to enhance online visibility, reputation management, and streamline local business information across different platforms, ultimately improving the customer experience and search engine performance.",
    },
    {
      value: "Ticketmaster",
      name: "Ticketmaster",
      category: "dataSources",
      description:
        "Ticketmaster Integration API empowers developers to seamlessly integrate Ticketmaster's event ticketing services into their platforms. It enables real-time access to event listings, ticket availability, and purchases. By integrating this API, businesses can provide users with up-to-date event information and a streamlined ticket booking experience, enhancing customer engagement and satisfaction.",
    },
    {
      value: "Tripadvisor",
      name: "Tripadvisor",
      category: "dataSources",
      description:
        "Tripadvisor Integration API enables businesses to integrate Tripadvisor's vast travel data directly into their applications. Developers can access hotel, restaurant, and attraction information, including reviews and ratings. By integrating this API, companies can enhance their travel-related services, offering users valuable insights for informed decision-making, ultimately improving customer experience and satisfaction.",
      documentation: `<p>Increase your user experience, engagement, and conversion with Tripadvisor's globally recognized and highly trusted content. Our partner API provides you with dynamic access to Tripadvisor content, enabling seamless integration with your website and applications. Locations are defined within this API as hotels, restaurants or attractions.</p><a class="font-weight-bold" href="https://tripadvisor-content-api.readme.io/reference/overview" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://tripadvisor-content-api.readme.io/reference/overview<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Viator",
      name: "Viator",
      category: "dataSources",
      description:
        "Viator Integration API allows seamless integration of Viator's vast selection of tours, activities, and attractions into third-party platforms. Developers can access detailed tour information, pricing, and availability, enhancing user experience by providing comprehensive travel options. Integrating Viator API offers businesses a wide array of travel offerings, optimizing customer satisfaction and engagement.",
      documentation: `<p>The Viator Partner API v2 comprises a set of endpoints that can support the operation of a fully-featured tours and experiences booking website or application; or, it can be integrated with your existing travel-booking software.</p><a class="font-weight-bold" href="https://docs.viator.com/partner-api/technical/" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://docs.viator.com/partner-api/technical/<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "GetYourGuide",
      name: "GetYourGuide",
      category: "dataSources",
      description:
        "GetYourGuide Integration API allows developers to seamlessly integrate the GetYourGuide travel and activity booking platform into their applications. It provides access to a wide range of travel experiences and tours, enabling customization, booking, and itinerary management. This API enhances travel and tourism apps, offering users diverse activity options and seamless booking.",
    },
    {
      value: "Hugging Face",
      name: "Hugging Face",
      category: "dataSources",
      description:
        "Hugging Face is dedicated to advancing and democratizing AI through open source and open science. It's allows you to host and collaborate on unlimited models, datasets, and applications.",
    },
    {
      value: "Kaggle",
      name: "Kaggle",
      category: "dataSources",
      description:
        "Kaggle is the world's largest data science community with powerful tools and resources to help you achieve your data science goals.",
    },
    {
      value: "Mondee Marketplace",
      name: "Mondee Marketplace",
      category: "integration",
      description:
        "Mondee marketplace integration APIs allow merchants to integrate their online stores with Mondee's marketplace. This allows merchants to reach a wider audience of potential customers and to sell their products on a variety of platforms. The Mondee marketplace integration APIs are a valuable tool for merchants who want to reach a wider audience of potential customers and to sell their products on a variety of platforms.",
    },
    {
      value: "Walmart",
      name: "Walmart",
      category: "eCommerce",
      description:
        "Walmart Integration API enables seamless connection between Walmart's e-commerce platform and external applications. It allows developers to manage product listings, process orders, and handle inventory data efficiently. By integrating this API, businesses can automate various tasks, ensuring a smooth experience for customers, optimizing sales, and enhancing overall operational efficiency within the Walmart ecosystem.",
    },
    {
      value: "TickPick",
      name: "TickPick",
      category: "dataSources",
      description:
        "TickPick is an online marketplace for purchasing and selling event tickets, providing a transparent platform with no hidden fees and allowing users to buy tickets at fair market prices.",
    },
    {
      value: "Target",
      name: "Target",
      category: "eCommerce",
      description:
        "Target is a large retail corporation in the United States, offering a wide range of products, including clothing, electronics, groceries, and home goods, through physical stores and online.",
    },
    {
      value: "Magento",
      name: "Magento",
      category: "eCommerce",
      description:
        "Magento is an open-source e-commerce platform empowering businesses to create scalable, customizable online stores with robust features for marketing, SEO, and extensive customization options.",
    },
    {
      value: "Squarespace",
      name: "Squarespace",
      category: "eCommerce",
      description:
        "Squarespace is a website building platform offering user-friendly tools for creating professional websites, portfolios, blogs, and online stores without extensive technical expertise.",
    },
    {
      value: "BigCommerce",
      name: "BigCommerce",
      category: "eCommerce",
      description:
        "BigCommerce is an e-commerce platform providing scalable solutions for online businesses, enabling creation, management, and growth of digital stores.",
    },
    {
      value: "Airlegit",
      name: "Airlegit",
      category: "integration",
      description:
        "Streamline your travel with automated check-ins and boarding pass integration for a hassle-free experience.",
    },
    {
      value: "Amadeus Discover",
      name: "Amadeus Discover",
      category: "dataSources",
      description:
        "Enhance your journey with a comprehensive suite, offering activities, tickets, events, dining options, transfers, and insights from Yelp.",
    },
    {
      value: "Dreamfolks",
      name: "Dreamfolks",
      category: "integration",
      description:
        "Elevate your airport experience with lounge access, food, spa services, transfers, and shopping amenities.",
    },
    {
      value: "Tevo",
      name: "Tevo",
      category: "dataSources",
      description: "Simplify your plans by securing tickets for events, shows, and movies effortlessly.",
    },
    {
      value: "Splitwise",
      name: "Splitwise",
      category: "integration",
      description:
        "Effectively manage and split expenses, ensuring seamless financial collaboration during your trips.",
    },
    {
      value: "AeroLOPA",
      name: "AeroLOPA",
      category: "integration",
      description:
        "Gain insights into aircraft seat layouts with AeroLOPA, facilitating a comfortable and informed travel experience.",
    },
    {
      value: "Google News, Reuters",
      name: "Google News, Reuters",
      category: "integration",
      description:
        "Stay updated with real-time news, ensuring you are informed about global events during your travels.",
    },
    {
      value: "ShipGo",
      name: "ShipGo",
      category: "integration",
      description:
        "Enjoy the convenience of luggage shipping services, arranging pick-up and delivery for your belongings and outdoor equipment.",
    },
    {
      value: "Cirium",
      name: "Cirium",
      category: "integration",
      description: "Access valuable flight data points for a comprehensive understanding of your travel plans.",
    },
    {
      value: "Travel Boutique",
      name: "Travel Boutique",
      category: "dataSources",
      description: "Explore a wide flights inventory, providing a range of options to suit your travel preferences.",
    },
    {
      value: "Weatherstack",
      name: "Weatherstack",
      category: "integration",
      description: "Stay ahead of weather changes with accurate forecasts, enhancing your overall travel preparedness.",
      documentation: `<a class="font-weight-bold" href="https://weatherstack.com/documentation" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://weatherstack.com/documentation<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Wagtail",
      name: "Wagtail",
      category: "integration",
      description:
        "Manage your content seamlessly with a user-friendly CMS, ensuring efficient communication and updates during your journeys.",
    },
    {
      value: "Entertainment Benefits Group",
      name: "Entertainment Benefits Group",
      category: "dataSources",
      description:
        "Entertainment Benefits Group offers corporate benefits and technology solutions, specializing in entertainment. They provide discounted tickets, travel services, and rewards, enhancing employee perks and customer loyalty. Through strategic partnerships and innovative platforms, they redefine corporate perks, delivering unparalleled value.",
    },
    {
      value: "TBC Hotels",
      name: "TBC Hotels",
      category: "dataSources",
      description:
        "TBC Hotels is a leading provider of hotel accommodation, offering a diverse range of properties worldwide. With a focus on quality service and guest satisfaction, TBC Hotels aims to provide memorable stays for travelers seeking comfort, convenience, and value.",
    },

    {
      value: "Uber",
      name: "Uber",
      category: "dataSources",
      description:
        "Integrating with the Uber API enables developers to seamlessly incorporate Uber's ride-hailing services into their own applications or platforms. With the Uber API, developers gain access to a wide range of functionalities, including requesting rides, estimating fares, tracking trips in real-time, and managing user accounts. By integrating the Uber API, businesses can enhance their services with convenient transportation options, streamline user experiences, and expand their reach to millions of Uber users worldwide. This integration empowers developers to create innovative solutions that leverage Uber's reliable transportation network, ultimately enhancing mobility solutions and providing users with greater convenience and flexibility.",
    },
    {
      value: "Ola",
      name: "Ola",
      category: "dataSources",
      description:
        "Integrating with the Ola API offers developers a gateway to seamlessly incorporate Ola's extensive ride-hailing services into their applications or platforms. Through the Ola API, developers gain access to a spectrum of features including ride booking, fare estimation, real-time trip tracking, and user account management. This integration empowers businesses to augment their offerings with efficient transportation solutions, streamline user experiences, and tap into Ola's vast user base. By leveraging the Ola API, developers can craft innovative solutions that harness Ola's robust transportation network, fostering enhanced mobility solutions and delivering users with greater convenience and accessibility.",
    },
    {
      value: "Redbus",
      name: "Redbus",
      category: "dataSources",
      description:
        "Integrating with the RedBus API enables developers to seamlessly incorporate RedBus' comprehensive bus booking services into their applications or platforms. With the RedBus API, developers gain access to a plethora of functionalities including searching for buses, booking tickets, retrieving booking details, and managing user accounts. This integration empowers businesses to enhance their services with efficient transportation solutions, streamline user experiences, and extend their reach to a vast network of bus operators and travelers. By leveraging the RedBus API, developers can create innovative solutions that leverage RedBus' extensive network, providing users with convenient and reliable bus booking experiences.",
    },
    {
      value: "Variflights",
      name: "Variflights",
      category: "dataSources",
      description: "Tracking & Ancillary information for flights",
    },
    {
      value: "Abhibus",
      name: "Abhibus",
      category: "dataSources",
      description:
        "Integrating with the AbhiBus API offers developers a seamless pathway to incorporate AbhiBus' extensive bus booking services into their applications or platforms. Through the AbhiBus API, developers gain access to a wide array of features including bus searching, ticket booking, retrieving booking details, and managing user accounts. This integration empowers businesses to enrich their services with efficient transportation solutions, streamline user experiences, and tap into AbhiBus' vast network of bus operators and travelers. By leveraging the AbhiBus API, developers can create innovative solutions that harness AbhiBus' robust transportation network, offering users convenient and reliable bus booking experiences tailored to their needs.",
    },
    // {
    //   value: "Searce",
    //   name: "Searce",
    //   category: "dataSources",
    //   description:
    //     "Integrating with the Searce API facilitates developers to seamlessly incorporate Searce's diverse range of services into their applications or platforms. Through the Searce API, developers gain access to an array of functionalities tailored to their needs, including data analytics, cloud consulting, AI solutions, and more. This integration empowers businesses to enhance their services with cutting-edge technologies, streamline workflows, and optimize decision-making processes. By leveraging the Searce API, developers can create innovative solutions that leverage Searce's expertise and resources, enabling businesses to thrive in today's dynamic digital landscape while delivering exceptional value to their clients.",
    // },
    {
      value: "TomTom",
      name: "TomTom",
      category: "dataSources",
      description:
        "Integrating with the TomTom API offers developers a powerful toolkit to seamlessly integrate location-based services into their applications or platforms. Through the TomTom API, developers gain access to a wide range of functionalities including mapping, geocoding, routing, traffic information, and more. This integration empowers businesses to enhance their services with accurate and reliable location data, improve user experiences, and optimize logistical operations. By leveraging the TomTom API, developers can create innovative solutions that leverage advanced mapping technologies, enabling businesses to deliver personalized and efficient location-based services to their users, ultimately driving growth and success in today's competitive landscape.",
    },
    {
      value: "Book My Show",
      name: "Book My Show",
      category: "dataSources",
      description:
        "Integrating with the BookMyShow API allows developers to seamlessly incorporate a wide range of entertainment ticketing services into their applications or platforms. Through the BookMyShow API, developers gain access to features such as event discovery, ticket booking, seat selection, and payment processing. This integration empowers businesses to enhance their services with comprehensive entertainment solutions, streamline ticketing processes, and offer users a seamless booking experience for movies, events, concerts, and more. By leveraging the BookMyShow API, developers can create innovative solutions that provide users with convenient access to entertainment options, while also enabling businesses to expand their reach and drive engagement in the entertainment industry.",
    },
    {
      value: "Eatapp",
      name: "Eatapp",
      category: "dataSources",
      description:
        "Integrating with the EatApp API provides developers with a seamless way to incorporate restaurant reservation and management services into their applications or platforms. Through the EatApp API, developers gain access to a range of functionalities including table booking, waitlist management, guest tracking, and more. This integration empowers businesses to enhance their services with efficient restaurant solutions, streamline reservation processes, and improve guest experiences. By leveraging the EatApp API, developers can create innovative solutions that offer users convenient access to restaurant reservations, while also enabling restaurants to optimize their operations and drive customer satisfaction in the competitive hospitality industry.",
    },
    {
      value: "G Adventures",
      name: "G Adventures",
      category: "dataSources",
      description:
        "Integrating with the G Adventures API offers developers a seamless pathway to incorporate a diverse range of travel experiences into their applications or platforms. Through the G Adventures API, developers gain access to a vast array of travel itineraries, destination information, tour availability, and booking capabilities. This integration empowers businesses to enrich their services with unique travel experiences, streamline booking processes, and provide users with personalized travel solutions. By leveraging the G Adventures API, developers can create innovative solutions that offer users convenient access to authentic and sustainable travel experiences, while also enabling businesses to expand their reach and cater to the growing demand for adventure travel.",
    },
    {
      value: "Omega",
      name: "Omega",
      category: "dataSources",
      description:
        "Integrating with the Omega Tours and Travels API offers developers a seamless pathway to incorporate a comprehensive range of travel services into their applications or platforms. Through the Omega Tours and Travels API, developers gain access to features such as flight booking, hotel reservations, car rentals, tour packages, and more. This integration empowers businesses to enhance their services with efficient travel solutions, streamline booking processes, and provide users with a convenient and personalized travel experience. By leveraging the Omega Tours and Travels API, developers can create innovative solutions that cater to the diverse needs of travelers, while also enabling businesses to expand their offerings and compete effectively in the travel industry.",
    },
    {
      value: "Open Menu",
      name: "Open Menu",
      category: "dataSources",
      description:
        "Integrating with the Open Menu API provides developers with a seamless way to incorporate restaurant menu data into their applications or platforms. Through the Open Menu API, developers gain access to a vast array of restaurant menus, including item descriptions, prices, dietary information, and more. This integration empowers businesses to enhance their services with comprehensive menu data, streamline menu display processes, and provide users with an informative and engaging dining experience. By leveraging the Open Menu API, developers can create innovative solutions that offer users convenient access to restaurant menus, while also enabling restaurants to optimize their menu presentation and attract more customers in the competitive hospitality industry.",
    },
    {
      value: "Restaurant Guru",
      name: "Restaurant Guru",
      category: "dataSources",
      description:
        "Integrating with the Restaurant Guru API provides developers with a seamless way to access a comprehensive database of restaurant information and reviews for use in their applications or platforms. Through the Restaurant Guru API, developers can retrieve details such as restaurant names, addresses, cuisines, ratings, reviews, and more. This integration empowers businesses to enhance their services with rich restaurant data, improve user experiences, and offer valuable insights to their customers. By leveraging the Restaurant Guru API, developers can create innovative solutions that help users discover new dining experiences, make informed decisions, and enjoy exceptional culinary adventures tailored to their preferences and tastes.",
    },
    {
      value: "Resy",
      name: "Resy",
      category: "dataSources",
      description:
        "Integrating with the Resy API offers developers a seamless pathway to incorporate advanced restaurant reservation and management services into their applications or platforms. Through the Resy API, developers gain access to features such as table booking, waitlist management, guest tracking, and more. This integration empowers businesses to enhance their services with efficient restaurant solutions, streamline reservation processes, and provide users with a seamless dining experience. By leveraging the Resy API, developers can create innovative solutions that offer users convenient access to restaurant reservations, while also enabling restaurants to optimize their operations and deliver exceptional hospitality in the competitive restaurant industry.",
    },
    {
      value: "Seven Rooms",
      name: "Seven Rooms",
      category: "dataSources",
      description:
        "Integrating with the SevenRooms API provides developers with a seamless way to incorporate advanced hospitality and guest management services into their applications or platforms. Through the SevenRooms API, developers gain access to a range of functionalities including reservation management, guest profiles, table assignments, CRM capabilities, and more. This integration empowers businesses to enhance their services with personalized guest experiences, streamline operations, and drive customer loyalty. By leveraging the SevenRooms API, developers can create innovative solutions that offer users convenient access to reservation and dining experiences, while also enabling restaurants, hotels, and other hospitality venues to optimize their operations and deliver exceptional service in a competitive industry landscape.",
    },
    {
      value: "Smartvel",
      name: "Smartvel",
      category: "dataSources",
      description:
        "Integrating with the Smartvel API offers developers a seamless pathway to incorporate rich destination content and travel experiences into their applications or platforms. Through the Smartvel API, developers gain access to a wealth of information including events, activities, attractions, weather data, and more for various destinations worldwide. This integration empowers businesses to enhance their services with dynamic travel content, provide personalized recommendations, and offer users an immersive travel planning experience. By leveraging the Smartvel API, developers can create innovative solutions that cater to the diverse needs of travelers, inspire exploration, and facilitate seamless trip planning, ultimately driving engagement and loyalty in the competitive travel industry landscape.",
    },
    {
      value: "Stubhub",
      name: "Stubhub",
      category: "dataSources",
      description:
        "Integrating with the StubHub API provides developers with a seamless pathway to incorporate a wide range of ticketing and event management services into their applications or platforms. Through the StubHub API, developers gain access to features such as event discovery, ticket purchasing, seat selection, event listings, and more. This integration empowers businesses to enhance their services with comprehensive event solutions, streamline ticketing processes, and provide users with convenient access to live entertainment experiences. By leveraging the StubHub API, developers can create innovative solutions that offer users personalized event recommendations, real-time ticket availability, and seamless event attendance, ultimately enhancing user satisfaction and driving engagement in the entertainment industry.",
    },

    {
      value: "Hotstar",
      name: "Hotstar",
      category: "dataSources",
      description:
        "Integrating with the Hotstar API offers developers a seamless pathway to incorporate a diverse range of digital entertainment services into their applications or platforms. Through the Hotstar API, developers gain access to a vast library of content including movies, TV shows, live sports, news, and more. This integration empowers businesses to enhance their services with premium entertainment solutions, provide users with a personalized streaming experience, and offer access to popular content from around the world. By leveraging the Hotstar API, developers can create innovative solutions that cater to the diverse preferences of users, deliver high-quality streaming experiences, and drive engagement in the competitive digital entertainment landscape.",
    },
    {
      value: "Stictravel Group",
      name: "Stictravel Group",
      category: "dataSources",
      description:
        "The Stictravel Group provides comprehensive travel services, including tour packages, hotel bookings, flight reservations, transportation, and more. Integrating with the Stictravel Group API offers developers a seamless way to incorporate these travel services into their applications or platforms. Through the Stictravel Group API, developers gain access to a wide array of functionalities, including itinerary planning, booking management, destination information, and customer support. This integration empowers businesses to enhance their travel offerings, streamline booking processes, and provide users with convenient access to a diverse range of travel options. By leveraging the Stictravel Group API, developers can create innovative solutions that cater to the unique needs of travelers, deliver personalized experiences, and drive growth in the competitive travel industry.",
    },
    {
      value: "Gupshup",
      name: "Gupshup",
      category: "integration",
      description:
        "Integrating with the Gupshup API provides developers with a seamless way to incorporate messaging and conversational experiences into their applications or platforms. Through the Gupshup API, developers gain access to a wide range of functionalities including SMS, WhatsApp, RCS, Facebook Messenger, and more. This integration empowers businesses to enhance their communication channels, engage with users in real-time, and automate interactions through chatbots and AI. By leveraging the Gupshup API, developers can create innovative solutions that offer users personalized messaging experiences, streamline customer support, and drive engagement in today's digital landscape.",
    },
    {
      value: "Netcore",
      name: "Netcore",
      category: "integration",
      description:
        "Integrating with the Netcore API offers developers a seamless pathway to incorporate advanced communication and engagement services into their applications or platforms. Through the Netcore API, developers gain access to a comprehensive suite of functionalities including email marketing, SMS campaigns, push notifications, marketing automation, and more. This integration empowers businesses to enhance their customer engagement strategies, personalize communications, and optimize marketing efforts across multiple channels. By leveraging the Netcore API, developers can create innovative solutions that offer users tailored messaging experiences, drive customer acquisition and retention, and deliver measurable results in today's competitive digital marketing landscape.",
    },
    {
      value: "Infobip",
      name: "Infobip",
      category: "integration",
      description:
        "Integrating with the Infobip API provides developers with a powerful toolkit to incorporate advanced communication and messaging services into their applications or platforms. Through the Infobip API, developers gain access to a wide range of functionalities including SMS, voice, email, chat apps, and omnichannel messaging solutions. This integration empowers businesses to enhance their customer engagement strategies, streamline communication processes, and reach users on their preferred channels. By leveraging the Infobip API, developers can create innovative solutions that offer users personalized messaging experiences, improve customer service, and drive growth in today's digital landscape.",
    },
    {
      value: "Abode Worldwide",
      name: "Abode Worldwide",
      category: "dataSources",
      description:
        "Integrating with the Abode Worldwide API offers developers a seamless pathway to incorporate luxury vacation rental services into their applications or platforms. Through the Abode Worldwide API, developers gain access to a curated selection of premium properties, including villas, apartments, and homes, in sought-after destinations around the world. This integration empowers businesses to enhance their travel offerings with high-quality accommodations, streamline booking processes, and provide users with personalized travel experiences. By leveraging the Abode Worldwide API, developers can create innovative solutions that cater to the discerning needs of travelers, deliver exceptional hospitality, and drive engagement in the luxury travel market.",
    },
    {
      value: "Holiday",
      name: "Holiday",
      category: "dataSources",
      description: "List of holidays as per states for all countries around the world.",
      documentation: `<p>Retrieves a list public holidays and observances for countries, states and provinces.</p><a class="font-weight-bold" href="https://holidayapi.com/docs" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://holidayapi.com/docs<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Carbon Emission",
      name: "Carbon Emission",
      category: "integration",
      description: "Information for carbon offset for flights.",
      documentation: `<p>The Travel Impact Model API exposes emission estimates computed according to the Travel Impact Model.</p><a class="font-weight-bold" href="https://developers.google.com/travel/impact-model" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://developers.google.com/travel/impact-model<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Award Wallet",
      name: "Award Wallet",
      category: "integration",
      description: "Provider for parsing travel itineraries on mail",
      documentation: `<p>The AwardWallet Email Parsing API is designed to extract travel reservation information from travel confirmation emails. You can submit any travel confirmation email to the API, including those with itinerary details in an attachment, and it will automatically parse the email and return the reservation data in a structured JSON format.</p><a class="font-weight-bold" href="https://awardwallet.com/api" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://awardwallet.com/api<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Fluxir",
      name: "Fluxir",
      category: "dataSources",
      description: "Online Visa processor",
      documentation: `<p>Online Visa process API</p><a class="font-weight-bold" href="https://api.test.fluxir.com/" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://api.test.fluxir.com/<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Airportzo",
      name: "Airportzo",
      category: "integration",
      description:
        "AirportZo aggregates A to Z of airport experiences like Meet & Assist, Lounge, Porter, Airport Transfers, Airport Spa, Sleep pods, and many more services on a single digital platform",
      documentation: `<p>Step 01: Choose Basic Auth</p><p>Step 02: Put specific User name and Password</p><p>Step 03: Create a variable in the environment</p><p>Step 04: The variable should be based endpoint</p><p>Step 05: Value = <a class="font-weight-bold" href="https://airportzo.net.in/ext-services/" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://airportzo.net.in/ext-services/<i class="fa fa-external-link ml-1"></i></a></p>`,
    },
    {
      value: "Way",
      name: "Way",
      category: "integration",
      description:
        "Way is a car super-app designed to provide members with peace of mind regarding car-related matters Auto related service, Parking and Experience airport parking.",
      documentation: `<a class="font-weight-bold" href="https://doc.clickup.com/9017059410/d/h/8cqau2j-24277/4b63ad0f6c97665" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://doc.clickup.com/9017059410/d/h/8cqau2j-24277/4b63ad0f6c97665<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Asego",
      name: "Asego",
      category: "integration",
      description:
        "Provides travel assistance services with Insurance underwritten by an IRDA authorised insurance company",
    },
    {
      value: "iWay",
      name: "iWay",
      category: "integration",
      description: "Provider for point A to Point B chaufer driven Transfers",
      documentation: `<p>API Swagger can be accessed by</p><a class="font-weight-bold" href="https://api.test.fluxir.com/" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://api.test.fluxir.com/<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "TUI",
      name: "TUI",
      category: "integration",
      description:
        "Musement is an online platform for activities, tours, museums, shows and art events. Musement aggregates third-party activities and tickets for users to book online and provides an API integration to sell activities from the Musement/TUI  catalog",
      documentation: `<a class="font-weight-bold" href="https://partner-api.musement.com/api/getting-started/" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://partner-api.musement.com/api/getting-started/<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "FinityFun",
      name: "FinityFun",
      category: "integration",
      description: "Gateway to discover and purchase a wide range of tickets and vouchers",
    },

    {
      value: "Trip Planning",
      name: "Trip Planning",
      category: "service",
      description:
        "Trip planning services assist in organizing travel itineraries, including accommodations, activities, and logistics, ensuring a convenient and tailored travel experience for individuals or groups.",
      createdBy: "Mondee",
    },
    {
      value: "XHub Services",
      name: "XHub Services",
      category: "service",
      description:
        "Mondee Xperience Hub curates and distributes trip experiences crafted by industry experts. Travelers can access meticulously designed itineraries, insider tips, and unique insights to make their journeys truly extraordinary. This innovative hub serves as a dynamic channel for the distribution of expert and AI-generated trip experiences, while also offering personalized recommendations based on user profiles.",
      createdBy: "Mondee",
    },
    {
      value: "Rewards Loyalty Points",
      name: "Rewards Loyalty Points",
      category: "service",
      description:
        "Rewards Loyalty Points service offers incentives, rewarding customer loyalty through accumulated points, providing perks, discounts, or exclusive benefits within a particular program or business.",
      createdBy: "Quantum Nexus",
    },
    {
      value: "Booking",
      name: "Booking",
      category: "service",
      description:
        "Booking services facilitate reservations for accommodations, activities, transportation, and events, simplifying the process for users to secure desired options conveniently.",
      createdBy: "Mondee",
    },
    {
      value: "Cruise",
      name: "Cruise",
      category: "service",
      description:
        "Cruise services offer voyages on luxurious ships, providing travel, accommodation, entertainment, and leisure experiences, exploring diverse destinations for passengers' enjoyment and relaxation.",
      createdBy: "NovaTech",
    },
    {
      value: "Appointment",
      name: "Appointment",
      category: "service",
      description:
        "Appointment services enable scheduling, managing, and confirming meetings or events, optimizing organization, time management, and facilitating seamless interactions for businesses or individuals.",
      createdBy: "Purplegrids",
    },
    {
      value: "Passport",
      name: "Passport",
      category: "service",
      description:
        "Passport services assist in issuing, renewing, and processing passports, ensuring legal travel documents for international journeys, contributing to global mobility and identification verification.",
      createdBy: "ApexStream",
    },
    {
      value: "Currency Exchange",
      name: "Currency Exchange",
      category: "service",
      description:
        "Currency exchange services provide conversions between different currencies, facilitating international transactions, travel, and enabling the exchange of foreign currencies for local use.",
      createdBy: "PixelPulse",
    },
    {
      value: "Travel Documents Service",
      name: "Travel Documents Service",
      category: "service",
      description:
        "Travel Documents service aids in obtaining passports, visas, and other necessary paperwork, streamlining processes for smooth international travel and compliance with destination requirements.",
      createdBy: "HyperTech",
    },
    {
      value: "BOPIS",
      name: "BOPIS",
      category: "service",
      description:
        "BOPIS, Buy Online Pick Up In Store, allows customers to purchase items online and retrieve them at a physical store, offering convenience and faster transactions.",
      createdBy: "VectorBeam",
    },
    {
      value: "Delivery Service",
      name: "Delivery Service",
      category: "service",
      description:
        "Delivery services transport goods from vendors to customers, offering convenience, speed, and reliability in the transportation of products to specified locations.",
      createdBy: "LogicNest",
    },
    {
      value: "Price Match",
      name: "Price Match",
      category: "service",
      description:
        "Price Match service ensures customers receive the best price by matching or beating competitors' prices for the same product, fostering customer loyalty and satisfaction.",
      createdBy: "TechVista",
    },
    {
      value: "Verify ID Services",
      name: "Verify ID Services",
      category: "service",
      description:
        "Verify ID Services authenticate and confirm identities using advanced methods, ensuring security and trust through verification processes in various industries and transactions.",
      createdBy: "Cyberventure",
    },
    {
      value: "ID Scanning Services",
      name: "ID Scanning Services",
      category: "service",
      description:
        "ID scanning services use technology to verify and extract information from identification documents, enhancing security, age verification, and data collection in various industries and settings.",
      createdBy: "InnoSphere",
    },
    {
      value: "Mondee AI Services",
      name: "Mondee AI Services",
      category: "service",
      description:
        "Offers state-of-the-art AI-driven functionalities including chatbots, predictive analytics, User Segmentation and recommendation engines. Enables automation and intelligent decision-making, optimizing user journeys.",
      createdBy: "Mondee",
    },
    {
      value: "Trip Management Services",
      name: "Trip Management Services",
      category: "service",
      description:
        "Enables seamless integration of global travel inventories, providing users access to flights, hotels, and other travel amenities. Provides seamless abilities for end to end trip management and trip collaboration.",
      createdBy: "Mondee",
    },
    {
      value: "Travel Shopping Services",
      name: "Travel Shopping Services",
      category: "service",
      description: "Enables Shopping of Travel Ancillary and accessories.",
      createdBy: "Mondee",
    },
    {
      value: "Engagement Services",
      name: "Engagement Services",
      category: "service",
      description:
        "Enhances user interactions through personalized Contextual Travel Conversation, content delivery  and dynamic contextual messaging.",
      createdBy: "Mondee",
    },
    {
      value: "Fintech Services",
      name: "Fintech Services",
      category: "service",
      description:
        "Empowers seamless travel transactions with advanced financial tools. Provides users with diverse payment options, ensuring speed and security.",
      createdBy: "Mondee",
    },
    {
      value: "Travel Booking Services",
      name: "Travel Booking Services",
      category: "service",
      description:
        "Provides ability to book travel products - Flights, Hotels, Cars, Cruises etc and manage, track booking status.",
      createdBy: "Mondee",
    },
    {
      value: "Microsoft Fabric",
      name: "Microsoft Fabric",
      category: "integration",
      description:
        "Microsoft Fabric is a unified analytics platform that integrates various data and AI services, enabling seamless data management, analysis, and business intelligence. It brings together data engineering, data warehousing, data science, and real-time analytics in a single, end-to-end solution.",
    },
    {
      value: "Amadeus Cars and Transfers",
      name: "Amadeus Cars and Transfers",
      category: "integration",
      description:
        "Amadeus Cars and Transfers APIs offer comprehensive capabilities to streamline the process of booking and managing transfers during your travelers' trips. These APIs help you simplify transportation arrangements and deliver a seamless, efficient experience to your customers.",
      documentation: `<a class="font-weight-bold" href="https://developers.amadeus.com/self-service/category/cars-and-transfers" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://developers.amadeus.com/self-service/category/cars-and-transfers<i class="fa fa-external-link ml-1"></i></a>`,
    },
    {
      value: "Mozio",
      name: "Mozio",
      category: "integration",
      description:
        "Mozio is a comprehensive travel solutions provider that offers global airport transfer and ground transportation services. It integrates various transport options into a single platform, allowing travelers to easily book rides and transfers to and from airports worldwide.",
      documentation: `<a class="font-weight-bold" href="https://test.travel.api.amadeus.com" target="_blank" style="white-space: pre-line; word-break: break-all; line-height: 18px;">https://test.travel.api.amadeus.com<i class="fa fa-external-link ml-1"></i></a>`,
    },
  ];
  if (value) {
    let obj = list.find(x => x.value === value);
    if (!obj) return { value: value, name: value };
    return obj;
  }
  return list;
};

export const merchantIdListByBusinessType = type => {
  let merchantArr = [
    { businessType: "Retail", value: 101 },
    { businessType: "Healthcare", value: 102 },
    { businessType: "Education", value: 103 },
    { businessType: "Banking", value: 104 },
    { businessType: "Insurance", value: 105 },
    { businessType: "Service Desk", value: 106 },
    { businessType: "RealEstate", value: 107 },
    { businessType: "Enterprise", value: 108 },
  ];
  let obj = merchantArr.find((x, i) => x.businessType == type);
  return obj ? obj.value : 101;
};

export const dayList = [
  { label: "Monday", value: "Monday" },
  { label: "Tuesday", value: "Tuesday" },
  { label: "Wednesday", value: "Wednesday" },
  { label: "Thursday", value: "Thursday" },
  { label: "Friday", value: "Friday" },
  { label: "Saturday", value: "Saturday" },
  { label: "Sunday", value: "Sunday" },
];
export const getBrowserType = () => {
  let browser;
  if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf("OPR")) != -1) {
    browser = "Opera";
  } else if (navigator.userAgent.indexOf("Edge") != -1) {
    browser = "Edge";
  } else if (navigator.userAgent.indexOf("Chrome") != -1) {
    browser = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    browser = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    browser = "Firefox";
  } else if (navigator.userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true) {
    //IF IE > 10
    browser = "IE";
  } else {
    browser = "unknown";
  }

  return browser;
};
export const getDeviceName = () => {
  if (navigator.userAgent.match(/mobile/i)) {
    return "Mobile";
  } else if (navigator.userAgent.match(/iPad|Android|Touch/i)) {
    return "Tablet";
  } else {
    return "Desktop";
  }
};
export const getTimeZone = () => {
  let guess = moment.tz.guess();
  let timestamp = new Date();
  let zone = moment.tz.zone(guess).abbr(timestamp);
  return zone;
};
export const paymentOptionList = [
  { label: "Paypal", value: "paypal" },
  { label: "Paytm", value: "paytm" },
  { label: "Braintree", value: "braintree" },
  { label: "Stripe", value: "stripe" },
  { label: "Razorpay", value: "razorpay" },
  { label: "NoqoodyPay", value: "noqoody" },
  { label: "Hyperpay", value: "hyperpay" },
];
export const statusList = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];
export const roleStatusList = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
];
export const userStatusList = [
  { value: "NEW", label: "NEW" },
  { value: "PROSPECT", label: "PROSPECT" },
  { value: "MQL", label: "MQL" },
  { value: "SQL", label: "SQL" },
  { value: "CUSTOMER", label: "CUSTOMER" },
  { value: "DNQ", label: "DNQ" },
];
export const leadTypeList = [
  { value: "NEW", label: "NEW" },
  { value: "RETURN", label: "RETURN" },
];
export const periodList = (isSix = false) => {
  return [
    { value: 1, label: "1 Month" },
    { value: 3, label: "3 Months" },
    ...(isSix ? [{ value: 6, label: "6 Months" }] : []),
  ];
};

export const currencyList = [
  { value: "AED", label: "AED" },
  { value: "AUD", label: "AUD" }, // AUS Dollar
  { value: "EUR", label: "EUR" }, // Euro
  { value: "CRC", label: "CRC" }, // Costa Rican Colón
  { value: "ETB", label: "ETB" }, // Ethiopian Birr
  { value: "GBP", label: "GBP" }, // British Pound Sterli
  { value: "ILS", label: "ILS" }, // Israeli New Sheqel
  { value: "INR", label: "INR" }, // Indian Rupee
  { value: "JPY", label: "JPY" }, // Japanese Yen
  { value: "KRW", label: "KRW" }, // South Korean Won
  { value: "NGN", label: "NGN" }, // Nigerian Naira
  { value: "PHP", label: "PHP" }, // Philippine Peso
  { value: "PLN", label: "PLN" }, // Polish Zloty
  { value: "PYG", label: "PYG" }, // Paraguayan Guarani
  { value: "THB", label: "THB" }, // Thai Baht
  { value: "UAH", label: "UAH" }, // Ukrainian Hryvnia
  { value: "USD", label: "USD" }, // US Dollar
  { value: "VND", label: "VND" }, // Vietnamese Dong
  { value: "SAR", label: "SAR" }, // Saudi Riyal
];
export const businessTypeList = [
  { value: "Auto Dealership", name: "Auto Dealership" },
  { value: "E-Commerce", name: "E-Commerce" },
  { value: "Healthcare", name: "Healthcare" },
  { value: "Insurances", name: "Insurances" },
  { value: "RealEstate", name: "RealEstate" },
  { value: "Restaurant", name: "Restaurant" },
  { value: "Retail", name: "Retail" },
  { value: "Enterprise", name: "Enterprise" },
  { value: "Lifestyle", name: "Lifestyle" },
  { value: "Education", name: "Education" },
  { value: "Service Desk", name: "Service Desk" },
  { value: "Banking", name: "Banking" },
  { value: "Telecom", name: "Telecom" },
];
export const widgetPositionList = [
  { title: "Bottom Right", value: "bottom-right", className: "br" },
  { title: "Bottom Left", value: "bottom-left", className: "bl" },
  { title: "Top Left", value: "top-left", className: "tl" },
  { title: "Top Right", value: "top-right", className: "tr" },
];
export const convertDate = (date, format = "lll", inputDtFormat = "MM-DD-YYYY HH:mm:ss") => {
  if (!date) return "";
  const dt = inputDtFormat ? moment(date, inputDtFormat) : moment(date);
  if (!dt.isValid()) return date;
  return dt.format(format);
};
export const convertUTCtoLocal = (date, format = "lll", inputDtFormat) => {
  if (!date) return "";
  const dt = inputDtFormat ? moment.utc(date, inputDtFormat) : moment.utc(date);
  if (!dt.isValid()) return date;
  const utcDate = dt.format(); //is used to convert to consider input as UTC if timezone offset is not passed
  return moment(utcDate).format(format);
};
export const convertUTCtoMerchant = (date, format = "lll", inputDtFormat) => {
  if (!date) return "";
  const dt = inputDtFormat ? moment.utc(date, inputDtFormat) : moment.utc(date);
  if (!dt.isValid()) return date;

  const timeZone = sessionTimeZone();
  return dt.tz(timeZone).format(format);
};
export const convertlocaltoUTC = (date, format = "lll", inputDtFormat) => {
  if (!date) return "";
  return moment.utc(inputDtFormat ? moment(date, inputDtFormat) : moment(date)).format(format);
};
export const convertSecToMin = seconds => {
  if (!seconds) return "0s";

  let duration = seconds;
  let hours = duration / 3600;
  duration = duration % 3600;

  let min = parseInt(duration / 60);
  duration = duration % 60;

  let sec = parseInt(duration);

  // if (sec > 30) {
  //   min = min + 1;
  // } else {
  //   sec = 0
  // }
  if (min >= 60) {
    hours = hours + 1;
    min = 0;
  }
  // if (min < 10) {
  //   min = `0${min}`;
  // }
  if (parseInt(hours, 10) > 0) return `${parseInt(hours, 10)}h ${min}m ${sec}s`;
  else if (min == 0) return `${sec}s`;
  else return `${min}m ${sec == 0 ? "" : `${sec}s`}`;
};
export const secondsToTime = seconds => {
  const duration = moment.duration(seconds, "seconds");
  return duration.format("DD[d] HH[h] mm[m] ss[s]");
};
export const messageStatus = {
  PENDING: "Pending",
  REPLIED: "Replied",
  DELETED: "Deleted",
  JUNK: "Junk",
  DRAFT: "Draft",
  ANSWERED: "Answered",
  READ: "Read",
  ASSIGNED: "Assigned",
  COMPLETED: "Completed",
};
export const getRandomColor = () => {
  return `rgb(${Math.floor(Math.random() * 256)},${Math.floor(Math.random() * 256)},${Math.floor(
    Math.random() * 256
  )},0.5)`;
};
export const getRandomColorByName = (name = "") => {
  var tempName = name.replace(/[^a-zA-Z0-9 ]/g, "");

  //removing visitor. from string
  if (name.includes("visitor.")) tempName = name.replace("visitor.", "");

  //removing numbers from string
  tempName = tempName.replace(/[0-9]/g, "") || "v";

  const firstAlphabet = tempName.charAt(0);
  var char = firstAlphabet.toLowerCase();
  const asciiCode = char.charCodeAt(0);

  //number that contains 3 times ascii value of character --unique for every alphabet
  const colorNum = asciiCode.toString() + (asciiCode + 5).toString() + (asciiCode + 10).toString();

  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = (num >> 16) & 255;
  var g = (num >> 8) & 255;
  var b = num & 255;

  return {
    color: "rgb(" + r + ", " + g + ", " + b + ", 0.3)",
    borderColor: "rgb(" + r + ", " + g + ", " + b + ", 0.4)",
    character: firstAlphabet.toUpperCase(),
  };
};
export const dataFilter = (col, row) => {
  let temp = col.map(c => c.key);
  let data = row.map(r => {
    let obj = {};
    Object.keys(r).map(k => {
      obj[k] = r[k];
    });
    return obj;
  });
  return data;
};

export const splitDate = date => {
  let dateTime = date.split(" ");
  let time = dateTime[2] + "/" + dateTime[1] + "/" + dateTime[0] + " " + date.split(" ")[3] + " " + date.split(" ")[4];
  return time;
};

export const templateImgById = id => {
  const image = "/img/Templates/templates_thumbnails";
  const template = [
    { id: 18, image: `${image}/18_small.jpg` },
    { id: 19, image: `${image}/19_small.jpg` },
    { id: 20, image: `${image}/20_small.jpg` },
    { id: 21, image: `${image}/21_small.jpg` },
    { id: 22, image: `${image}/22_small.jpg` },
    { id: 23, image: `${image}/23_small.jpg` },
    { id: 24, image: `${image}/24_small.jpg` },
    { id: 25, image: `${image}/25_small.jpg` },
    { id: 26, image: `${image}/26_small.jpg` },
    { id: 27, image: `${image}/27_small.jpg` },
    { id: 28, image: `${image}/28_small.jpg` },
    { id: 29, image: `${image}/29_small.jpg` },
    { id: 30, image: `${image}/30_small.jpg` },
    { id: 31, image: `${image}/31_small.jpg` },
    { id: 32, image: `${image}/32_small.jpg` },
    { id: 33, image: `${image}/33_small.jpg` },
    { id: 34, image: `${image}/34_small.jpg` },
    { id: 35, image: `${image}/35_small.jpg` },
    { id: 36, image: `${image}/36_small.jpg` },
    { id: "car_rental_landing", image: `${image}/car_rental_landing_small.png` },
    { id: "ecommerce_landing", image: `${image}/ecommerce_landing_small.png` },
  ];
  let obj = template.find(t => t.id == id);
  let img = obj ? obj.image : `${image}/defaultImage.png`;
  return img;
};

export const engagementType = {
  web: "Chatbot Website",
  social: "Chatbot Social Media",
  campaigns: "Smart Campaigns",
  ads: "Ads Platforms",
  messaging: "Chatbot Messenger",
  promotions: "Promotions",
  landingPage: "Conversational Smart Pages",
  voiceBasedAssistant: "Smart Assistant",
};

export const engagementSetupData = (type, engValue = "") => {
  let obj = { name: "", value: "", desc: "", imgDesc: "" };
  const list = [
    {
      name: "Website",
      value: "Chatbot Website",
      desc: "To Preview the Chatbot please click the preview button. Change the Chatbot Flow, Design, Style using the Designer tools.",
      imgDesc:
        "To add this Chatbot to your website please click Source button and copy/paste the code snipped between <head></head> section of your website.",
    },
    {
      name: "Social Media",
      value: "Chatbot Social Media",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: "Smart Campaigns",
      value: "Smart Campaigns",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: `${engValue || "Ads"}`,
      value: "Ads Platforms",
      desc: `Please use the engagment URL for posting your ads in ${engValue || "Ads"} platform. `,
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: "Messaging",
      value: "Chatbot Messenger",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: "Promotions",
      value: "Promotions",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: "Landing Page",
      value: "Conversational Smart Pages",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
    {
      name: "Voice Based Assistant",
      value: "Smart Assistant",
      desc: "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
      imgDesc:
        "Our customers use conversational commerce on a variety of digital messaging channels including SMS, Facebook Messenger, WhatsApp, along with Web",
    },
  ];
  obj = list.find((x, i) => x.value === type);
  return obj ? obj : {};
};

export const engagementAPIType = (type, saveData = false) => {
  const list = [
    { name: "Website", value: "Chatbot Website" },
    { name: "Social Media", value: "Chatbot Social Media" },
    { name: "Email Campaigns", value: "Smart Campaigns" },
    { name: "Ads Platforms", value: "Ads Platforms" },
    { name: "Messaging Platforms", value: "Chatbot Messenger" },
    { name: "Promotions", value: "Promotions" },
    { name: "Landing Page", value: "Conversational Smart Pages" },
    { name: "Voice Based Assistant", value: "Smart Assistant" },
  ];
  let obj = {};
  if (saveData) {
    obj = list.find((x, i) => x.value === type);
    return obj && obj.name ? obj.name : "";
  } else {
    obj = list.find((x, i) => x.name === type);
    return obj && obj.value ? obj.value : "";
  }
};

export const getEngagementTypeDescription = type => {
  const list = [
    {
      key: "Chatbot Website",
      desc: "Design a Website Purplebot in minutes so it can start engaging visitors in your website, answering questions, qualify visitors, grow pipeline and speed up customer resolutions all with Website PurpleBots.",
    },
    {
      key: "Campaigns Social Media",
      desc: "Create and deploy Purplebots in your social media channels to take your brands and increase your customer engagements and improve your social connections with personalization.",
    },
    {
      key: "Smart Email Campaigns",
      desc: "Create and manage smart Campaigns by adding personalization to your contents by tagging a PurpleBot along with campaigns. Anytime your campaigns are clicked the Purplebots respond.",
    },
    {
      key: "Ads Platforms",
      desc: "Create Purplebots to respond to your ads, these are microbots that focus only on a specific product, promotion or an event etc. These are deployed along with your ads across wide platforms.",
    },
    {
      key: "Chatbot Messenger",
      desc:
        "Create and deploy Purplebots for your messaging channels that will address consumer" +
        "s personal and professional needs and interests, so as to provide complete engagement for your brands.",
    },
    {
      key: "Promotions",
      desc: "Enable voice based assistants and personalization, engage multi turn conversation and smart follow ups with adding AI driven assistants Alexa, Cortana and Google Voice.",
    },
    {
      key: "Conversational Smart Pages",
      desc: "With Conversational Smart Pages, you can take potential customers from an ad or campaign directly to a conversation with your bot or team! also You can create Conversational Smart Pages as well.",
    },
    { key: "Smart Assistant", desc: "Greet site visitors and invite them to start a chat with you!" },
  ];
  let obj = list.find((x, i) => x.key === type);
  return obj && obj.desc ? obj.desc : "";
};

export const getPreviewUrl = channelId => {
  let url = "";
  switch (channelId) {
    case 63:
      url = "clbk/Z29vZ2xlcGxheXN0b3Jl/readReview";
      break;
    case 62:
      url = "clbk/Z29vZ2xlYnVzaW5lc3Nz/readReviews";
      break;
    case 64:
      url = "clbk/ZmFjZWJvb2tw/readComments";
      break;
    case 65:
      url = "clbk/aW5zdGFncmFt/readComments";
      break;
    case 53:
      url = "clbk/readEmail";
      break;
    default:
      break;
  }
  return url;
};

export const getLeadStatus = (user_type, step) => {
  const list = [
    { type: "NEW", step: 1 },
    { type: "VISITOR", step: 1 },
    { type: "PROSPECT", step: 2 },
    { type: "MQL", step: 3 },
    { type: "SQL", step: 4 },
    { type: "CUSTOMER", step: 5 },
    { type: "DNQ", step: 6 },
  ];

  if (step) return list.find(a => a.step === step).type;

  const obj = list.find(a => a.type === user_type);
  let currentStep = 1;
  if (obj) currentStep = obj.step;
  return currentStep;
};

export const getCreatedBy = () => {
  const firstName = sessionFirstName();
  const lastName = sessionLastName();
  const userName = sessionUser();
  const createdBy = firstName || lastName ? `${firstName && firstName + " "}${lastName && lastName}` : userName;
  return createdBy;
};

export const getVisitorId = visitor => {
  /**
   * Sample Object
   *
   * {
   *   referenceID,
   *   contacts: {
   *     firstName,
   *     lastName,
   *     phone: {
   *       cell
   *     }
   *   },
   *   engagements: [{
   *     channel
   *   }]
   * }
   *
   */

  let visitorName = "";
  if (!visitor) return visitorName;

  let fName = visitor.contacts && visitor.contacts.firstName;
  let lName = visitor.contacts && visitor.contacts.lastName;

  if (fName || lName) {
    visitorName = `${fName || ""} ${lName || ""}`;
  } else if (
    isArray(visitor.engagements) &&
    visitor.engagements[0].channel === "WhatsApp" &&
    visitor.contacts &&
    visitor.contacts.phone &&
    visitor.contacts.phone.cell
  ) {
    visitorName = visitor.contacts.phone.cell;
  } else if (visitor.referenceID) {
    visitorName = `visitor.${visitor.referenceID.substring(0, 8)}`;
  }
  return visitorName.trim();
};

export const getContactVisitorId = visitor => {
  let visitorName = "";
  if (!visitor) return visitorName;

  let fName = visitor.first_name;
  let lName = visitor.last_name;

  if (fName || lName) {
    visitorName = `${fName || ""} ${lName || ""}`;
  } else if (
    isArray(visitor.engagements) &&
    visitor.engagements[0].channel === "WhatsApp" &&
    visitor.contacts &&
    visitor.contacts.phone &&
    visitor.contacts.phone.cell
  ) {
    visitorName = visitor.contacts.phone.cell;
  } else if (visitor.referenceID) {
    visitorName = `visitor.${visitor.referenceID.substring(0, 8)}`;
  }
  return visitorName.trim();
};

export const getTaskColor = value => {
  switch (value) {
    case "Low":
      return "#21D9A0";
    case "Medium":
      return "#D9B421";
    case "High":
      return "#D92121";
    case "default":
  }
};
export const getVisitorLocation = (visitor, isReverse = false) => {
  if (!visitor) return "";
  let { city, region, country } = visitor;
  let location = (isReverse ? [country, region, city] : [city, region, country]).filter(Boolean).join(", ");
  return location;
};

export const getVisitorAddress = (data, isReverse = false) => {
  let { city, state, country } = data || {};
  let address = (isReverse ? [country, state, city] : [city, state, country]).filter(Boolean).join(", ");
  return address;
};

export const widgetSizeList = [
  { title: "Small", value: "small", className: "sm" },
  { title: "Medium", value: "medium", className: "md" },
  { title: "Large", value: "large", className: "lg" },
];

export const getPrevDate = (dtValue = null, format = "DD/MM/YYYY hh:mm:ss A", params = "days") => {
  if (dtValue) return moment(dtValue).subtract(1, params).format(format);
  else return moment().subtract(1, params).format(format);
};

export const leadStatus = {
  new: { bg: "rgba(253, 203, 110, 0.6)", color: "#444" },
  qualified: { bg: "rgba(0, 184, 148, 0.6)", color: "#444" },
  mql: { bg: "rgba(255, 117, 117, 0.6)", color: "#444" },
  sql: { bg: "rgba(9, 132, 227, 0.6)", color: "#444" },
  dnq: { bg: "rgba(191, 191, 191, 0.6)", color: "#444" },
};

export const getInternationalFormat = (phonenumber, country) => {
  if (country.length > 2) {
    const obj = countrycode.find(
      c => c["name"].toLowerCase() == country.toLowerCase() || c["alpha-3"].toLowerCase() == country.toLowerCase()
    );
    country = obj ? obj["alpha-2"] : country;
  }
  try {
    const tel = phoneUtil.parse(phonenumber, country);
    return phoneUtil.format(tel, PNF.INTERNATIONAL);
  } catch (err) {
    return phonenumber;
  }
};

export const PageType = {
  customLP: "custom-landingPage",
  predefinedLP: "predefined-landingPage",
  customEmail: "custom-email",
  predefinedEmail: "predefined-email",
  customTemplate: "custom-template",
  predefinedTemplate: "predefined-template",
  email: "email",
  predefined: "predefined",
  custom: "custom",
};

export const addScript = url => {
  var script = document.createElement("script");
  script.src = url;
  document.head.appendChild(script);
};

export const stripHTML = str => str.replace(/(<([^>]+)>)/gi, "");

export const transposeBreakLine = str => str.replace(/<br\s*\/?>/g, "\n");

export const encryptionList = [
  { label: "sha128", value: "sha128" },
  { label: "sha256", value: "sha256" },
  { label: "sha512", value: "sha512" },
];

export const filterCriteriaList = [
  { label: "greater than", value: "greater_than" },
  { label: "less than", value: "less_than" },
  { label: "equal to", value: "equal_to" },
  { label: "not equal to", value: "not_equal_to" },
];

export const isValidRecord = (columnValue, searchText, filterCriteria) => {
  let isFiltered = false;
  columnValue = ((columnValue || "") + "").toLowerCase();
  switch (filterCriteria) {
    case "greater_than":
      const gcValue = parseFloat(columnValue);
      const gsValue = parseFloat(searchText);
      isFiltered = !isNaN(gcValue) && !isNaN(gsValue) ? gcValue > gsValue : false;
      break;
    case "less_than":
      const lcValue = parseFloat(columnValue);
      const lsValue = parseFloat(searchText);
      isFiltered = !isNaN(lcValue) && !isNaN(lsValue) ? lcValue < lsValue : false;
      break;
    case "equal_to":
      isFiltered = columnValue == searchText;
      break;
    case "not_equal_to":
      isFiltered = columnValue != searchText;
      break;
    default:
      isFiltered = columnValue.includes(searchText);
      break;
  }
  return isFiltered;
};

export const roleStaticList = [
  { label: "Administrator", value: "ADMIN", description: "Best for business owners and company administrators" },
  {
    label: "Business Manager",
    value: "MANAGER",
    description: "Best for  business managers of Marketing or Sales Team, Process Automation Team members.",
  },
  {
    label: "Business User",
    value: "USER",
    description: "Best for Marketing or Sales Team, Process Automation Team members.",
  },
  { label: "Physician", value: "PHYSICIAN", description: "Best for doctor to take a call with patient" },
  {
    label: "Call Center - User1",
    value: "CALLCENTER_USER1",
    description: "Best for call center - Allow only Live Chat and Visitors pages",
  },
  {
    label: "Call Center - User2",
    value: "CALLCENTER_USER2",
    description: "Best for call center - Allow only Dashboard",
  },
  {
    label: "Call Center - User3",
    value: "CALLCENTER_USER3",
    description: "Best for call center - Allow only Live Chat and Visitors pages",
  },
  {
    label: "Call Center - Supervisor",
    value: "CALLCENTER_SUPERVISOR",
    description: "Best for Supervisor - Allow only Live Chat and Visitors pages",
  },
  { label: "IT Manager", value: "IT_MANAGER", description: "Best for IT Management Teams" },
  { label: "M User", value: "M_USER", description: "Best for Mobile Users - Allow only Live Chat" },
];

export const orderStatusList = [
  { label: "DRAFT", value: "DRAFT" },
  { label: "NEW PENDING ACCEPTANCE", value: "NEW PENDING ACCEPTANCE" },
  { label: "NEW", value: "NEW" },
  { label: "IN PROCESS", value: "IN PROCESS" },
  { label: "READY", value: "READY" },
  { label: "FULFILLED", value: "FULFILLED" },
  { label: "CANCELLED", value: "CANCELLED" },
  { label: "CLOSED", value: "CLOSED" },
];

export const ignoreChannelForMerchant = (channelName = "", ignoreMerchant = false) => {
  const merchantList = ["135699100356", "135699100126"];
  if (ignoreMerchant) return merchantList.includes(sessionMerchantId());

  if (channelName && channelName.trim() === "Facebook Messenger")
    return merchantList.includes(sessionMerchantId()) ? "FB" : channelName;
  else if (!channelName) return merchantList.includes(sessionMerchantId()) ? "facebook.svg" : "facebook-messenger.png";
  return channelName;
};

export const getQueryParams = (url, params) => {
  let href = decodeURIComponent(url);
  if (params) {
    //this expression is to get the query strings
    let reg = new RegExp("[?&]" + params + "=([^&#]*)", "i");
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
  } else {
    return {};
  }
};
export const isObjEmpty = object => !Object.values(object).some(x => x !== null && x !== "");

export const anchorUrl = url => {
  //create an anchor tag to use the property called search
  let anchor = document.createElement("a");
  //assigning url to href of anchor tag
  anchor.href = url;
  return anchor;
};

export const getAllQueryParams = url => {
  let queryParams = {};
  //search property returns the query string of url
  let queryStrings = anchorUrl(url).search.substring(1);
  let params = queryStrings.split("&");

  for (var i = 0; i < params.length; i++) {
    var pair = params[i].split("=");
    queryParams[pair[0]] = decodeURIComponent(pair[1]);
  }
  return queryParams;
};

export const isArray = arr => {
  return Array.isArray(arr) && arr.length ? true : false;
};

export const getDomain = () => {
  const port = window.location.port ? `:${window.location.port}` : "";
  const domain = `${window.location.protocol}//${window.location.hostname}${port}`;
  return domain;
};

export const isArabic = str => {
  const arabic = /[\u0600-\u06FF]/;
  return arabic.test(str); // displays true if arabic string
};

export const CLOUDINARY_ASSETS = {
  CALLER_TUNE: `https://storage.googleapis.com/${GCS_BUCKET.AUDIO}/caller-tune.mp3`,
  NOTIFICATION_TUNE: `https://storage.googleapis.com/${GCS_BUCKET.AUDIO}/notification.mp3`,
  AGENT_NOTIFICATION_TUNE: `https://storage.googleapis.com/${GCS_BUCKET.AUDIO}/app-notify.mp3`,
  VISITOR_MSG_TUNE: `https://storage.googleapis.com/${GCS_BUCKET.AUDIO}/msg-notify.mp3`,
  SCANNER_BEEP: "https://res.cloudinary.com/dddd9bezd/video/upload/v1622206284/AiAgents/scanner-beep_lcvh5z.mp3",
  AGENT_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/default.png`,
  TEXTURE_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/agent-bg.jpg`,
  AUDIO_CALL_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/audio-call.svg`,
  VIDEO_CALL_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710425/public/images/video-call.svg`,
  SCREEN_SHARE_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710425/public/images/screen-share.svg`,
  CO_BROWSE_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/co-browse.svg`,
  MUTE_CHAT_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/mute-chat.svg`,
  START_AGAIN_IMAGE: `${CLOUDINARY_ENDPOINT}/image/upload/v1587710424/public/images/start-again.svg`,
};

export const validationTypeList = [
  { label: "Text", value: "TEXT" },
  { label: "Phone", value: "PHONE" },
  { label: "Email", value: "EMAIL" },
  { label: "Number", value: "NUMBER" },
  { label: "RegEx", value: "REGEX" },
  { label: "Rating", value: "RATING" },
  { label: "Location", value: "LOCATION" },
  { label: "Date", value: "DATE" },
  { label: "Time", value: "TIME" },
  { label: "Barcode Scanner", value: "SCANNER" },
  { label: "Attachment", value: "ATTACHMENT" },
  { label: "Camera", value: "CAMERA" },
  { label: "OCR", value: "OCR" },
  // { label: 'Password', value: 'Password' },
  // { label: 'NPS (1-10)', value: 'NPS (1-10)' },
  // { label: 'NPS (1-5)', value: 'NPS (1-5)' },
  // { label: 'NPS (1-3)', value: 'NPS (1-3)' },
  // { label: 'Regex', value: 'Regex' }
];

export const botPaymentMethods = [
  { label: "Razorpay", value: "razorpayv2" },
  { label: "Stripe", value: "stripepay" },
  { label: "Paytabs", value: "paytabs" },
  { label: "NoqoodyPAY", value: "noqoody" },
  { label: "Hyperpay", value: "hyperpay" },
];

export const viewSizes = [
  { label: "Small", value: "S" },
  { label: "Medium", value: "M" },
  { label: "Large", value: "L" },
];

export const botViewType = [
  { label: "WebView", value: "webview" },
  { label: "ProductView", value: "productview" },
];

export const botFormType = [
  { label: "Lightbox", value: "LIGHTBOX" },
  { label: "Popup", value: "POPUP" },
  { label: "Embed", value: "EMBED" },
];

export const fileUploadType = [
  { label: "OneDrive", value: "fileuploadonedrivev2" },
  { label: "Cloudinary", value: "fileUpload" },
  { label: "Custom API", value: "customfileupload" },
];

export const pageDataType = [
  { label: "Local Storage", value: "LOCAL_STORAGE" },
  { label: "Session Storage", value: "SESSION_STORAGE" },
  { label: "Page Variable", value: "PAGE_VARIABLE" },
  { label: "Cookie", value: "COOKIE" },
];

export const isElement = element => {
  return element && (element instanceof Element || element instanceof HTMLDocument);
};

export const loadImage = src => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", err => reject(err));
    img.src = src;
  });
};

export const conditionList = [
  { value: "contains", name: "Contains" },
  { value: "isEqualTo", name: "Is Equal To" },
  { value: "isNotEqualTo", name: "Is Not Equal To" },
  { value: "isLessThan", name: "Is Less Than" },
  { value: "isLessThanOrEqual", name: "Is Less Than Or Equal" },
  { value: "isGreaterThan", name: "Is Greater Than" },
  { value: "isGreaterThanOrEqual", name: "Is Greater Than Or Equal" },
  { value: "beginsWith", name: "Begins With" },
  { value: "endsWith", name: "Ends With" },
  { value: "doesNotContain", name: "Does Not Contain" },
  { value: "regEx", name: "RegEx" },
  { value: "hasValue", name: "Has Value" },
];

export const checkCondition = (attr1, attr2, condition) => {
  let result = false;
  switch (condition) {
    case "contains":
      result = attr1.toLowerCase().includes(attr2.toLowerCase());
      break;
    case "isEqualTo":
      result = attr1.toLowerCase() === attr2.toLowerCase();
      break;
    case "isNotEqualTo":
      result = attr1.toLowerCase() !== attr2.toLowerCase();
      break;
    case "isLessThan":
      result = parseFloat(attr1) < parseFloat(attr2);
      break;
    case "isLessThanOrEqual":
      result = parseFloat(attr1) <= parseFloat(attr2);
      break;
    case "isGreaterThan":
      result = parseFloat(attr1) > parseFloat(attr2);
      break;
    case "isGreaterThanOrEqual":
      result = parseFloat(attr1) >= parseFloat(attr2);
      break;
    case "beginsWith":
      result = attr1.toLowerCase().startsWith(attr2.toLowerCase());
      break;
    case "endsWith":
      result = attr1.toLowerCase().endsWith(attr2.toLowerCase());
      break;
    case "doesNotContain":
      result = !attr1.toLowerCase().includes(attr2.toLowerCase());
      break;
    case "regEx":
      result = new RegExp(attr2).test(attr1);
      break;
    case "hasValue":
      result = attr1 ? true : false;
      break;
    default:
      break;
  }
  return result;
};

export const styleList = [
  { label: "Card", value: "carousel" },
  { label: "List", value: "list" },
  { label: "Menu", value: "botMenu" },
  { label: "Appointment Card", value: "appointmentCarousel" },
];

export const HTTP_STATUS_CODES = {
  CODE_200: "OK",
  CODE_201: "Created",
  CODE_202: "Accepted",
  CODE_203: "Non-Authoritative Information",
  CODE_204: "No Content",
  CODE_205: "Reset Content",
  CODE_206: "Partial Content",
  CODE_300: "Multiple Choices",
  CODE_301: "Moved Permanently",
  CODE_302: "Found",
  CODE_303: "See Other",
  CODE_304: "Not Modified",
  CODE_305: "Use Proxy",
  CODE_307: "Temporary Redirect",
  CODE_400: "Bad Request",
  CODE_401: "Unauthorized",
  CODE_402: "Payment Required",
  CODE_403: "Forbidden",
  CODE_404: "Not Found",
  CODE_405: "Method Not Allowed",
  CODE_406: "Not Acceptable",
  CODE_407: "Proxy Authentication Required",
  CODE_408: "Request Timeout",
  CODE_409: "Conflict",
  CODE_410: "Gone",
  CODE_411: "Length Required",
  CODE_412: "Precondition Failed",
  CODE_413: "Request Entity Too Large",
  CODE_414: "Request-URI Too Long",
  CODE_415: "Unsupported Media Type",
  CODE_416: "Requested Range Not Satisfiable",
  CODE_417: "Expectation Failed",
  CODE_500: "Internal Server Error",
  CODE_501: "Not Implemented",
  CODE_502: "Bad Gateway",
  CODE_503: "Service Unavailable",
  CODE_504: "Gateway Timeout",
  CODE_505: "HTTP Version Not Supported",
};

export const fetchData = (url, method, headersList, template) => {
  const reqObj = {
    method,
  };

  const headers = {};
  let isJSONContent = method === "GET";
  headersList.map(header => {
    const [key, value] = header.split(":");
    headers[key] = value;
    if (key.toLowerCase() === "content-type") {
      isJSONContent = true;
    }
  });
  if (!isJSONContent) {
    headers["content-type"] = "application/json";
  }
  if (Object.keys(headers).length > 0) {
    reqObj.headers = headers;
  }

  if (
    template &&
    typeof template === "string" &&
    JSON.parse(template) &&
    Object.keys(JSON.parse(template)).length > 0
  ) {
    reqObj.body = template;

    let isJSON = typeof reqObj.body === "object";
    if (!isJSON) reqObj.body = JSON.parse(reqObj.body);

    isJSON = typeof reqObj.body === "object";
    if (!isJSON) reqObj.body = JSON.parse(reqObj.body);

    reqObj.body = JSON.stringify(reqObj.body);
  }

  return fetch(url, reqObj).then(response => {
    let statusText = response.statusText;
    if (!statusText && HTTP_STATUS_CODES[`CODE_${response.status}`]) {
      statusText = HTTP_STATUS_CODES[`CODE_${response.status}`];
    }
    if (!response.ok) {
      return { success: false, error: response.statusText || "Error", status: response.status, statusText };
    }
    return response
      .json()
      .then(data => {
        return { success: true, data, status: response.status, statusText };
      })
      .catch(error => {
        return { success: false, error, status: "Error!", statusText: "" };
      });
  });
};

export const getStructure = (obj, str, cb) => {
  if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
    Object.keys(obj).map(x => {
      if (typeof obj[x] === "object") {
        getStructure(obj[x], `${str}.${x}`, cb);
      } else {
        cb(str, x);
      }
    });
  } else if (Array.isArray(obj)) {
    cb(str);
    getStructure(obj[0], `${str}${str === "JSON" ? "." : ""}[i]`, cb);
  }
};

export const replaceEntityAttr = (value, obj, entityId) => {
  if (!value) return "";
  Object.keys(obj).map(o => {
    value = value.split(`{{entity_${entityId}.${o}}}`).join(obj[o]);
  });
  return value;
};
export const getUrlExtension = url => {
  return url.split(/[#?]/)[0].split(".").pop().trim();
};

export const addErrorObj = (obj, errors) => {
  const insertErr = o => {
    if (!("errors" in o)) {
      return {
        ...o,
        errors: { ...errors },
      };
    }
    return o;
  };
  if (typeof obj === "object" && !Array.isArray(obj)) {
    return insertErr(obj);
  } else if (Array.isArray(obj)) {
    return obj.map(insertErr);
  }
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const medicineDetails = [
  // { name: '	8-HOUR BAYER-650MG-ASPIRIN	 ', value: '	8-HOUR BAYER-650MG-ASPIRIN	   ' },
  // { name: '	8-MOP-10MG-METHOXSALEN	 ', value: '	8-MOP-10MG-METHOXSALEN	   ' },
  // { name: '	A-HYDROCORT-EQ 100MG BASE/VIAL-HYDROCORTISONE SODIUM SUCCINATE	 ', value: '	A-HYDROCORT-EQ 100MG BASE/VIAL-HYDROCORTISONE SODIUM SUCCINATE	   ' },
  // { name: '	A-METHAPRED-EQ 125MG BASE/VIAL-METHYLPREDNISOLONE SODIUM SUCCINATE	 ', value: '	A-METHAPRED-EQ 125MG BASE/VIAL-METHYLPREDNISOLONE SODIUM SUCCINATE	   ' },
  // { name: '	A-POXIDE-10MG-CHLORDIAZEPOXIDE HYDROCHLORIDE	 ', value: '	A-POXIDE-10MG-CHLORDIAZEPOXIDE HYDROCHLORIDE	   ' },
  // { name: '	A.P.L.-10,000 UNITS/VIAL-GONADOTROPIN, CHORIONIC	 ', value: '	A.P.L.-10,000 UNITS/VIAL-GONADOTROPIN, CHORIONIC	   ' },
  // { name: '	A/T/S-0.02-ERYTHROMYCIN	 ', value: '	A/T/S-0.02-ERYTHROMYCIN	   ' },
  // { name: '	ABACAVIR AND LAMIVUDINE-120MG;60MG-ABACAVIR; LAMIVUDINE	 ', value: '	ABACAVIR AND LAMIVUDINE-120MG;60MG-ABACAVIR; LAMIVUDINE	   ' },
  // { name: '	ABACAVIR SULFATE AND LAMIVUDINE-EQ 600MG BASE;300MG-ABACAVIR SULFATE; LAMIVUDINE	 ', value: '	ABACAVIR SULFATE AND LAMIVUDINE-EQ 600MG BASE;300MG-ABACAVIR SULFATE; LAMIVUDINE	   ' },
  // { name: '	ABACAVIR SULFATE-20MG-ABACAVIR SULFATE	 ', value: '	ABACAVIR SULFATE-20MG-ABACAVIR SULFATE	   ' },
  // { name: '	ABACAVIR SULFATE, LAMIVUDINE AND ZIDOVUDINE-EQ 300MG BASE; 150MG; 300MG-ABACAVIR SULFATE; LAMIVUDINE; ZIDOVUDINE	 ', value: '	ABACAVIR SULFATE, LAMIVUDINE AND ZIDOVUDINE-EQ 300MG BASE; 150MG; 300MG-ABACAVIR SULFATE; LAMIVUDINE; ZIDOVUDINE	   ' },
  // { name: '	ABACAVIR SULFATE; LAMIVUDINE-600;300MG-ABACAVIR SULFATE;LAMIVUDINE	 ', value: '	ABACAVIR SULFATE; LAMIVUDINE-600;300MG-ABACAVIR SULFATE;LAMIVUDINE	   ' },
  // { name: '	ABACAVIR; LAMIVUDINE-60MG;30MG-ABACAVIR;LAMIVUDINE	 ', value: '	ABACAVIR; LAMIVUDINE-60MG;30MG-ABACAVIR;LAMIVUDINE	   ' },
  // { name: '	ABELCET-5MG/ML-AMPHOTERICIN B	 ', value: '	ABELCET-5MG/ML-AMPHOTERICIN B	   ' },
  // { name: '	ABILIFY MAINTENA KIT-300MG-ARIPIPRAZOLE	 ', value: '	ABILIFY MAINTENA KIT-300MG-ARIPIPRAZOLE	   ' },
  // { name: '	ABILIFY MYCITE KIT-10MG-ARIPIPRAZOLE	 ', value: '	ABILIFY MYCITE KIT-10MG-ARIPIPRAZOLE	   ' },
  // { name: '	ABILIFY-10MG 	 ', value: '	ABILIFY-10MG 	   ' },
  // { name: '	ABIRATERONE ACETATE-250MG-ABIRATERONE ACETATE	 ', value: '	ABIRATERONE ACETATE-250MG-ABIRATERONE ACETATE	   ' },
  // { name: '	ABITREXATE-EQ 100MG BASE/VIAL-METHOTREXATE SODIUM	 ', value: '	ABITREXATE-EQ 100MG BASE/VIAL-METHOTREXATE SODIUM	   ' },
  // { name: '	ABLAVAR-2440MG/10ML (244MG/ML)-GADOFOSVESET TRISODIUM	 ', value: '	ABLAVAR-2440MG/10ML (244MG/ML)-GADOFOSVESET TRISODIUM	   ' },
  // { name: '	ABLYSINOL-99% (1ML)-ALCOHOL	 ', value: '	ABLYSINOL-99% (1ML)-ALCOHOL	   ' },
  // { name: '	ABRAXANE-100MG/VIAL-PACLITAXEL	 ', value: '	ABRAXANE-100MG/VIAL-PACLITAXEL	   ' },
  // { name: '	ABREVA-0.1-DOCOSANOL	 ', value: '	ABREVA-0.1-DOCOSANOL	   ' },
  // { name: '	ABRILADA-10MG/0.2ML-ADALIMUMAB-AFZB	 ', value: '	ABRILADA-10MG/0.2ML-ADALIMUMAB-AFZB	   ' },
  // { name: '	ABSORICA LD-16MG-ISOTRETINOIN	 ', value: '	ABSORICA LD-16MG-ISOTRETINOIN	   ' },
  // { name: '	ABSORICA-10MG-ISOTRETINOIN	 ', value: '	ABSORICA-10MG-ISOTRETINOIN	   ' },
  // { name: '	ABSTRAL-EQ 0.1MG BASE-FENTANYL CITRATE	 ', value: '	ABSTRAL-EQ 0.1MG BASE-FENTANYL CITRATE	   ' },
  // { name: '	ACAMPROSATE CALCIUM-333MG-ACAMPROSATE CALCIUM	 ', value: '	ACAMPROSATE CALCIUM-333MG-ACAMPROSATE CALCIUM	   ' },
  // { name: '	ACANYA-2.5%;EQ 1.2% BASE-BENZOYL PEROXIDE; CLINDAMYCIN PHOSPHATE	 ', value: '	ACANYA-2.5%;EQ 1.2% BASE-BENZOYL PEROXIDE; CLINDAMYCIN PHOSPHATE	   ' },
  // { name: '	ACARBOSE-100MG-ACARBOSE	 ', value: '	ACARBOSE-100MG-ACARBOSE	   ' },
  // { name: '	ACCOLATE-10MG-ZAFIRLUKAST	 ', value: '	ACCOLATE-10MG-ZAFIRLUKAST	   ' },
  // { name: '	ACCRETROPIN-5MG/ML (5MG/ML)-SOMATROPIN	 ', value: '	ACCRETROPIN-5MG/ML (5MG/ML)-SOMATROPIN	   ' },
  // { name: '	ACCRUFER-30MG IRON-FERRIC MALTOL	 ', value: '	ACCRUFER-30MG IRON-FERRIC MALTOL	   ' },
  // { name: '	ACCUNEB-EQ 0.021% BASE-ALBUTEROL SULFATE	 ', value: '	ACCUNEB-EQ 0.021% BASE-ALBUTEROL SULFATE	   ' },
  // { name: '	ACCUPRIL-EQ 10MG BASE-QUINAPRIL HYDROCHLORIDE	 ', value: '	ACCUPRIL-EQ 10MG BASE-QUINAPRIL HYDROCHLORIDE	   ' },
  // { name: '	ACCURBRON-150MG/15ML-THEOPHYLLINE	 ', value: '	ACCURBRON-150MG/15ML-THEOPHYLLINE	   ' }
];

export const getLoginUrl = () => {
  const cLogin = sessionCLogin() || (window.location.host.includes(CLOGIN_DOMAIN) ? CLOGIN_MERCHANT : null);
  return cLogin ? `/clogin/${cLogin}` : "/login";
};

export const logoutApp = navigate => {
  navigate(getLoginUrl());
};

export const visitorCheck = (v, messageList) => {
  let agentObj = v.activeAgent ? v.agents.find(x => x.id === v.activeAgent) : null;
  let needTracking = isArray(v.tracking) && v.tracking[0].tracking_code !== "9";
  let disableAlert = false;
  let alertMessageText = "";
  if (needTracking) {
    const trackedBy = (
      v.tracking[0].tracked_by && v.tracking[0].tracked_by.includes("|") ? v.tracking[0].tracked_by : "||"
    ).split("|");
    if (v.tracking[0].tracking_code === "1") {
      disableAlert = true;

      // tracking code
      // 0 - tracking with alert message (request assist)
      // 1 - tracking without alert message (assigned to agent)
      // 9 - replied
    }
    if (trackedBy[0] && trackedBy[0] !== "ANY") {
      agentObj = {
        id: trackedBy[0],
        firstName: trackedBy[1],
        lastName: trackedBy[2],
      };
    }
    alertMessageText = v.tracking[0].track_message || "";
  }

  const fName = ((agentObj || {}).firstName || "").trim();
  const lName = ((agentObj || {}).lastName || "").trim();
  const agentName = `${fName} ${lName.replace("**", "")}`.trim();

  let isCheckinAgent = agentObj && (!fName || !lName || lName === "**") ? true : false;
  if (isCheckinAgent && sessionRole() === "PHYSICIAN") {
    isCheckinAgent = agentObj.id == sessionUserId();
  }

  const condition1 =
    sessionRole() !== "PHYSICIAN" &&
    !agentObj &&
    v.visitorParams &&
    v.visitorParams.intent &&
    v.visitorParams.intent.startsWith("requestCallBack");

  const condition2 = sessionRole() !== "PHYSICIAN" && needTracking && (!agentObj ? true : !disableAlert);
  const condition3 =
    sessionRole() === "PHYSICIAN" && needTracking && agentObj && agentObj.id == sessionUserId() && !disableAlert;

  const isReqAssist = v.referenceID && (condition1 || condition2 || condition3 || isCheckinAgent);
  let assistBadge = isReqAssist || agentName ? true : false;

  const obj = {
    isReqAssist,
    reqObj: {
      visitorId: v.referenceID,
      name: getVisitorId(v),
      sessionID: v.sessionID,
      channel: v.channel,
    },
    vObj: {
      needTracking,
      isReqCallBack: condition1,
      isCheckinAgent,
      userAgentName: agentName,
      userAgentId: agentObj ? agentObj.id : null,
      needAgent: isCheckinAgent ? (!agentObj || isCheckinAgent ? 2 : 1) : null,
      assistBadge,
      assistBadgeText: agentName || alertMessageText || "Request Assist",
      sortColumn: assistBadge ? (agentName ? 2 : 1) : 3,
      alertMessageText,
    },
    isAgentAssigned: false,
  };

  if (
    !needTracking &&
    agentName &&
    agentObj.id == sessionUserId() &&
    !v.isAgentAssigned &&
    v.userAgentName !== agentName
  ) {
    v.isAgentAssigned = true;
    obj.isAgentAssigned = true;
  }
  v.userAgentName = agentName;

  if (messageList) {
    const needAgent = messageList.findIndex(x => x.visitorId == v.referenceID && x.isOnline) !== -1;
    assistBadge = needAgent || isReqAssist || agentName;
    obj.vObj = {
      ...obj.vObj,
      needAgent: needAgent || isCheckinAgent ? (!agentObj || isCheckinAgent ? 2 : 1) : null,
      assistBadge,
      sortColumn: assistBadge ? (agentName ? 2 : 1) : 3,
    };
  }

  return obj;
};

export const channelProviderList = [
  { label: "Twilio", value: "dHdpbGlv", route: "", err: "errorsTwilio" },
  { label: "Gupshup", value: "Z3Vwc2h1cHBw", route: "/gpe", err: "errorsGupShup" },
  { label: "Gupshup.io", value: "Z3Vwc2h1cGlv", route: "/gpio", err: "errorsGupShupIo" },
];

export const voiceProviderList = [
  { label: "Avaya", value: "avaya" },
  { label: "Twilio", value: "twilio" },
];

export const taskTypeList = [
  { label: "Request Call Back", value: "Request Call Back" },
  { label: "Service Request", value: "Service Request" },
  { label: "Feedback", value: "Feedback" },
];

export const taskPriorityList = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];

export const taskModeList = [
  { label: "Task", value: "task" },
  { label: "Ticket", value: "ticket" },
];

export const taskStatusList = [
  { label: "Open", value: "Open" },
  { label: "Pending", value: "Pending" },
  { label: "Assigned", value: "Assigned" },
  { label: "In Progress", value: "In Progress" },
  { label: "Completed", value: "Completed" },
];

export const getColor = value => {
  switch (value) {
    case "Low":
      return "#B1D43A";
    case "Medium":
      return "#FEB92E";
    case "High":
      return "#E71D07";
    case "Pending":
      return "rgb(255, 196, 0)";
    case "Assigned":
      return "rgb(255, 139, 0)";
    case "In Progress":
      return "rgb(0, 82, 204)";
    case "Completed":
      return "rgb(0, 135, 90)";
    case "default":
  }
};

export const getInsightColor = value => {
  switch (value) {
    case 1:
      return { value: "Very unlikely", color: "badge-red" };
    case 2:
      return { value: "Unlikely", color: "badge-orange" };
    case 3:
      return { value: "Neutral", color: "badge-gray-500" };
    case 4:
      return { value: "Likely", color: "badge-green" };
    case 5:
      return { value: "Very likely", color: "badge-darkgreen" };
  }
};

export const taskPriorityDropdownList = () => {
  const prioList = [];
  taskPriorityList.map((x, i) => {
    prioList.push({
      label: (
        <div style={{ color: getColor(x.value) }} className="font-weight-bold">
          {x.label}
        </div>
      ),
      value: x.value,
    });
  });
  return prioList;
};

export const taskStatusDropdownList = () => {
  const statusList = [];
  taskStatusList.map((x, i) => {
    statusList.push({
      label: (
        <div style={{ color: getColor(x.value) }} className="font-weight-bold">
          {x.label}
        </div>
      ),
      value: x.value,
    });
  });
  return statusList;
};

export const createCloudinaryWidget = (preset, cb, options = {}) => {
  return cloudinary.createUploadWidget(
    {
      cloudName: CLOUDINARY_DATA.CLOUD_NAME,
      uploadPreset: preset,
      cropping: true,
      sources: ["local", "url", "camera"],
      tags: [sessionMerchantId()],
      ...options,
    },
    (error, result) => cb(result, error)
  );
};

export const replaceToken = data => {
  if (!data) return data;

  let input = data;
  let isJSON = false;
  if (typeof input === "object") {
    input = JSON.stringify(input);
    isJSON = true;
  }

  input = input
    .split("${pgt.access_token}")
    .join(sessionAccessToken())
    .split("${(pgt.access_token)}")
    .join(sessionAccessToken())
    .split("${pgt.access_token!}")
    .join(sessionAccessToken())
    .split("${(pgt.access_token)!}")
    .join(sessionAccessToken());

  if (isJSON) {
    input = JSON.parse(input);
  }

  return input;
};

export const getTotalMessages = x => {
  const totalMessages =
    x.inboundMessages !== null &&
    x.inboundMessages !== undefined &&
    x.outboundMessages !== null &&
    x.outboundMessages !== undefined
      ? x.inboundMessages + x.outboundMessages
      : x.totalMessages;
  return totalMessages || x.totalMessages;
};

export const getTotalConverseCt = x => {
  const senderConverseCt =
    x.inboundMessages !== null &&
    x.inboundMessages !== undefined &&
    x.outboundMessages !== null &&
    x.outboundMessages !== undefined
      ? x.inboundMessages + x.outboundMessages
      : x.senderConverseCt;
  return senderConverseCt;
};

export const replaceURLs = message => {
  if (!message) return;

  const urlRegex =
    /(<img\s[^>]*>|<a(?:\s[^>]*)?>[\s\S]*?<\/a>)|(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gi;
  return message.replace(urlRegex, (url, tag) => {
    if (tag) return url;

    let hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" class="chat-link" rel="noopener noreferrer">' + url + "</a>";
  });
};

export const generateTagLabel = tag => {
  return (
    <span className="tag-label" style={{ color: tag.tagColor }}>
      <span style={{ background: tag.tagColor }}></span>
      {tag.tagName}
    </span>
  );
};

export const loadSupportBot = cb => {
  const el = document.getElementById("purplegrid-support-chat-frame");
  if (el) {
    const isOpen = el.style.display !== "none";
    el.style.display = el.style.display !== "none" ? "none" : "block";
    if (cb) cb(isOpen ? 0 : 2);
    return;
  }

  if (cb) cb(1);
  const sbInterval = setInterval(() => {
    const iframeEl = document.getElementById("purplegrid-support-chat-frame");
    if (iframeEl) {
      clearInterval(sbInterval);
      iframeEl.style.transition = "left 0.25s";
      if (cb) cb(2);
    }
    // if (iframeEl && iframeEl.contentWindow.document.getElementsByClassName('chat-widget').length && iframeEl.contentWindow.document.getElementsByClassName('chat-widget')[0].style.display === 'block') {
    //   clearInterval(sbInterval);
    //   if (cb) cb(2);
    // }
  }, 1000);

  const chatBotScript = document.createElement("script");
  chatBotScript.id = "supportChat";
  chatBotScript.type = "text/javascript";
  chatBotScript.src = `${MESSENGER_URL}libs/engagement-1.0.0.min.js?v=${Math.random()}`;
  chatBotScript.setAttribute("data-pg-chat", "3435");
  chatBotScript.setAttribute("data-pg-client", "135699100621");
  chatBotScript.setAttribute("data-pg-db", "1");
  chatBotScript.setAttribute("data-pg-dsc", "1");
  chatBotScript.setAttribute("data-pg-sb", "1");
  chatBotScript.setAttribute("data-pg-id", "purplegrid-support-chat-frame");
  chatBotScript.setAttribute(
    "data-pg-attr",
    `&size=medium&cId=pg_welcome&setAttr=merchantId:${sessionMerchantId()};userId:${sessionUserId()};saveUser:${sessionUser()}`
  );
  chatBotScript.setAttribute(
    "data-pg-style",
    "border: 0;position:fixed;z-index: 9999999;left:54px;bottom:0;max-width: 402px;min-width: 320px;width: 100%;height: 100%;max-height: 630px;"
  );
  document.body.appendChild(chatBotScript);
};

export const removeSupportBot = () => {
  const chatElement = $("iframe[id='purplegrid-support-chat-frame']");
  if (chatElement && chatElement.remove) {
    chatElement.remove();
  }
  const scriptElement = $("script#supportChat");
  if (scriptElement && scriptElement.remove) {
    scriptElement.remove();
  }
};

export const getTarget = type => {
  switch (type) {
    case "web_url":
      return "_blank";
    case "web_url_self":
      return "_parent";
    default:
      return "";
  }
};

export const hashMobileAccess = () => {
  let isIOS = false;
  let isAndroid = false;

  // Show notification in IOS devices
  if (
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.iOSMessageHandler &&
    window.webkit.messageHandlers.iOSMessageHandler.postMessage
  ) {
    isIOS = true;
    // window.webkit.messageHandlers.iOSMessageHandler.postMessage(notificationMsg);
  }

  // Show notification in Android devices
  if (window.Android && window.Android.showToast) {
    isAndroid = true;
  }

  return { isIOS, isAndroid };
};

export const mapEnvironment = (envList, body, customConfigData) => {
  let mappedData = JSON.stringify(body);
  if (customConfigData && mappedData && mappedData.includes("merchant.customConfig")) {
    for (const match of mappedData.matchAll(/\${([^}]*)}/g)) {
      const keyMatch = match[0] && match[0].match(/merchant\.customConfig\.(\w+)/);
      if (keyMatch && keyMatch[1]) {
        const key = keyMatch[1];
        envList.push({ key: match[0], value: customConfigData[key] ? customConfigData[key] : keyMatch["input"] });
      }
    }
  }
  envList.map(env => {
    mappedData = mappedData.replaceAll(env.key, env.value);
  });
  return JSON.parse(mappedData);
};

export const handleRequestAPI = (formData, externalEnvironments = [], customConfigData = []) => {
  const {
    url,
    type,
    headersList,
    authType,
    bearerToken,
    rawData,
    environmentList,
    contentType,
    bodyList,
    username,
    password,
  } = formData;
  const httpEnvList = unionBy(environmentList, externalEnvironments, "key");
  const request = {
    uri: url,
    method: type,
  };
  const newHeader = [...headersList];
  if (isArray(newHeader.filter(l => l.key && l.value))) {
    request["headers"] = newHeader.filter(x => x.key);
  }

  if (authType === "BEARER_TOKEN") {
    request["authorization"] = {
      type: "BEARER_TOKEN",
      token: bearerToken,
    };
  } else if (authType === "BASIC_AUTH") {
    request["authorization"] = {
      type: "BASIC_AUTH",
      username: username,
      password: password,
    };
  }

  if (type !== "GET") {
    request["contentType"] = contentType;
    if (contentType === "application/json") {
      request["payload"] = rawData;
    } else {
      request["payload"] = isArray(bodyList) ? bodyList : "";
    }
  } else {
    request["payload"] = "";
  }

  const mappedBody = isArray(httpEnvList)
    ? mapEnvironment(
        httpEnvList.filter(x => x.key && x.value),
        request,
        customConfigData
      )
    : request;

  return CommonUtils.requestApi(mappedBody);
};

export const mapEnvironmentV2 = (envList, body, customConfigData) => {
  const list = cloneDeep(envList).map(env => {
    env.value = isJsonString(env.value) ? JSON.parse(env.value) : env.value;
    return env;
  });

  if (customConfigData) {
    Object.keys(customConfigData).map(key => {
      list.push({
        key: `\${merchant.customConfig.${key}}`,
        value: customConfigData[key],
      });
    });
  }
  return replaceEnvPlaceholders(body, list);
};

//#region replace placeholder

const replaceEnvPlaceholders = (obj, envList) => {
  if (Array.isArray(obj)) {
    return obj.map(item => replaceEnvPlaceholders(item, envList));
  } else if (typeof obj === "object" && obj !== null) {
    let result = {};
    for (let key in obj) {
      if (typeof obj[key] === "string") {
        let replacedValue = obj[key];
        envList.forEach(env => {
          const envKey = env.key;
          const envValue = typeof env.value === "string" ? env.value : JSON.stringify(env.value);
          replacedValue = replacedValue.replaceAll(envKey, envValue);
        });
        try {
          result[key] = JSON.parse(replacedValue);
        } catch (_) {
          result[key] = replacedValue;
        }
      } else {
        result[key] = replaceEnvPlaceholders(obj[key], envList);
      }
    }
    return result;
  } else if (typeof obj === "string") {
    let replacedValue = obj;
    envList.forEach(env => {
      const envKey = env.key;
      const envValue = typeof env.value === "string" ? env.value : JSON.stringify(env.value);
      replacedValue = replacedValue.replaceAll(envKey, envValue);
    });
    try {
      return JSON.parse(replacedValue);
    } catch (_) {
      return replacedValue;
    }
  } else {
    return obj;
  }
};

//#endregion

export const getValue = (data, fields, delimiter) => {
  return fields
    .map(f => get(data, f))
    .reduce((o, r) => {
      return [o, r]
        .map(e => (typeof e === "string" ? e.trim() : e))
        .filter(Boolean)
        .join(delimiter || " ");
    }, "");
};

export const convertVertexPredictData = data => {
  return data.replace(/\*\*([^*]+)\*\*/g, "<b>$1</b>").replace(/\*([^*\s]+)\*/g, "<i>$1</i>");
};

export const userLogout = (isDeleteAgent = true) => {
  if (sessionUser()) {
    const obj = {
      email: sessionUser(),
      firstName: sessionFirstName(),
      lastName: sessionLastName(),
      loginStatus: "logout",
      logoutDt: getUtcDate(),
      merchantID: sessionMerchantId(),
    };
    if (isDeleteAgent) {
      CommonUtils.deleteUserAgent();
    }
    CommonUtils.createUserAgents(obj);
  }
};

export const scrollPageTo = (targetElement, position) => {
  const boundingRect = document.querySelector(targetElement).getBoundingClientRect();
  const scrollPosition = window.scrollY + boundingRect.top - position;
  window.scrollTo({
    top: scrollPosition,
    behavior: "smooth",
  });
};

export const getMerchantLogo = mId => {
  return mId ? `https://storage.googleapis.com/${GCS_BUCKET.MERCHANT}/${mId}.png` : BRAND_LOGO_URL;
};

export const maskEmail = (email = "") => {
  const [name, domain] = email.split("@");
  const { length: len } = name;
  const maskedName = name[0] + "*".repeat(name.length - 2) + name[len - 1];
  const maskedEmail = maskedName + "@" + domain;
  return maskedEmail;
};

export const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

export const generateCodeChallenge = codeVerifier => {
  // Encode verifier using SHA-256
  var encoder = new TextEncoder();
  var encodedVerifier = encoder.encode(codeVerifier);
  return window.crypto.subtle.digest("SHA-256", encodedVerifier).then(digest => {
    // Convert the result to base64, remove special URL chars, and return
    return window
      .btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/=/g, "")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");
  });
};

export const convertToHTML = text => {
  // Replace escaped newline characters with actual newline
  text = text.replace(/\\n/g, "\n");

  // Use a regular expression to find text between two ** and replace it with the same text wrapped in <strong> tags
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  // Split the text into sections based on double newline characters ("\n\n")
  const sections = text.split("\n\n");

  // Initialize an empty HTML string
  let htmlContent = "";

  // Iterate through each section and convert it into HTML format
  sections.forEach(section => {
    // Check if the section starts with '**', indicating a bold heading
    if (section.startsWith("**")) {
      // Remove the '**' from the section and add it as a bold heading (h2) to the HTML
      htmlContent += `<b>${section.replace("**", "").trim()}</b>\n`;
    } else {
      // Split the section into items based on newline and asterisk characters ("\n*")
      const items = section.split("\n*");

      // Iterate through each item and add it to the HTML as list item (li)
      items.forEach(item => {
        // Add the item as a regular list item
        htmlContent += `<p>${item.trim()}</p>\n`;
      });
    }
  });

  try {
    return htmlContent
      .replace(/\\u([\dA-F]{4})/gi, (_, grp) => String.fromCharCode(parseInt(grp, 16)))
      .replace(/\\u([\dA-F]{4})/gi, (_, grp) => String.fromCharCode(parseInt(grp, 16)));
  } catch (_) {
    return htmlContent;
  }
};

export function formatLocalDate(utcDate) {
  return moment.utc(utcDate).local().format("MMM D, YYYY h:mm A");
}

export function optimizeCloudinaryImage(url, width) {
  if (url && url.includes("res.cloudinary.com") && url.includes("/image/upload/")) {
    let cloudinaryUrl = url.split("/image/upload/").join("/image/upload/f_auto/q_auto/");
    if (width) {
      const numericWidth = width.toString().match(/\d+/);
      const widthValue = `w_${2 * numericWidth[0]}`;
      cloudinaryUrl = url.split("/image/upload/").join(`/image/upload/${widthValue}/q_auto/`);
    }
    return cloudinaryUrl;
  }
  return url;
}

export const convertArrayToKeyvalue = list => {
  if (isArray(list) && list.filter(x => x.key !== "" && x.value !== "").length) {
    return list
      .filter(x => x.key !== "" && x.value !== "")
      .map(attribute => ({
        [attribute.key]: attribute.value,
      }));
  }
  return list;
};

export const extractVariables = (input, prefix = "$") => {
  let variables = [];

  function traverse(data) {
    if (typeof data === "string") {
      // Find all occurrences of $-prefixed variables in the string
      const regex = new RegExp(`\\${prefix}(\\w+)`, "g");
      let match;
      while ((match = regex.exec(data)) !== null) {
        variables.push(match[1]); // Push the variable name without $
      }
    } else if (Array.isArray(data)) {
      // Traverse each element of the array
      data.forEach(element => traverse(element));
    } else if (typeof data === "object" && data !== null) {
      // Traverse each key-value pair of the object
      Object.values(data).forEach(value => traverse(value));
    }
  }

  traverse(input);

  // Remove duplicates and return the result
  return Array.from(new Set(variables));
};

export const extractAttributes = (input, prefix = "$") => {
  let attributes = [];

  function traverse(data) {
    if (typeof data === "object" && data !== null) {
      // If the data is an object, traverse each key-value pair
      Object.entries(data).forEach(([key, value]) => {
        // Check if the value is a string and includes prefix $
        if (typeof value === "string" && value.includes(prefix)) {
          attributes.push(key); // Add the key to attributes list
        }
        traverse(value); // Recursively traverse nested values
      });
    } else if (Array.isArray(data)) {
      // If the data is an array, traverse each element
      data.forEach(element => traverse(element));
    }
  }

  traverse(input);

  // Remove duplicates and return the result
  return Array.from(new Set(attributes));
};
