import { get, orderBy } from "lodash";
import React from "react";
import loadable from "@loadable/component";
import { isArray } from "../../../utils/GeneralUtils.js";

const Loading = () => <div className="l-no-record no-visitor m-box">Loading...</div>;

const Dashboard = loadable(() => import("../../../pages/AIDashboard/Dashboard.js"), { fallback: <Loading /> });
const ExportMessage = loadable(() => import("../../../pages/Visitors/ExportMessage.js"), { fallback: <Loading /> });
const KBUnansweredMessage = loadable(() => import("../../../pages/KnowledgeBase/UnansweredMessage.js"), {
  fallback: <Loading />,
});
const ExportVisitorsMenu = loadable(() => import("../../../pages/Visitors/ExportVisitors.js"), {
  fallback: <Loading />,
});
const ExportFulfilment = loadable(() => import("../../../pages/Fulfilment/ExportFulfilment.js"), {
  fallback: <Loading />,
});
const Tickets = loadable(() => import("../../../pages/Tickets"), { fallback: <Loading /> });
const Chat = loadable(() => import("../../../pages/Chat/Chat.js"), { fallback: <Loading /> });
const AllContacts = loadable(() => import("../../../pages/Contacts/AllContacts.js"), { fallback: <Loading /> });
const Leads = loadable(() => import("../../../pages/Leads/LeadsNew.js"), { fallback: <Loading /> });
const ExportContactMenu = loadable(() => import("../../../pages/Contacts/ExportContacts.js"), {
  fallback: <Loading />,
});
const Routing = loadable(() => import("../../../pages/Engagements/Routing"), { fallback: <Loading /> });
const Scoring = loadable(() => import("../../../pages/Engagements/Scoring"), { fallback: <Loading /> });
const Engagements = loadable(() => import("../../../pages/Engagements/EngagementTab.js"), { fallback: <Loading /> });
const ExportAnalytics = loadable(() => import("../../../pages/Analytics/ExportAnalytics"), { fallback: <Loading /> });
const ExportBusinessMenu = loadable(() => import("../../../pages/Business/ExportBusiness.js"), {
  fallback: <Loading />,
});
const Reports = loadable(() => import("../../../pages/Reports/Reports.js"), { fallback: <Loading /> });
const RequestReport = loadable(() => import("../../../pages/Reports/RequestReport"), { fallback: <Loading /> });
const ScheduleReport = loadable(() => import("../../../pages/Reports/ScheduleReport"), { fallback: <Loading /> });
const ExportStudio = loadable(() => import("../../../pages/Studio/ExportStudio.js"), { fallback: <Loading /> });
const RobotAgents = loadable(() => import("../../../pages/RobotAgents"), { fallback: <Loading /> });
const AutomationRules = loadable(() => import("../../../pages/Automation/Rules"), { fallback: <Loading /> });
const AutomationScheduler = loadable(() => import("../../../pages/Automation/Scheduler"), { fallback: <Loading /> });
const RobotFlow = loadable(() => import("../../../pages/RobotFlow"), { fallback: <Loading /> });
const ExportKnowledge = loadable(() => import("../../../pages/KnowledgeBase/ExportKnowledge.js"), {
  fallback: <Loading />,
});
const KnowledgeBase = loadable(() => import("../../../pages/KnowledgeBase/KnowledgeBase.js"), {
  fallback: <Loading />,
});
const Settings = loadable(() => import("../../../pages/Settings/Settings.js"), { fallback: <Loading /> });
const AppStore = loadable(() => import("../../../pages/AppStore/index.js"), { fallback: <Loading /> });
const ModelManagement = loadable(() => import("../../../pages/ModelStudio/Model/index.js"), { fallback: <Loading /> });
const GenerativeAI = loadable(() => import("../../../pages/ModelStudio/GenerativeAI/index.js"), {
  fallback: <Loading />,
});
const MLModel = loadable(() => import("../../../pages/ModelStudio/MLModel/index.js"), { fallback: <Loading /> });
const ModelPlayground = loadable(() => import("../../../pages/ModelStudio/Playground/index.js"), {
  fallback: <Loading />,
});
const ModelPromptLab = loadable(() => import("../../../pages/ModelStudio/PromptLab/index.js"), {
  fallback: <Loading />,
});
const ModelKnowLedgeBase = loadable(() => import("../../../pages/ModelStudio/KnowLedgeBase/index.js"), {
  fallback: <Loading />,
});
const Insights = loadable(() => import("../../../pages/Insights/index.js"), { fallback: <Loading /> });

const ModelExtensions = loadable(() => import("../../../pages/ModelStudio/Extensions/index.js"), {
  fallback: <Loading />,
});
const ModelKnowledgeGraph = loadable(() => import("../../../pages/ModelStudio/KnowledgeGraph/index.js"), {
  fallback: <Loading />,
});
const ModelIntent = loadable(() => import("../../../pages/ModelStudio/Intents/index.js"), { fallback: <Loading /> });
const ModelEntites = loadable(() => import("../../../pages/ModelStudio/Entities/index.js"), { fallback: <Loading /> });
const ModelOntology = loadable(() => import("../../../pages/ModelStudio/Ontology/index.js"), { fallback: <Loading /> });
const ModelDocuments = loadable(() => import("../../../pages/ModelStudio/Documents/index.js"), {
  fallback: <Loading />,
});
const ModelUnansweredMessage = loadable(() => import("../../../pages/ModelStudio/UnansweredMessage.js"), {
  fallback: <Loading />,
});
const ModelBusinessFAQ = loadable(() => import("../../../pages/ModelStudio/businessFQA/index.js"), {
  fallback: <Loading />,
});
const Datasets = loadable(() => import("../../../pages/ModelStudio/Dataset/index.js"), { fallback: <Loading /> });

export const menuType = {
  DASHBOARD: "dashboard",
  MY_MESSAGES: "my_messages",
  TEAM_MESSAGES: "team_messages",
  UNASSIGNED_MESSAGES: "unassigned_messages",
  SENT_MESSAGES: "sent_messages",
  DRAFT: "draft",
  DELETED: "deleted",
  LABELS: "labels",
  TASKS: "tasks",
  REVIEW_RESPONSE: "review_response",
  TEAMS: "teams",
  APPOINTMENTS: "appointments",
  ONLINE_VISITORS: "online_visitors",
  LIVECHAT: "livechat",
  MY_CONTACTS: "my_contacts",
  VISIT_HISTORY: "visit_history",
  MY_LEADS: "my_leads",
  LISTS: "lists",
  BROADCAST: "broadcast",
  ROUTING: "routing",
  SCORING: "scoring",
  ENGAGEMENTS: "engagements",
  ANALYTICS_CONVERSATION: "analytics_conversation",
  ANALYTICS_CAMPAIGNS: "analytics_campaigns",
  ANALYTICS_USER_ACTIVITY: "analytics_useractivity",
  ANALYTICS_ENGAGEMENT: "analytics_engagement",
  ANALYTICS_VISITOR: "analytics_visitor",
  ANALYTICS_VISITORS_ENQUIRY: "visitors_enquiry",
  ANALYTICS_VISITORS_ANALYTICS: "visitors_analytics",
  ANALYTICS_PRODUCTS: "analytics_products",
  ANALYTICS_CUSTOM: "analytics_custom",
  ANALYTICS_CUSTOMER_SERVICE: "analytics_customer_service",
  ANALYTICS_ORDERS: "analytics_orders",
  ANALYTICS_EXECUTIVE_SUMMARY: "executive_summary_service",
  ORDER: "order",
  PAYMENT: "payment",
  SCHEDULE: "schedule",
  ADD_PRODUCT: "add_product",
  CATALOG: "catalog",
  PROMOTIONS: "promotions",
  PRODUCT_BULK_UPLOAD: "product_bulk_upload",
  REPORTS: "reports",
  REQUEST_REPORT: "request_report",
  SCHEDULING_REPORT: "scheduling_report",
  VIRTUAL_AGENTS: "virtual_agents",
  ROBOT_AGENTS: "robot_agents",
  EMAILS: "emails",
  LANDING_PAGE: "landing_page",
  TEMPLATES: "templates",
  CHATBOT: "chatbot",
  BOT_STORE: "bot_store",
  APP_STORE: "app_store",
  FLOWBOTS: "flowbots",
  ROBOTFLOW: "robotflow",
  RULES: "rules",
  AUTOMATION_SCHEDULER: "automation_scheduler",
  KNOWLEDGE: "knowledge",
  KNOWLEDGE_BASE: "knowledge_base",
  SKILL_BUILDER: "skill_builder",
  DOCUMENT: "document",
  BUSINESS_FAQ: "business_faq",
  INTENT: "intent",
  ENTITIES: "entities",
  TRAINING: "training",
  SETTINGS: "settings",
  MY_TICKETS: "my_tickets",
  TEAM_TICKETS: "team_tickets",
  ALL_TICKETS: "all_tickets",
  FRT_PENDING: "frt_pending_tickets",
  UNASSIGNED_TICKETS: "unassigned_tickets",
  KB_UNASSIGNED_MESSAGES: "kb_unassigned_messages",
  KB_REVIEW_RESPONSE: "kb_review_response",
  MODEL_MENAGEMENT: "model_management",
  MODEL_KNOWLEDGE_BASE: "model_knowledge_base",
  DOCUMENTS: "documents",
  INSIGHT_CONVERSION: "insight_conversion",
  INSIGHT_VISITOR_ENQUIRY: "insight_visitor_enquiry",
  INSIGHT_VISITOR: "insight_visitor",
  INSIGHT_CAMPAIGNS: "insight_campaigns",
  INSIGHT_ACTIVITY: "insight_activity",
  INSIGHT_ENGAGEMENT: "insight_engagement",
  INSIGHT_PRODUCTS: "insight_products",
  INSIGHT_ORDERS: "insight_orders",
  INSIGHT_CUSTOMER_SERVICE: "insight_customer_service",
  INSIGHT_EXECUTIVE_SUMMARY: "insight_executive_summary",
  INSIGHT_TEAM_ANALYTICS: "insight_team_analytics",
  INSIGHT_AGENT_ACTIVITY: "insight_agent_activity",

  MODEL_INTENT: "model_intents",
  MODEL_EXTENSIONS: "model_extensions",
  MODEL_KNOWLEDGE_GRAPH: "model_knowledge_graph",
  MODEL_ENTITIES: "model_entities",
  MODEL_DOCUMENTS: "model_documents",
  MODEL_REVIEW_RESPONSE: "model_review_response",
  MODEL_UNASSIGNED_MESSAGES: "model_unassigned_messages",
  MODEL_BUSINESS_FAQ: "model_business_faq",
  MODEL_GENERATIVE_AI: "model_generative_ai",
  MODEL_ML: "model_ml",
  MODEL_PLAYGROUND: "model_playground",
  MODEL_DATASETS: "model_datasets",
  MODEL_PROMPT_LAB: "model_prompt_lab",
  MODEL_ONTOLOGY: "model_ontology",
};

export const menuItems = [
  {
    pageId: menuType.DASHBOARD,
    url: "/dashboard",
    title: "Dashboard",
    desc: "",
    component: Dashboard,
  },
  {
    pageId: menuType.MY_MESSAGES,
    url: "/visitors/my-messages",
    title: "My Messages",
    desc: "Messages from visitors that are assigned to you and requires assistance to respond.",
    component: ExportMessage,
  },
  {
    pageId: menuType.TEAM_MESSAGES,
    url: "/visitors/team-messages",
    title: "Team Messages",
    desc: "Messages from visitors that are assigned to team and requires assistance to respond.",
    component: ExportMessage,
  },
  {
    pageId: menuType.UNASSIGNED_MESSAGES,
    url: "/visitors/unassigned-messages",
    title: "Inbox",
    desc: "Messages from visitors that are assigned to you and requires assistance to respond.",
    component: ExportMessage,
  },
  {
    pageId: menuType.SENT_MESSAGES,
    url: "/visitors/sent-messages",
    title: "Sent Messages",
    desc: "Messages from visitors that are answered.",
    component: ExportMessage,
  },
  {
    pageId: menuType.DRAFT,
    url: "/visitors/drafts",
    title: "Draft Messages",
    desc: "Messages from visitors that are reverted as Drafts and requires agents assistance.",
    component: ExportMessage,
  },
  {
    pageId: menuType.DELETED,
    url: "/visitors/deleted",
    title: "Deleted Messages",
    desc: "Messages from visitors that are Deleted.",
    component: ExportMessage,
  },
  {
    pageId: menuType.LABELS,
    url: "/visitors/labels",
    title: "Labels",
    desc: "List of targeted visitors who will be handled with personalized messaging.",
    component: ExportVisitorsMenu,
  },
  {
    pageId: menuType.TASKS,
    url: "/visitors/tasks",
    title: "Tasks",
    desc: "Manage your tasks.",
    component: ExportFulfilment,
  },
  {
    pageId: menuType.REVIEW_RESPONSE,
    url: "/visitors/review-response",
    title: "Response Review",
    desc: "",
    component: ExportMessage,
  },
  {
    pageId: menuType.TEAMS,
    url: "/visitors/teams",
    title: "Agents",
    desc: "Agents serving your visitors across channels.",
    component: ExportVisitorsMenu,
  },
  {
    pageId: menuType.APPOINTMENTS,
    url: "/visitors/appt",
    title: "Appointments",
    desc: "Manage your scheduled appointments.",
    component: ExportFulfilment,
  },
  {
    pageId: menuType.ONLINE_VISITORS,
    url: "/visitors/online-visitors",
    title: "Online Visitors",
    desc: "Users online and presently accessing your business across multiple channels like Facebook, Web, Twitter, Email etc.",
    component: ExportVisitorsMenu,
  },
  {
    pageId: menuType.LIVECHAT,
    url: "/livechat",
    title: "Live Chat",
    desc: "",
    component: Chat,
  },
  {
    pageId: menuType.MY_CONTACTS,
    url: "/contacts/contacts",
    title: "Contacts",
    desc: "List of Customers",
    component: AllContacts,
  },
  {
    pageId: menuType.VISIT_HISTORY,
    url: "/visitors/visit-history",
    title: "Visit History",
    desc: "History of all visitors who visited your business across channels.",
    component: ExportVisitorsMenu,
  },
  {
    pageId: menuType.MY_LEADS,
    url: "/contacts/leads",
    title: "My Leads",
    desc: "Manage your Leads.",
    component: Leads,
  },
  {
    pageId: menuType.LISTS,
    url: "/contacts/lists",
    title: "Lists",
    desc: "",
    component: ExportContactMenu,
  },
  {
    pageId: menuType.BROADCAST,
    url: "/visitors/broadcast-message",
    title: "Broadcast",
    desc: "Broadcasts are personalized messages delivered to visitors through multiple channels. Easily create and send a new broadcast to engage with visitors using the 'Create Broadcast' feature.",
    component: ExportMessage,
  },
  {
    pageId: menuType.ROUTING,
    url: "/contacts/insightful",
    title: "Insightful Routing",
    desc: "Utilize Insightful Routing to craft rule-based pathways for conversational interactions. Define intelligent routing rules to seamlessly direct conversations based on specific criteria.",
    component: Routing,
  },
  {
    pageId: menuType.SCORING,
    url: "/contacts/smart-scoring",
    title: "Scoring Model",
    desc: "Rank your visitors against your custom scale using their Conversational metrics.",
    component: Scoring,
  },
  {
    pageId: menuType.ENGAGEMENTS,
    url: "/engagements/myEngagements",
    title: "My Engagements",
    desc: "Create & manage your customer engagements, that could be used in your websites, ads, campaigns, emails etc.",
    component: Engagements,
  },
  {
    pageId: menuType.ANALYTICS_CONVERSATION,
    url: "/analytics/conversation",
    title: "Conversation",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_CAMPAIGNS,
    url: "/analytics/campaigns",
    title: "Campaigns",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_USER_ACTIVITY,
    url: "/analytics/user-activity",
    title: "Activity",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_ENGAGEMENT,
    url: "/analytics/engagement",
    title: "Engagement",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_VISITOR,
    url: "/analytics/visitor",
    title: "Visitor",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_VISITORS_ENQUIRY,
    url: "/analytics/visitors-enquiry",
    title: "Visitors Enquiry",
    desc: "Top enquiries from visitors across channels.",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_VISITORS_ANALYTICS,
    url: "/analytics/visitors-analytics",
    title: "Visitors Analytics",
    desc: "Top enquiries from visitors across channels.",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_PRODUCTS,
    url: "/analytics/prod",
    title: "Products",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_CUSTOM,
    url: "/analytics/custom",
    title: "Custom",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_CUSTOMER_SERVICE,
    url: "/analytics/customer-service",
    title: "Customer Service Analytics",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_ORDERS,
    url: "/analytics/analytics-orders",
    title: "Orders",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ANALYTICS_EXECUTIVE_SUMMARY,
    url: "/analytics/executive_summary",
    title: "Executive Summary",
    desc: "",
    component: ExportAnalytics,
  },
  {
    pageId: menuType.ORDER,
    url: "/fulfilment/Order",
    title: "Order",
    desc: "View the status of all your orders or easily create a new order.",
    component: ExportFulfilment,
    subRoutes: {
      url: "/fulfilment/order-details",
      title: "Order Details",
      desc: "",
      component: ExportFulfilment,
    },
  },
  {
    pageId: menuType.PAYMENT,
    url: "/fulfilment/payment",
    title: "Payment",
    desc: "View all of your payments here.",
    component: ExportFulfilment,
  },
  {
    pageId: menuType.SCHEDULE,
    url: "/fulfilment/schedule",
    title: "Schedule",
    desc: "",
    component: ExportFulfilment,
  },
  {
    pageId: menuType.ADD_PRODUCT,
    url: "/business/new",
    title: "Product",
    desc: "",
    component: ExportBusinessMenu,
  },
  {
    pageId: menuType.CATALOG,
    url: "/business/catalog",
    title: "Catalog",
    desc: "",
    component: ExportBusinessMenu,
  },
  {
    pageId: menuType.PROMOTIONS,
    url: "/business/promotions",
    title: "Promotions",
    desc: "",
    component: ExportBusinessMenu,
  },
  {
    pageId: menuType.PRODUCT_BULK_UPLOAD,
    url: "/business/bulk-upload",
    title: "Bulk Upload",
    desc: "",
    component: ExportBusinessMenu,
  },
  {
    pageId: menuType.REPORTS,
    url: "/reports",
    exact: true,
    title: "Reports",
    desc: "Do you know that for all large reports spanning across multiple period you could use the Request Report feature from the menu that will generate reports offline and get you the required reports.",
    component: Reports,
  },
  {
    pageId: menuType.REQUEST_REPORT,
    url: "/reports/request-report",
    exact: true,
    title: "Request Report",
    desc: "",
    component: RequestReport,
  },
  {
    pageId: menuType.SCHEDULING_REPORT,
    url: "/reports/scheduling-report",
    exact: true,
    title: "Scheduling Report",
    desc: "",
    component: ScheduleReport,
  },
  {
    pageId: menuType.VIRTUAL_AGENTS,
    url: "/designer/virtual-agents",
    title: "Virtual Agents",
    desc: "Design your personalized Virtual Assistant to seamlessly assist your customers as they interact with your business.",
    component: ExportStudio,
  },
  {
    pageId: menuType.ROBOT_AGENTS,
    url: "/designer/robot-agents",
    title: "Robot Agents",
    desc: "Create your custom Robot Agent that can serve your customers when they engage with your business.",
    component: RobotAgents,
  },
  {
    pageId: menuType.EMAILS,
    url: "/designer/emails",
    title: "Email Templates",
    desc: "Craft captivating email templates from a diverse range of designs to connect and engage with your customers through impactful campaigns.",
    component: ExportStudio,
  },
  {
    pageId: menuType.LANDING_PAGE,
    url: "/designer/landing-page",
    title: "Landing Pages",
    desc: "Pick your theme for the landing page from the multiple templates.",
    component: ExportStudio,
  },
  {
    pageId: menuType.TEMPLATES,
    url: "/designer/templates",
    title: "WhatsApp Templates",
    desc: "Design compelling WhatsApp templates to effectively connect and engage with your customers through impactful WhatsApp campaigns.",
    component: ExportStudio,
  },
  {
    pageId: menuType.CHATBOT,
    url: "/automation/chatbot-templates",
    title: "Get Started with Virtual Agents & Chatbots",
    desc: "Get Started with your Virtual Agents & Chatbots with prebuilt templates from Bot Store or create your Flow Bots with easy to use Bot Builder.",
    component: ExportStudio,
  },
  {
    pageId: menuType.FLOWBOTS,
    url: "/automation/flowbots",
    title: "Get started with Flow Bot",
    desc: "Automate your customer engagements with Flowbots that optimizes engagements efficiently and enhance the experiences. Get started with prebuilt Bots or Create your Flow Bots with easy to use Bot Builder.",
    component: ExportStudio,
  },
  {
    pageId: menuType.ROBOTFLOW,
    url: "/automation/robotflow",
    title: "Get Started with Robots Automation Solution",
    desc: "Accelerates your robotic automation with pre-built solutions and plug-and-play integrations, all readliy available from your marketplace or build your own using Bot Builder.",
    component: ExportStudio, // RobotFlow
  },
  {
    pageId: menuType.BOT_STORE,
    url: "/automation/bot-store",
    title: "Welcome to Bot Store",
    desc: "Get started with prebuilt solutions from the bot store. Accelerate your solution-building process with ready-made options available in the marketplace.",
    component: ExportStudio,
  },
  {
    pageId: menuType.RULES,
    url: "/automation/rules",
    title: "Rules",
    desc: "Rules actually create workflows that can explicitly route a conversation using a rule set.",
    component: AutomationRules,
  },
  {
    pageId: menuType.AUTOMATION_SCHEDULER,
    url: "/automation/scheduler",
    title: "Scheduler",
    desc: "Scheduler Description.",
    component: AutomationScheduler,
  },
  {
    pageId: menuType.KNOWLEDGE,
    url: "/knowledge",
    exact: true,
    title: "Knowledge Base",
    desc: "AI Model Studio empowers users to efficiently develop, experiment, and deploy AI models without the need for extensive coding or infrastructure setup.",
    component: KnowledgeBase,
  },
  {
    pageId: menuType.KNOWLEDGE_BASE,
    url: "/knowledge/knowledge-base",
    title: "Knowledge Base",
    desc: "Knowledge base is the repository of knowledge that instantly enable your virtual assistant to learn and respond to a customer’s natural language queries.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.SKILL_BUILDER,
    url: "/knowledge/skill-management",
    title: "Skill Builder",
    desc: "Choose from a dozen question templates to engage your visitors with relevant questions.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.DOCUMENT,
    url: "/knowledge/document",
    title: "Documents",
    desc: "Documents are sources using which you can create a Knowledge Base, these documents could be FAQ's, Websites, Document files etc.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.BUSINESS_FAQ,
    url: "/knowledge/businessFAQ",
    title: "Business FAQ",
    desc: "Establish your business's knowledge base for frequently asked questions here, empowering our conversational AI bots to assist your customers effectively.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.INTENT,
    url: "/knowledge/intent",
    title: "Intents",
    desc: "Intents are the building blocks of chatbot conversations, representing the goals or purposes behind user messages. Each intent is designed to capture a specific user request, question, or action, helping the chatbot understand what the user wants. By defining intents, chatbots can effectively route conversations, provide accurate responses, and fulfill user needs.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.ENTITIES,
    url: "/knowledge/entities",
    title: "Entities",
    desc: "Empower your workflows with Entities, offering customizable attributes in a dynamic schema that aligns perfectly with your requirements. Easily reference these Entities to personalize your workflows. Choose between the provided template file or create a custom data source for seamless importing.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.TRAINING,
    url: "/knowledge/training",
    title: "Training",
    desc: "Developing, Training and optimizing AI models by feeding them with data and adjusting their parameters to enhance accuracy and performance.",
    component: ExportKnowledge,
  },
  {
    pageId: menuType.KB_UNASSIGNED_MESSAGES,
    url: "/knowledge/unanswered-messages",
    title: "Unanswered Messages",
    desc: "Messages from visitors that are unanswered and requires agents assistance.",
    component: KBUnansweredMessage,
  },
  {
    pageId: menuType.KB_REVIEW_RESPONSE,
    url: "/knowledge/review-response",
    title: "Response Review",
    desc: "Refining and optimizing the responses generated by an AI model. Fine-tuning the model's parameters, the algorithms, and improving the training data to enhance the accuracy and relevance of the AI-generated responses.",
    component: KBUnansweredMessage,
  },
  {
    pageId: menuType.SETTINGS,
    url: "/settings",
    title: "Settings",
    desc: "",
    component: Settings,
  },
  {
    pageId: menuType.APP_STORE,
    url: "/app-store",
    path: "/app-store/:category?/:name?/:botId?",
    title: "App Store",
    desc: "Seamlessly Build your Enterprise AI Applications with Essential Modules from the AI App Store.",
    component: AppStore,
  },
  {
    pageId: menuType.MY_TICKETS,
    url: "/tickets/my-tickets",
    path: "/tickets/:type",
    title: "My Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.TEAM_TICKETS,
    url: "/tickets/team",
    path: "/tickets/:type",
    title: "Team Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.ALL_TICKETS,
    url: "/tickets/all",
    path: "/tickets/:type",
    title: "All Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.FRT_PENDING,
    url: "/tickets/frt-pending",
    path: "/tickets/:type",
    title: "FRT Pending Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.UNASSIGNED_TICKETS,
    url: "/tickets/unassigned",
    path: "/tickets/:type",
    title: "Unassigned Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.UNASSIGNED_TICKETS,
    url: "/tickets/unassigned",
    path: "/tickets/:type",
    title: "Unassigned Tickets",
    desc: "Manage your tickets.",
    component: Tickets,
  },
  {
    pageId: menuType.MODEL_MENAGEMENT,
    url: "/model-studio/model",
    title: "Model Management",
    desc: "Model Management handles the entire lifecycle of Custom GPT, Prebuild GPT, and Vision models. This includes creating, tracking, optimizing, and managing the models from their development to deployment and monitoring.",
    component: ModelManagement,
  },
  {
    pageId: menuType.MODEL_KNOWLEDGE_BASE,
    url: "/model-studio/knowledge-base",
    path: "/model-studio/knowledge-base/:modelId?",
    title: "Knowledge Base",
    desc: "Knowledge base is a specialized repository designed for the efficient storage, organization, and retrieval of specific knowledge or data. Documents stored within a knowledge base could include FAQs, websites, and document files, among others.",
    component: ModelKnowLedgeBase,
  },
  {
    pageId: menuType.INSIGHT_VISITOR,
    url: "/insights/visitor",
    path: "/insights/:analyticId",
    title: "Visitor",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_TEAM_ANALYTICS,
    url: "/insights/team-analytics",
    path: "/insights/:analyticId",
    title: "Team Analytics",
    desc: "Top enquiries from tickets across teams & channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_CUSTOMER_SERVICE,
    url: "/insights/customer-service",
    path: "/insights/:analyticId",
    title: "Customer Service",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_ORDERS,
    url: "/insights/orders",
    path: "/insights/:analyticId",
    title: "Orders",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_AGENT_ACTIVITY,
    url: "/insights/agent-activity",
    path: "/insights/:analyticId",
    title: "Agent Activity",
    desc: "Top enquiries from tickets across teams & channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_PRODUCTS,
    url: "/insights/products",
    path: "/insights/:analyticId",
    title: "Products",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_ENGAGEMENT,
    url: "/insights/engagement",
    path: "/insights/:analyticId",
    title: "Engagement",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_CAMPAIGNS,
    url: "/insights/campaigns",
    path: "/insights/:analyticId",
    title: "Campaigns",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_CONVERSION,
    url: "/insights/conversion",
    path: "/insights/:analyticId",
    title: "Conversion",
    desc: "Top enquiries from tickets across teams & channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_EXECUTIVE_SUMMARY,
    url: "/insights/executive-summary",
    path: "/insights/:analyticId",
    title: "Executive Summary",
    desc: "Top enquiries from tickets across teams & channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_VISITOR_ENQUIRY,
    url: "/insights/visitor-enquiry",
    path: "/insights/:analyticId",
    title: "Visitor Enquiry",
    desc: "Top enquiries from tickets across teams & channels.",
    component: Insights,
  },
  {
    pageId: menuType.INSIGHT_ACTIVITY,
    url: "/insights/activity",
    path: "/insights/:analyticId",
    title: "Activity",
    desc: "Top enquiries from visitors across channels.",
    component: Insights,
  },
  {
    pageId: menuType.MODEL_INTENT,
    url: "/model-studio/intents",
    path: "/model-studio/intents/:documentId?",
    title: "Intents",
    desc: "Intents are the building blocks of chatbot conversations, representing the goals or purposes behind user messages. Each intent is designed to capture a specific user request, question, or action, helping the chatbot understand what the user wants. By defining intents, chatbots can effectively route conversations, provide accurate responses, and fulfill user needs.",
    component: ModelIntent,
  },
  {
    pageId: menuType.MODEL_EXTENSIONS,
    url: "/model-studio/extensions",
    // path: "/model-studio/extensions/:intent?",
    title: "Extension",
    desc: "Extensions is an advanced capability to design business flows specific to the domain. Define intents that capture a specific user request, question or action and link it to your business flow using a graphical domain language. Design the required automation using simple bot elements.",
    component: ModelExtensions,
  },
  {
    pageId: menuType.MODEL_KNOWLEDGE_GRAPH,
    url: "/model-studio/knowledge-graph",
    path: "/model-studio/knowledge-graph/:knowledgeGraphId?",
    title: "Knowledge Graph",
    desc: "Knowledge Graph are the building blocks of chatbot conversations, representing the goals or purposes behind user messages.",
    component: ModelKnowledgeGraph,
  },
  {
    pageId: menuType.MODEL_ENTITIES,
    url: "/model-studio/entities",
    title: "Entities",
    desc: "Empower your workflows with Entities, offering customizable attributes in a dynamic schema that aligns perfectly with your requirements. Easily reference these Entities to personalize your workflows. Choose between the provided template file or create a custom data source for seamless importing.",
    component: ModelEntites,
  },
  {
    pageId: menuType.MODEL_DOCUMENTS,
    url: "/model-studio/documents",
    path: "/model-studio/documents/:knowledgeId?",
    title: "Documents",
    desc: "Documents are sources using which you can create a Knowledge Base, these documents could be FAQ's, Websites, Document files etc.",
    component: ModelDocuments,
  },
  {
    pageId: menuType.MODEL_REVIEW_RESPONSE,
    url: "/model-studio/review-response",
    title: "Review",
    desc: "Refining and optimizing the responses generated by an AI model. Fine-tuning the model's parameters, the algorithms, and improving the training data to enhance the accuracy and relevance of the AI-generated responses.",
    component: ModelUnansweredMessage,
  },
  {
    pageId: menuType.MODEL_UNASSIGNED_MESSAGES,
    url: "/model-studio/unanswered-messages",
    title: "Un Trained Utterences",
    desc: "Messages from visitors that are unanswered and requires agents assistance.",
    component: ModelUnansweredMessage,
  },
  {
    pageId: menuType.MODEL_BUSINESS_FAQ,
    url: "/model-studio/businessFAQ",
    title: "Business FAQ",
    desc: "Establish your business's knowledge base for frequently asked questions here, empowering our conversational AI bots to assist your customers effectively.",
    component: ModelBusinessFAQ,
  },
  {
    pageId: menuType.MODEL_GENERATIVE_AI,
    url: "/model-studio/generative-ai",
    title: "Generative AI",
    desc: "Transform your business with tailored generative AI models. Craft private AI models enriched with custom knowledge bases, driving tangible value to your operations. Customize public LLMs to suit your needs. Seamlessly oversee the complete lifecycle of generative AI models, from conceptualization and fine-tuning to seamless deployment.",
    component: GenerativeAI,
  },
  {
    pageId: menuType.MODEL_ML,
    url: "/model-studio/ml-model",
    title: "ML Models",
    desc: "Create, train, and optimize machine learning models seamlessly in our ML Models section. Choose from various model types like regression, classification, or clustering, and attach them to your datasets effortlessly. Customize training parameters, track progress, and fine-tune models for superior performance. Evaluate model accuracy using validation datasets before deploying them for real-world applications.",
    component: MLModel,
  },
  {
    pageId: menuType.MODEL_PLAYGROUND,
    url: "/model-studio/playground",
    title: "Model Playground",
    desc: "Model Playground serves as the central hub for generating and experimenting with an extensive range of state-of-the-art AI models. Explore options across Generative AI, Language Translation, Vision AI, and OCR, and make the most of the available services.",
    component: ModelPlayground,
  },
  {
    pageId: menuType.MODEL_DATASETS,
    url: "/model-studio/datasets",
    title: "Model Datasets",
    desc: "Datasets are structured collections of data used to train, validate, and test ML models. Create your dataset here, and configure your data pipeline.",
    component: Datasets,
  },
  {
    pageId: menuType.MODEL_PROMPT_LAB,
    url: "/model-studio/prompt-lab",
    title: "Prompt Lab",
    desc: "Prompt lab allows you to generate prompts tailored to your needs, from creative storytelling to data-driven analyses. With intuitive tools and customizable templates, unleash the full potential of your AI creations like never before.",
    component: ModelPromptLab,
  },
  {
    pageId: menuType.MODEL_ONTOLOGY,
    url: "/model-studio/ontology",
    title: "Ontology",
    desc: "Ontology refers to a structured framework for organizing and defining concepts and relationships within a specific domain.",
    component: ModelOntology,
  },
];

export const getAccessList = (data, isSubMenu) => {
  const pid = (data.pageId || "").split("#")[0];
  const type = (data.pageId || "").split("#").length === 2 ? (data.pageId || "").split("#")[1] : null;

  const result = {
    [isSubMenu ? "sPid" : "mPid"]: pid,
    [isSubMenu ? "sType" : "mType"]: type,
  };
  if (pid === "reports") {
    const reportAccessList = get(data, "accessList.reports");
    result[isSubMenu ? "sAccessList" : "mAccessList"] = {
      reports: isArray(reportAccessList) ? orderBy(reportAccessList, ["order"]) : defaultReportList,
    };
  } else if (pid === "request_report") {
    const reportAccessList = get(data, "accessList.requestReport");
    result[isSubMenu ? "sAccessList" : "mAccessList"] = {
      requestReport: isArray(reportAccessList) ? reportAccessList : defaultRequestReportList,
    };
  } else if (pid === "scheduling_report") {
    const reportAccessList = get(data, "accessList.scheduleReport");
    result[isSubMenu ? "sAccessList" : "mAccessList"] = {
      scheduleReport: isArray(reportAccessList) ? reportAccessList : defaultRequestReportList,
    };
  }
  return result;
};

export const defaultRequestReportList = [
  "task",
  "pending_tasks",
  "user_agent_activity",
  "virtual_agent_activity",
  "abandoned_chat",
  "agent_pending_task",
  "frt_pending",
  "ticket_aging",
  "tat",
  "sla_status",
  "leads_channel",
  "daily_visitor",
  "chat_summary",
  "broadcast_message",
  "customer_sentiment",
  "visitor_product",
  "activity_summary",
  "activity",
];

export const completeRequestReportList = [
  "task",
  "pending_tasks",
  "user_agent_activity",
  "virtual_agent_activity",
  "abandoned_chat",
  "agent_pending_task",
  "frt_pending",
  "ticket_aging",
  "tat",
  "sla_status",
  "leads_channel",
  "leads_engagement",
  "daily_visitor",
  "leads_status",
  "leads_priority",
  "chat_summary",
  "leads_owner",
  "leads_conversion",
  "broadcast_message",
  "chat_duration",
  "video_conference",
  "customer_sentiment",
  "visitor_product",
  "activity_summary",
  "activity",
  "agent_access",
];

export const defaultReportList = [
  {
    catId: "strategy_insights",
    list: ["leads_engagement", "leads_status", "leads_priority", "leads_channel"],
    order: 1,
  },
  {
    catId: "engagement_analysis",
    list: [
      "leads_owner",
      "leads_conversion",
      "daily_visitor",
      "abandoned_chat",
      "chat_duration",
      "chat_summary",
      "broadcast_message",
      "customer_sentiment",
    ],
    order: 2,
  },
  {
    catId: "content_performance",
    list: ["video_conference", "activity", "visitor_product"],
    order: 3,
  },
  {
    catId: "customer_care_team_performance",
    list: [
      "task",
      "pending_tasks",
      "user_agent_activity",
      "virtual_agent_activity",
      "agent_pending_task",
      "frt_pending",
      "ticket_aging",
      "tat",
      "sla_status",
    ],
    order: 4,
  },
];

export const mainReportList = [
  {
    catId: "strategy_insights",
    title: "Strategy & Insights",
    desc: "Measure the level of engagement with your content and uncover opportunities, trends, and themes to improve your strategy.",
    img: "/img/v1/reports/si.svg",
  },
  {
    catId: "engagement_analysis",
    title: "Engagement Analysis",
    desc: "Track and compare your Engagements to see how you measure up with your customers inflow.",
    img: "/img/v1/reports/ea.svg",
  },
  {
    catId: "content_performance",
    title: "Content Performance",
    desc: "See how your past content has performed across different channels. Export and share results across your team.",
    img: "/img/v1/reports/cp.svg",
  },
  {
    catId: "customer_care_team_performance",
    title: "Customer Care & Team Performance",
    desc: "Analyze your response rates to the messages that matter most to your brand. Measure your team’s efficiency and impact across channels.",
    img: "/img/v1/reports/ct.svg",
  },
];

export const requestReportList = [
  {
    reportId: "task",
    reportName: "task_report",
    displayName: "Ticket management report",
    isDateFilter: true,
  },
  {
    reportId: "pending_tasks",
    reportName: "pending_tasks_report",
    displayName: "Pending Tickets Report",
    isDateFilter: true,
  },
  {
    reportId: "user_agent_activity",
    reportName: "agent_activity",
    displayName: "User Agent Activity Report",
    isDateFilter: true,
  },
  {
    reportId: "virtual_agent_activity",
    reportName: "chatbot_activity",
    displayName: "Virtual Agent Activity Report",
    isDateFilter: true,
  },
  {
    reportId: "abandoned_chat",
    reportName: "abandoned_chats",
    displayName: "Abandoned Chat Report",
  },
  {
    reportId: "agent_pending_task",
    reportName: "agent_pending_task_report",
    displayName: "Agent Pending Task Report",
    isDateFilter: false,
  },
  {
    reportId: "frt_pending",
    reportName: "frt_pending_report",
    displayName: "FRT Report",
  },
  {
    reportId: "ticket_aging",
    reportName: "ticket_Aging_report",
    displayName: "Ticket Aging Report",
    isDateFilter: true,
  },
  {
    reportId: "tat",
    reportName: "tat_report",
    displayName: "TAT Report",
    isDateFilter: true,
  },
  {
    reportId: "sla_status",
    reportName: "sla_status_report",
    displayName: "SLA status Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_channel",
    reportName: "leads_channel",
    displayName: "Leads By Channel Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_engagement",
    reportName: "leads_engagement",
    displayName: "Leads By Engagement Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_status",
    reportName: "leads_status",
    displayName: "Leads By Status Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_priority",
    reportName: "leads_priority",
    displayName: "Leads By Priority Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_owner",
    reportName: "leads_owner",
    displayName: "Leads By Owner Report",
    isDateFilter: true,
  },
  {
    reportId: "leads_conversion",
    reportName: "leads_conversion",
    displayName: "Leads By Conversion Report",
    isDateFilter: true,
  },
  {
    reportId: "chat_duration",
    reportName: "chat_duration",
    displayName: "Chatbot Duration Report",
    isDateFilter: true,
  },
  {
    reportId: "video_conference",
    reportName: "video_conference",
    displayName: "Video Conference Report",
    isDateFilter: true,
  },
  {
    reportId: "daily_visitor",
    reportName: "daily_visitor",
    displayName: "Daily Visitor Report",
    isDateFilter: true,
  },
  {
    reportId: "chat_summary",
    reportName: "chat_summary",
    displayName: "Chatbot Summary Report",
    isDateFilter: true,
  },
  {
    reportId: "broadcast_message",
    reportName: "broadcastmessage",
    displayName: "Broadcast Message Report",
    isDateFilter: true,
  },
  {
    reportId: "customer_sentiment",
    reportName: "customer_sentiment_report",
    displayName: "Customer Sentiment Report",
    isDateFilter: true,
  },
  {
    reportId: "visitor_product",
    reportName: "visitor_product_report",
    displayName: "Visitor Product Report",
    isDateFilter: true,
  },
  {
    reportId: "activity_summary",
    reportName: "activity_summary_report",
    displayName: "Activity Summary Report",
    isDateFilter: true,
  },
  {
    reportId: "activity",
    reportName: "activity_report",
    displayName: "Activity Report",
    isDateFilter: true,
  },
  {
    reportId: "agent_access",
    reportName: "agent_access_report",
    displayName: "Agent Access Report",
    isDateFilter: true,
  },
];

export const menuList = [
  {
    name: "Dashboard",
    type: menuType.DASHBOARD,
    icon: "icon-home",
    order: 1,
  },
  {
    name: "Message Center",
    icon: "icon-drawer",
    order: 2,
    submenu: [
      {
        name: "Inbox",
        type: menuType.UNASSIGNED_MESSAGES,
        order: 1,
      },
      {
        name: "My Messages",
        type: menuType.MY_MESSAGES,
        order: 2,
      },
      {
        name: "Team Messages",
        type: menuType.TEAM_MESSAGES,
        order: 3,
      },
      {
        name: "Sent",
        type: menuType.SENT_MESSAGES,
        order: 4,
      },
      {
        name: "Draft",
        type: menuType.DRAFT,
        order: 5,
      },
      {
        name: "Deleted",
        type: menuType.DELETED,
        order: 6,
      },
      {
        name: "Labels",
        type: menuType.LABELS,
        order: 7,
      },
    ],
  },
  {
    name: "Visitors",
    icon: "icon-people",
    order: 3,
    submenu: [
      {
        name: "Online Visitors",
        type: menuType.ONLINE_VISITORS,
        order: 1,
      },
      {
        name: "Live Chat",
        type: menuType.LIVECHAT,
        order: 2,
      },
      {
        name: "Visit History",
        type: menuType.VISIT_HISTORY,
        order: 3,
      },
    ],
  },
  {
    name: "Engagements",
    type: menuType.ENGAGEMENTS,
    icon: "icon-share",
    order: 4,
  },
  {
    name: "Agents",
    type: menuType.TEAMS,
    icon: "icon-user",
    order: 5,
  },
  {
    name: "Tasks",
    type: menuType.TASKS,
    icon: "icon-note",
    order: 6,
  },
  {
    name: "Tickets",
    icon: "fa fa-ticket",
    order: 7,
    submenu: [
      {
        name: "My Tickets",
        type: menuType.MY_TICKETS,
        order: 1,
      },
      {
        name: "Team Tickets",
        type: menuType.TEAM_TICKETS,
        order: 2,
      },
      {
        name: "All Tickets",
        type: menuType.ALL_TICKETS,
        order: 3,
      },
      {
        name: "FRT Pending",
        type: menuType.FRT_PENDING,
        order: 4,
      },
      {
        name: "Un Assigned",
        type: menuType.UNASSIGNED_TICKETS,
        order: 5,
      },
    ],
  },
  {
    name: "Appointments",
    type: menuType.APPOINTMENTS,
    icon: "icon-calendar",
    order: 8,
  },
  {
    name: "Leads",
    icon: "icon-notebook",
    order: 9,
    submenu: [
      {
        name: "Contacts",
        type: menuType.MY_CONTACTS,
        order: 1,
      },
      {
        name: "Lists",
        type: menuType.LISTS,
        order: 2,
      },
      {
        name: "Leads",
        type: menuType.MY_LEADS,
        order: 3,
      },
      {
        name: "Broadcast",
        type: menuType.BROADCAST,
        order: 4,
      },
      {
        name: "Insightful Routing",
        type: menuType.ROUTING,
        order: 5,
      },
      {
        name: "Smart Scoring",
        type: menuType.SCORING,
        order: 6,
      },
    ],
  },
  {
    name: "Analytics",
    icon: "icon-graph",
    order: 10,
    submenu: [
      {
        name: "Conversation",
        type: menuType.ANALYTICS_CONVERSATION,
        order: 1,
      },
      {
        name: "Visitors Enquiry",
        type: menuType.ANALYTICS_VISITORS_ENQUIRY,
        order: 2,
      },
      {
        name: "Visitor",
        type: menuType.ANALYTICS_VISITOR,
        order: 3,
      },
      {
        name: "Campaigns",
        type: menuType.ANALYTICS_CAMPAIGNS,
        order: 4,
      },
      {
        name: "Activity",
        type: menuType.ANALYTICS_USER_ACTIVITY,
        order: 5,
      },
      {
        name: "Engagement",
        type: menuType.ANALYTICS_ENGAGEMENT,
        order: 6,
      },
      {
        name: "Products",
        type: menuType.ANALYTICS_PRODUCTS,
        order: 7,
      },
      {
        name: "Orders",
        type: menuType.ANALYTICS_ORDERS,
        order: 8,
      },
      {
        name: "Custom",
        type: menuType.ANALYTICS_CUSTOM,
        order: 9,
      },
      {
        name: "Customer Service",
        type: menuType.ANALYTICS_CUSTOMER_SERVICE,
        order: 10,
      },
      {
        name: "Executive Summary",
        type: menuType.ANALYTICS_EXECUTIVE_SUMMARY,
        order: 11,
      },
    ],
  },
  {
    name: "Fulfilment",
    icon: "icon-list",
    order: 11,
    submenu: [
      {
        name: "Order",
        type: menuType.ORDER,
        order: 1,
      },
      {
        name: "Payment",
        type: menuType.PAYMENT,
        order: 2,
      },
      {
        name: "Schedule",
        type: menuType.SCHEDULE,
        order: 3,
      },
    ],
  },
  {
    name: "Products",
    icon: "icon-basket-loaded",
    order: 12,
    submenu: [
      {
        name: "New",
        type: menuType.ADD_PRODUCT,
        order: 1,
      },
      {
        name: "Catalog",
        type: menuType.CATALOG,
        order: 2,
      },
      {
        name: "Promotions",
        type: menuType.PROMOTIONS,
        order: 3,
      },
      {
        name: "Bulk Upload",
        type: menuType.PRODUCT_BULK_UPLOAD,
        order: 4,
      },
    ],
  },
  {
    name: "Reports",
    type: menuType.REPORTS,
    icon: "icon-book-open",
    order: 13,
    submenu: [
      {
        name: "Request Report",
        type: menuType.REQUEST_REPORT,
        order: 1,
      },
      {
        name: "Scheduling Report",
        type: menuType.SCHEDULING_REPORT,
        order: 2,
      },
    ],
  },
  {
    name: "Designer",
    icon: "icon-layers",
    order: 14,
    submenu: [
      {
        name: "Virtual Agents",
        type: menuType.VIRTUAL_AGENTS,
        order: 1,
      },
      {
        name: "Robot Agents",
        type: menuType.ROBOT_AGENTS,
        order: 2,
      },
      {
        name: "Emails",
        type: menuType.EMAILS,
        order: 3,
      },
      {
        name: "WhatsApp",
        type: menuType.TEMPLATES,
        order: 4,
      },
      {
        name: "Pages",
        type: menuType.LANDING_PAGE,
        order: 5,
      },
    ],
  },
  {
    name: "Automation",
    icon: "fa fa-cogs",
    order: 15,
    submenu: [
      {
        name: "Chatbots",
        type: menuType.CHATBOT,
        order: 1,
      },
      {
        name: "Flowbots",
        type: menuType.FLOWBOTS,
        order: 2,
      },
      {
        name: "Robots",
        type: menuType.ROBOTFLOW,
        order: 3,
      },
      {
        name: "Rules",
        type: menuType.RULES,
        order: 4,
      },
    ],
  },
  {
    name: "Knowledge",
    icon: "icon-grid",
    order: 16,
    type: menuType.KNOWLEDGE,
    submenu: [
      {
        name: "Knowledge Base",
        type: menuType.KNOWLEDGE_BASE,
        order: 1,
      },
      {
        name: "Skill Builder",
        type: menuType.SKILL_BUILDER,
        order: 2,
      },
      {
        name: "Documents",
        type: menuType.DOCUMENT,
        order: 3,
      },
      {
        name: "Business FAQ",
        type: menuType.BUSINESS_FAQ,
        order: 4,
      },
      {
        name: "Intents",
        type: menuType.INTENT,
        order: 5,
      },
      {
        name: "Entities",
        type: menuType.ENTITIES,
        order: 6,
      },
      {
        name: "Training",
        type: menuType.TRAINING,
        order: 7,
      },
      {
        name: "Review",
        type: menuType.KB_REVIEW_RESPONSE,
        order: 8,
      },
      {
        name: "Un Answered",
        type: menuType.KB_UNASSIGNED_MESSAGES,
        order: 9,
      },
    ],
  },
  {
    name: "Settings",
    type: menuType.SETTINGS,
    icon: "icon-settings",
    order: 17,
  },
];
