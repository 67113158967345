import React from "react";
import PropTypes from "prop-types";
import { CKEditor as CKEditor5 } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKEditor = props => {
  const editorConfiguration = {
    toolbar: {
      viewportTopOffset: 54,
      items: [
        "undo",
        "redo",
        "|",
        "heading",
        "|",
        "bold",
        "italic",
        "blockQuote",
        "link",
        "uploadImage",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "mediaEmbed",
        "|",
        "imageTextAlternative",
        "imageStyle:side",
        "insertTable",
        "tableColumn",
        "tableRow",
        "mergeTableCells",
      ],
      shouldNotGroupWhenFull: true,
    },
    licenseKey: "XJOSJY336.UEG930RWH876",
  };

  return (
    <CKEditor5
      editor={ClassicEditor}
      config={editorConfiguration}
      data={props.content}
      disabled={props.isDisabled}
      onChange={(event, editor) => {
        props.onChangeFunc(editor.getData());
      }}
      onBlur={(event, editor) => {
        props.onChangeFunc(editor.getData());
      }}
    />
  );
};

CKEditor.defaultProps = {
  content: "",
  onChangeFunc: () => {},
  isDisabled: false,
};

CKEditor.propTypes = {
  content: PropTypes.string,
  onChangeFunc: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default CKEditor;
